import styled from 'styled-components'

import * as Dialog from '@radix-ui/react-dialog'

export const Trigger = styled(Dialog.Trigger)`
  align-self: stretch;
  padding-inline: ${({ theme }) => theme.sizes.sm};
  margin-right: -${({ theme }) => theme.sizes.sm};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    display: none;
  }
`

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  inset: 0;

  background-color: ${({ theme }) => theme.colors.backdrop};
  z-index: ${({ theme }) => theme.zIndices.nav};
`

export const Content = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  z-index: ${({ theme }) => theme.zIndices.nav + 1};

  position: fixed;
  inset: 0;
  left: auto;

  background: ${({ theme }) => theme.colors.darkBgG};
  box-shadow: ${({ theme }) => theme.shadows.nav};

  width: 100%;
  max-width: 300px;

  padding: ${({ theme }) => theme.sizes.sm};
  gap: ${({ theme }) => theme.sizes.xs};

  &:focus {
    outline: none;
  }

  button {
    align-self: flex-start;
  }

  a {
    align-self: flex-start;

    & > span {
      ${({ theme }) => theme.textVariants.paragraphMB};
      color: ${({ theme }) => theme.colors.light};
    }
  }
`

export const Close = styled(Dialog.Close)`
  padding: ${({ theme }) => theme.sizes.xs};
  margin-right: -${({ theme }) => theme.sizes.xs};

  && {
    align-self: flex-end;
  }
`
