import React, { Ref } from 'react'

import { Box, Icon, PlaceImage, Text } from 'ui'
import { Avatar } from 'types/models'
import { getImage } from 'utils/files'

import * as S from './styled'

type Props = {
  onChange: (value: string) => void
  children?: React.ReactNode
}

const Accordion = ({ onChange, children }: Props) => (
  <S.Root type="single" collapsible onValueChange={onChange}>
    {children}
  </S.Root>
)

type ItemProps = {
  value: string
  title: string
  avatar?: Avatar
  footer?: React.ReactNode
  children?: React.ReactNode
}

const Item = React.forwardRef(function PrimitiveItem(
  { value, title, avatar, children, footer }: ItemProps,
  forwardedRef: Ref<HTMLDivElement>
) {
  return (
    <S.Item {...{ value }} ref={forwardedRef}>
      <S.Wrapper>
        <S.Trigger>
          <Icon name="arrow" width={12} height={20} title="Zobraziť" />

          <PlaceImage
            draggable="false"
            src={avatar?.image}
            placeholder={getImage(avatar?.image, 'placeholder', 'png')}
            fallback="/assets/images/duck@fallback.png"
            alt={avatar?.name || 'káčer'}
            defW={74}
            defH={74}
            w={74}
            h={74}
            maxw={50}
            largePhone={{ maxw: 74 }}
            ofit="contain"
            opos="center"
          />

          <Box as="header" flex={1} gap="ss" justifyContent="center">
            <Text
              as="h2"
              textAlign="left"
              color="primary500"
              variant="heading5A"
              largePhone={{ fontSize: 20, lineHeight: 24 }}
              tablet={{ fontSize: 24, lineHeight: 29 }}
              desktop={{ fontSize: 28, lineHeight: 34 }}
            >
              {title}
            </Text>
          </Box>
        </S.Trigger>

        <Box
          as="footer"
          gap="ss"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          alignSelf="flex-end"
          tablet={{ alignSelf: 'center' }}
        >
          {footer}
        </Box>
      </S.Wrapper>

      <S.Content>
        <Box as="section" ph="xs" largePhone={{ ph: 'sm' }}>
          <Box
            gap="s"
            wrap="wrap"
            paddingVertical="xs"
            largePhone={{
              pv: 'sm',
              gap: 'sm',
              flexDirection: 'row',
            }}
            desktop={{ gap: 'l' }}
            css={S.wrapper}
          >
            {children}
          </Box>
        </Box>
      </S.Content>
    </S.Item>
  )
})

Accordion.Item = Item

export default Accordion
