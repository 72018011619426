import React, { useCallback } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { Box, Text, Button, Search } from 'ui'
import { SelectItem } from 'components/items'
import { useErrorHandler, useSelect } from 'hooks'
import useToastStore from 'stores/toast'

import Modal, { useModal } from 'components/Modal/Modal'

import {
  GET_NEW_STUDENTS_CLASSES,
  GET_STUDENTS,
  NewClassesPayload,
  NewStudentClassesData,
  NewStudentClassesPayload,
  StudentsPayload,
} from 'apollo/queries'
import {
  ReassignStudentData,
  ReassignStudentPayload,
  REASSIGN_STUDENT,
} from 'apollo/mutations'

type Props = {
  studentId: ID
  classId: ID
}

type TQPayload = Wrap<NewStudentClassesPayload, 'classes'>
type TQData = Wrap<NewStudentClassesData, 'data'>

type TMPayload = Wrap<ReassignStudentPayload, 'student'>
type TMData = Wrap<ReassignStudentData, 'data'>

type ActiveClass = NewStudentClassesPayload[number]

const ReassignStudent = ({ studentId, classId }: Props) => {
  const { hide } = useModal()
  const { handle } = useErrorHandler()
  const { show } = useToastStore()

  const [active, setActive] = useSelect<ActiveClass>()

  const { data, refetch } = useQuery<TQPayload, TQData>(
    GET_NEW_STUDENTS_CLASSES,
    {
      variables: { data: { id: studentId } },
    }
  )

  const [reassignStudent, { loading }] = useMutation<TMPayload, TMData>(
    REASSIGN_STUDENT,
    {
      onCompleted: ({ student }) =>
        show({
          title: 'Študent bol úspešne preradený',
          message: `${student?.name} ${student?.surname}`,
          type: 'success',
        }),
    }
  )

  const onSubmit = useCallback(() => {
    reassignStudent({
      variables: { data: { id: active?.id || '', studentId, classId } },
      refetchQueries: [
        {
          query: GET_NEW_STUDENTS_CLASSES,
          variables: { data: { id: studentId } },
        },
      ],
      update: (cache, { data: response }) => {
        cache.updateQuery(
          {
            query: GET_STUDENTS,
            variables: { data: { id: classId } },
          },
          (data?: Wrap<StudentsPayload, 'students'>) => {
            if (data?.students) {
              return {
                students: data?.students?.filter(
                  (student) => student.id !== response?.student.id
                ),
              }
            }
          }
        )

        cache.updateQuery(
          {
            query: GET_STUDENTS,
            variables: { data: { id: active?.id || '' } },
          },
          (data?: Wrap<StudentsPayload, 'students'>) => {
            if (data?.students) {
              return {
                students: [response!.student, ...data.students],
              }
            }
          }
        )
      },
    })
      .then(hide)
      .catch(handle())
  }, [reassignStudent, hide, handle, studentId, classId, active])

  const handleSearch = useCallback(
    (query: string) => {
      refetch({ data: { id: studentId, query } })
    },
    [refetch, studentId]
  )

  const renderItems = useCallback(
    (item: NewClassesPayload[number]) => (
      <SelectItem
        key={`class-${item.id}`}
        title={item.name}
        image={{
          src: item?.logo,
          placeholder: '/assets/logo.svg',
          fallback: '/assets/logo.svg',
        }}
        active={item.id === active?.id}
        onClick={() => setActive(item)}
        secondary
        rounded
      />
    ),
    [active, setActive]
  )

  return (
    <Modal.Wrapper indented condensed>
      <Modal.Title>Preradiť študenta</Modal.Title>

      <Box as="section" gap="3xs">
        {/* LABEL */}
        <Text<'label'> as="label" htmlFor="title" variant="paragraphMB">
          Názov triedy
        </Text>
        {/* END LABEL */}

        <Search
          id="title"
          placeholder="Názov"
          callback={handleSearch}
          fullWidth
        />
      </Box>

      {/* CLASSES LIST */}
      <Box maxHeight={395} overflow="scroll" radius="input">
        <Box mb="s" gap="s">
          {data?.classes?.map(renderItems)}
        </Box>
      </Box>
      {/* END CLASSES LIST */}

      <Modal.Actions>
        <Button
          disabled={!active || loading}
          isLoading={loading}
          onClick={onSubmit}
        >
          Preradiť
        </Button>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export default React.memo(ReassignStudent)
