import styled, { css } from 'styled-components'

import * as Dialog from '@radix-ui/react-dialog'

interface IContent {
  $extended?: boolean
  $max?: number
}

interface IActions {
  floating?: boolean
}

interface ITitle {
  $left?: boolean
}

interface IWrapper {
  $overflow?: boolean
}

export const Trigger = styled(Dialog.Trigger)`
  cursor: pointer;
`

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  inset: 0;

  background-color: ${({ theme }) => theme.colors.overlay};
  z-index: ${({ theme }) => theme.zIndices.modal};

  padding-inline: ${({ theme }) => theme.sizes.l};
  padding-block: ${({ theme }) => theme.sizes.xl};

  overflow-y: auto;

  display: flex;
`

export const Content = styled(Dialog.Content)<IContent>`
  z-index: ${({ theme }) => theme.zIndices.modal};

  margin: auto;

  border-radius: ${({ theme }) => theme.borderRadii.card};
  box-shadow: ${({ theme }) => theme.shadows.card};

  max-height: 897px;
  min-height: 100px;

  width: ${({ $extended }) => $extended && ' 100%'};
  max-width: ${({ $max }) => $max || 591}px;

  form {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.sizes.s};
  }

  &:focus {
    outline: none;
  }
`

export const Wrapper = styled.article<IWrapper>`
  position: ${({ $overflow }) => $overflow && 'relative'};
  overflow: ${({ $overflow }) => $overflow && 'hidden'};

  background-color: ${({ theme }) => theme.colors.light};
  border-radius: ${({ theme }) => theme.borderRadii.card};
`

export const Title = styled(Dialog.Title)<ITitle>`
  ${({ theme }) => theme.textVariants.heading4A};

  @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
    ${({ theme }) => theme.textVariants.heading3A};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ${({ theme }) => theme.textVariants.heading2A};
  }

  color: ${({ theme }) => theme.colors.text};

  text-align: ${({ $left }) => ($left ? 'left' : 'center')};
  flex: 1;
`

export const icon = css`
  width: 10px;
  height: 18px;

  @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
    width: 12px;
    height: 20px;
  }

  #path {
    fill: ${({ theme }) => theme.colors.primary500};
  }
`

export const Close = styled(Dialog.Close)`
  position: absolute;
  top: -10px;
  right: -10px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.primary500};
  border-radius: ${({ theme }) => theme.borderRadii.rounded};

  width: 28px;
  height: 28px;

  svg {
    width: 19px;
    height: 19px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
    width: 34px;
    height: 34px;

    top: -17px;
    right: -17px;

    svg {
      width: 22px;
      height: 22px;
    }
  }
`

export const position = css<IActions>`
  position: ${({ floating }) => floating && 'absolute'};
  ${({ floating }) => floating && 'inset: 0; top: auto;'}
  ${({ floating }) => floating && 'transform: translateY(50%);'}
`
