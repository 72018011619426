import React, { ButtonHTMLAttributes, ElementType } from 'react'
import { motion } from 'framer-motion'

import { WithLoading } from 'ui/hoc'

import * as S from './styled'

type Variant = 'primary' | 'secondary' | 'tertiary' | 'grey' | 'light'

export type Props = {
  as?: ElementType
  onClick?: () => void
  variant?: Variant
  inverted?: boolean
  icon?: string
  children?: React.ReactNode
} & ButtonHTMLAttributes<HTMLButtonElement>

const Button = ({
  variant = 'primary',
  disabled,
  children,
  ...props
}: Props) => (
  <motion.div
    whileHover={{ scale: disabled ? 1 : 1.05 }}
    whileTap={{ scale: disabled ? 1 : 0.95 }}
    transition={{ type: 'spring', stiffness: 400, damping: 17 }}
  >
    <S.Wrapper {...{ variant, disabled, ...props }}>{children}</S.Wrapper>
  </motion.div>
)

export default React.memo(WithLoading<Props>(Button))
