import React from 'react'
import { Navigate, useLocation } from 'react-router'

import ProtectRoute from './ProtectRoute'

const WithAuth = (Component: React.ComponentType, fallback = '/signin') => {
  const Wrapper = () => {
    const location = useLocation()

    return (
      <ProtectRoute
        yes={Component}
        no={() => <Navigate to={fallback} state={{ from: location }} replace />}
      />
    )
  }

  return <Wrapper />
}

export default WithAuth
