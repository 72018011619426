import { css } from 'styled-components'

export const filter = css`
  filter: drop-shadow(${({ theme }) => theme.shadows.profile});
  flex-shrink: 0;
`

export const shadow = css`
  text-shadow: ${({ theme }) => theme.shadows.profile};
`
