import { css } from 'styled-components'

export const image = css`
  transform: scaleX(-1);
  margin-top: -20px;
  margin-right: -10px;
  margin-bottom: -51px;

  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: block;
  }
`
