import React, { Ref } from 'react'
import { FlattenInterpolation, ThemeProps } from 'styled-components'
import * as Primitive from '@radix-ui/react-popover'

import { TTheme } from 'styles/theme'

import * as S from './styled'

type Props = {
  children: React.ReactNode
}

const Popover = ({ children }: Props) => (
  <Primitive.Root>{children}</Primitive.Root>
)

type TriggerProps = {
  css?: FlattenInterpolation<ThemeProps<TTheme>>
  children: React.ReactNode
}

const Trigger = ({ children, css }: TriggerProps) => (
  <S.Trigger {...{ css }}>{children}</S.Trigger>
)

const Content = React.forwardRef(function PopoverContent(
  { children }: Props,
  forwardedRef: Ref<HTMLDivElement>
) {
  return (
    <Primitive.Portal>
      <S.Content align="end" ref={forwardedRef}>
        {children}
      </S.Content>
    </Primitive.Portal>
  )
})

Popover.Trigger = Trigger
Popover.Content = Content

export default Popover
