import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useApolloClient } from '@apollo/client'

import { getAccessTokenData } from 'utils/auth'
import { setRefreshToken, removeRefreshToken } from 'utils/cookies'

import { Me } from 'types/global'
import { useAuthStore } from 'stores/auth'

type UseAuth = {
  onLogin: (refreshToken?: string, accessToken?: string) => Promise<Me | null>
  onLogout: () => void
}

export default (): UseAuth => {
  const navigate = useNavigate()
  const location = useLocation()
  const client = useApolloClient()

  const setToken = useAuthStore<'setToken'>((state) => state.setToken)
  const clearToken = useAuthStore<'clearToken'>((state) => state.clearToken)

  const backToLocation = useCallback(
    (state: any) => navigate(state?.from || '/', { replace: true }),
    [navigate]
  )

  const onLogin = useCallback(
    async (refreshToken = '', accessToken = '') => {
      // Validate access token
      const data = getAccessTokenData(accessToken)
      if (!data) return null

      // Set refresh token to cookie and access token to store
      setRefreshToken(refreshToken)
      setToken(accessToken)

      // Navigate to previous location
      backToLocation(location?.state)

      return await new Promise<Me>((resolve) => resolve(data))
    },
    [setToken, backToLocation, location]
  )

  const onLogout = useCallback(async () => {
    // Remove tokens from cookies and storage
    removeRefreshToken()
    clearToken()

    // Reset apollo client cache
    client.resetStore()
  }, [clearToken, client])

  return { onLogin, onLogout }
}
