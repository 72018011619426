import { InferType, mixed, object, string } from 'yup'

import { BYTE } from 'constants/global'

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']
const MAX_FILE_SIZE_IN_BYTES = BYTE * BYTE * 5 // 5MB

const schema = object({
  id: string(),
  name: string().required('Prosím vyplňte názov'),
  description: string().max(250, 'Maximálny počet znakov je 255'),
  logo: string(),
  image: mixed()
    .test(
      'fileSize',
      `Maximálna veľkosť súboru je ${MAX_FILE_SIZE_IN_BYTES / BYTE / BYTE}MB`,
      (value) => !value || (value && value.size <= MAX_FILE_SIZE_IN_BYTES)
    )
    .test(
      'fileFormat',
      'Podporované formáty súboru sú JPG, JPEG a PNG',
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
}).required()

export type UpsertClassData = InferType<typeof schema>

export default schema
