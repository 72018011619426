import { gql } from '@apollo/client'

import { User } from 'types/models'

export type NewStudentsData = {
  id: ID
  query?: string
}

export type NewStudentsPayload = User[]

export default gql`
  query getNewStudents($data: IdqueryData!) {
    students: getNewStudents(data: $data) {
      id
      name
      surname
      email
      avatar {
        id
        name
        image
      }
    }
  }
`
