import React, { useCallback } from 'react'

import { Button, Text } from 'ui'

import Modal, { useModal } from 'components/Modal/Modal'

type Props = {
  title: string
  button?: string
  loading?: boolean
  onAction: (hide: () => void) => void
  children?: React.ReactNode
}

const Alert = ({
  title,
  button = 'Vymazať',
  loading,
  onAction,
  children,
}: Props) => {
  const { hide } = useModal()

  const handleAction = useCallback(() => onAction(hide), [onAction, hide])

  return (
    <Modal.Wrapper indented condensed>
      <Modal.Title left>{title}</Modal.Title>

      <Text
        variant="paragraphM"
        color="text"
        largePhone={{ fontSize: 16, lineHeight: 19 }}
      >
        {children}
      </Text>

      <Modal.Actions close right>
        <Button onClick={handleAction} isLoading={loading} disabled={loading}>
          {button}
        </Button>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export default React.memo(Alert)
