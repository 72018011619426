import { DependencyList, EffectCallback, useEffect } from 'react'

import useOnce from './useOnce'

export default (effect: EffectCallback, deps?: DependencyList) => {
  const isFirst = useOnce()

  useEffect(() => {
    if (!isFirst) {
      return effect()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
