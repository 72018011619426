import React from 'react'

import { Box, Button, PlaceImage, Skeleton, Text } from 'ui'
import { UpsertClassModal } from 'components/modals'
import { DeleteModal } from './modals'

import { Class } from 'types/models'
import { ForRoles } from 'ui/auth'

import Modal from 'components/Modal'

type Props = {
  data?: Class
  loading?: boolean
}

const ClassHeader = ({ data, loading }: Props) => (
  <Box
    as="header"
    flexDirection="row"
    alignItems="center"
    bgColor="text"
    radius="card"
    padding="xs"
    gap="s"
    largePhone={{ gap: 'm', padding: 's' }}
    tablet={{ gap: 'l', padding: 'sm' }}
  >
    <PlaceImage
      draggable="false"
      src={data?.logo}
      placeholder="/assets/logo.svg"
      fallback="/assets/logo.svg"
      alt={`${data?.name} logo`}
      defW={100}
      defH={100}
      maxw={80}
      maxh={80}
      alignSelf="flex-start"
      radius="rounded"
      ofit="cover"
      opos="center"
      largePhone={{ maxw: 100, maxh: 100 }}
    />

    <Box flex={1} gap="ss" largePhone={{ gap: 's' }}>
      <Text
        as="h1"
        variant="heading4A"
        color="primary500"
        largePhone={{ fontSize: 24, lineHeight: 29 }}
        tablet={{ fontSize: 28, lineHeight: 34 }}
      >
        {data?.name || <Skeleton maxw="40ch" />}
      </Text>

      {(loading || data?.description) && (
        <Text
          color="light"
          variant="paragraphM"
          tablet={{ fontSize: 16, lineHeight: 19, maxw: '100ch' }}
        >
          {data?.description || (loading && <Skeleton count={2} />)}
        </Text>
      )}

      <ForRoles roles={['MANAGER']}>
        <Box flexDirection="row" gap="ss" wrap="wrap">
          {/* EDIT MODAL */}
          <Modal>
            <Modal.Trigger disabled={!data}>
              <Button as="div" variant="secondary" disabled={!data}>
                Upraviť
              </Button>
            </Modal.Trigger>

            <Modal.Content extended>
              <UpsertClassModal {...{ data }} />
            </Modal.Content>
          </Modal>
          {/* END EDIT MODAL */}

          {/* DELETE MODAL */}
          <Modal>
            <Modal.Trigger disabled={!data}>
              <Button as="div" disabled={!data}>
                Vymazať
              </Button>
            </Modal.Trigger>

            <Modal.Content extended hideClose>
              {!!data && <DeleteModal {...{ data }} />}
            </Modal.Content>
          </Modal>
          {/* END DELETE MODAL */}
        </Box>
      </ForRoles>
    </Box>
  </Box>
)

export default React.memo(ClassHeader)
