import { css } from 'styled-components'

const common = css`
  position: fixed;
  user-select: none;
  z-index: ${({ theme }) => theme.zIndices.base};
`

export const left = css`
  ${common}

  transform: rotate(45deg);

  left: -25px;
  top: 50%;
`

export const right = css`
  ${common}

  transform: rotate(-30deg);

  right: -45px;
  top: 17%;
`
