import React from 'react'

import { Box, PlaceImage, Text } from 'ui'
import { Avatar } from 'types/models'
import { getImage } from 'utils/files'

import * as S from './styled'

type Props = {
  onClick?: () => void
  children?: React.ReactNode
}

const UserItem = ({ onClick, children }: Props) => (
  <Box
    as="section"
    padding="xs"
    radius="toast"
    overflow="hidden"
    bgColor="grey100"
    largePhone={{ gap: 's', padding: 's', radius: 'card' }}
    tablet={{ gap: 'm', padding: 'sm', flexDirection: 'row' }}
    css={S.wrapper}
    {...{ onClick }}
  >
    {children}
  </Box>
)

type ContentProps = {
  title: string
  secondary?: boolean
  avatar?: Avatar
  subtitle?: React.ReactNode
  onClick?: () => void
  children?: React.ReactNode
}

const Content = ({
  title,
  avatar,
  secondary,
  subtitle,
  onClick,
  children,
}: ContentProps) => (
  <>
    <Box
      gap="xs"
      flexDirection="row"
      largePhone={{ gap: 's' }}
      tablet={{ gap: 'm' }}
    >
      {!secondary && (
        <PlaceImage
          draggable="false"
          src={avatar?.image}
          placeholder={getImage(avatar?.image, 'placeholder', 'png')}
          fallback="/assets/images/duck@fallback.png"
          alt={avatar?.name || 'káčer'}
          defW={74}
          defH={74}
          w={74}
          h={74}
          maxw={50}
          largePhone={{ maxw: 60 }}
          tablet={{ maxw: 74 }}
          ofit="contain"
          opos="center"
        />
      )}

      <Box
        as="header"
        flex={1}
        gap="3xs"
        justifyContent="center"
        largePhone={{ gap: 'ss' }}
      >
        <Text
          as="h2"
          color="primary500"
          underline={!!onClick}
          variant={secondary ? 'heading6A' : 'heading4A'}
          style={{ cursor: onClick ? 'pointer' : 'default' }}
          largePhone={{
            fontSize: secondary ? 18 : 24,
            lineHeight: secondary ? 22 : 29,
          }}
          tablet={{
            fontSize: secondary ? 20 : 28,
            lineHeight: secondary ? 24 : 34,
          }}
          {...{ onClick }}
        >
          {title}
        </Text>

        {subtitle}
      </Box>
    </Box>

    {children}
  </>
)

type FooterProps = {
  children?: React.ReactNode
}

const Footer = ({ children }: FooterProps) => (
  <Box
    as="footer"
    flex={1}
    gap="ss"
    flexDirection="row"
    alignItems="center"
    justifyContent="flex-end"
  >
    {children}
  </Box>
)

UserItem.Content = Content
UserItem.Footer = Footer

export default UserItem
