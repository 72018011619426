import { gql } from '@apollo/client'

import { Subject } from 'types/models'

export type SchoolSubjectsData = {
  query?: string
}

export type SchoolSubjectsPayload = Subject[]

export default gql`
  query getSchoolSubjects($data: QueryData) {
    subjects: getSchoolSubjects(data: $data) {
      id
      name
      createdAt
    }
  }
`
