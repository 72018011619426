import React, { useCallback } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { AnimatePresence, motion } from 'framer-motion'
import * as Dialog from '@radix-ui/react-dialog'

import { Avatar, Box, Button, Icon, Text } from 'ui'
import { ForRoles } from 'ui/auth'
import { useAuth, useToggle } from 'hooks'
import { getRefreshToken } from 'utils/cookies'

import Modal from 'components/Modal'
import ProfileModal from 'components/modals/Profile'

import { GET_ME, MePayload } from 'apollo/queries'
import { LOGOUT, LogoutData, LogoutPayload } from 'apollo/mutations'

import * as S from './styled'

type Props = {
  children: React.ReactNode
}

type TPayload = Wrap<MePayload, 'me'>

const MotionContent = motion(S.Content)
const MotionOverlay = motion(S.Overlay)

const Mobile = ({ children }: Props) => {
  const { visible, toggle } = useToggle()
  const { onLogout } = useAuth()

  const { data } = useQuery<TPayload>(GET_ME)

  const [logout, { loading }] = useMutation<LogoutPayload, LogoutData>(LOGOUT)

  const handleLogout = useCallback(() => {
    const refreshToken = getRefreshToken() || ''
    logout({ variables: { refreshToken } }).then(onLogout)
  }, [logout, onLogout])

  return (
    <Dialog.Root open={visible} onOpenChange={toggle}>
      <S.Trigger>
        <Icon name="burger" width={21} height={21} title="otvoriť navigáciu" />
      </S.Trigger>

      <AnimatePresence>
        {visible && (
          <Dialog.Portal forceMount>
            <MotionOverlay
              forceMount
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            />

            <MotionContent
              initial={{ opacity: 0, x: '100%' }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: '100%' }}
              transition={{ duration: 0.2 }}
            >
              <Box alignItems="flex-end" pb="ss">
                <S.Close aria-label="close">
                  <Icon
                    name="close"
                    width={21}
                    height={21}
                    title="zatvoriť navigáciu"
                  />
                </S.Close>
              </Box>

              <Box as="nav" gap="xxs" onClick={toggle}>
                {children}
              </Box>

              <Box h={1} bgColor="separator" radius="rounded" />

              <Box gap="xxs">
                <Box flexDirection="row" alignItems="center" gap="s">
                  <Avatar
                    src={data?.me?.avatar?.image}
                    alt={data?.me?.avatar?.name}
                  />

                  <Text variant="paragraphMB" color="light" w="100%">
                    {data?.me?.fullName}
                  </Text>

                  {/* FOR STUDENT */}
                  <ForRoles roles="STUDENT">
                    <Box flexDirection="row" alignItems="center" gap="3xs">
                      <Text variant="paragraphMB" color="light">
                        {data?.me?.coins || 0}
                      </Text>

                      <Icon
                        name="coin"
                        width={14}
                        height={14}
                        title="peniaze"
                      />
                    </Box>
                  </ForRoles>
                  {/* END FOR STUDENT */}
                </Box>

                <Modal>
                  <Modal.Trigger disabled={!data?.me}>
                    <Button
                      as="div"
                      variant="light"
                      disabled={!data?.me}
                      inverted
                    >
                      Profil
                    </Button>
                  </Modal.Trigger>

                  <Modal.Content extended max={420}>
                    <ProfileModal data={data?.me} />
                  </Modal.Content>
                </Modal>

                <Button
                  disabled={loading}
                  isLoading={loading}
                  onClick={handleLogout}
                >
                  Odhlásiť sa
                </Button>
              </Box>
            </MotionContent>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  )
}

export default React.memo(Mobile)
