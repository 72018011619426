import { gql } from '@apollo/client'

import { Class } from 'types/models'

export type MyClassesData = {
  query?: string
}

export type MyClassesPayload = Class[]

export default gql`
  query getMyClasses($data: QueryData) {
    myClasses: getMyClasses(data: $data) {
      id
      name
      description
      logo
    }
  }
`
