import React, { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { FetchResult, useMutation } from '@apollo/client'
import { Form, Formik, FormikHelpers } from 'formik'

import { Button, Error, Head, Input, Text } from 'ui'
import { Card } from 'components/layout'
import { useAuth, useErrorHandler } from 'hooks'

import validationSchema, { SigninData } from 'schemas/signin'
import { SIGNIN, SigninPayload } from 'apollo/mutations'

type TPayload = Wrap<SigninPayload, 'signin'>
type TData = Wrap<SigninData, 'data'>

const initialValues: SigninData = {
  email: '',
  password: '',
}

const SignIn = () => {
  const { onLogin } = useAuth()
  const { error, handle } = useErrorHandler()

  const [signin, { loading }] = useMutation<TPayload, TData>(SIGNIN)

  const onSubmit = useCallback(
    (values: SigninData, formikHelpers: FormikHelpers<SigninData>) => {
      signin({ variables: { data: values } })
        .then(({ data }: FetchResult<TPayload>) =>
          onLogin(data?.signin?.refreshToken, data?.signin?.accessToken)
        )
        .catch(handle(formikHelpers))
    },
    [signin, onLogin, handle]
  )

  return (
    <>
      <Head title="Prihlásenie" />

      <Card title="Prihlásiť sa">
        <Formik
          {...{ initialValues, validationSchema, onSubmit }}
          validateOnChange
        >
          {({ errors, touched }) => (
            <Form>
              <Input
                type="email"
                name="email"
                label="Email"
                hasError={touched.email && errors.email}
                disabled={loading}
                autoComplete="email"
                required
              />

              <Input
                type="password"
                name="password"
                label="Heslo"
                hasError={touched.password && errors.password}
                disabled={loading}
                autoComplete="current-password"
                required
              />

              <Error message={error} />

              <Button type="submit" isLoading={loading} disabled={loading}>
                Prihlásiť sa
              </Button>
            </Form>
          )}
        </Formik>
      </Card>

      <Text<typeof NavLink>
        as={NavLink}
        to="/forgot-password"
        textAlign="center"
        variant="anchorB"
        color="grey100"
        underline
      >
        Zabudol si heslo?
      </Text>
    </>
  )
}

export default React.memo(SignIn)
