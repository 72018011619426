import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Form, Formik, FormikHelpers } from 'formik'
import { FetchResult, useMutation, useQuery } from '@apollo/client'

import { Box, Button, Error, Head, Input, Text } from 'ui'
import { Card } from 'components/layout'
import { useAuth, useErrorHandler } from 'hooks'

import { SIGNUP, SignupPayload } from 'apollo/mutations'
import { ByTokenData, ByTokenPayload, GET_BY_TOKEN } from 'apollo/queries'
import validationSchema, { SignupData } from 'schemas/signup'

type Params = {
  token: string
}

type TQPayload = Wrap<ByTokenPayload, 'user'>
type TQData = Wrap<ByTokenData, 'data'>

type TMPayload = Wrap<SignupPayload, 'signup'>
type TMData = Wrap<SignupData, 'data'>

const SignUp = () => {
  const navigate = useNavigate()
  const { token } = useParams<Params>()

  const { onLogin } = useAuth()
  const { error, handle } = useErrorHandler()

  const { data } = useQuery<TQPayload, TQData>(GET_BY_TOKEN, {
    variables: { data: { token: token || '' } },
    onError: () => navigate('/signin', { replace: true }),
  })

  const [signup, { loading }] = useMutation<TMPayload, TMData>(SIGNUP)

  const initialValues = useMemo(
    (): SignupData => ({
      token,
      password: '',
      passwordConfirmation: '',
    }),
    [token]
  )

  const onSubmit = useCallback(
    (values: SignupData, formikHelpers: FormikHelpers<SignupData>) => {
      signup({ variables: { data: values } })
        .then(({ data: user }: FetchResult<TMPayload>) =>
          onLogin(user?.signup?.refreshToken, user?.signup?.accessToken)
        )
        .catch(handle(formikHelpers))
    },
    [signup, onLogin, handle]
  )

  return (
    <>
      <Head title="Registrácia" />

      <Card title="Registrovať sa">
        <Box flexDirection="row" gap="3xs" mb="m">
          <Text as="span" variant="paragraphMB" color="text">
            Email:
          </Text>

          <Text as="span" variant="paragraphMB" color="primary500">
            {data?.user?.email}
          </Text>
        </Box>

        <Formik
          {...{ initialValues, validationSchema, onSubmit }}
          validateOnChange
          enableReinitialize
        >
          {({ errors, touched }) => (
            <Form>
              <Input
                type="password"
                name="password"
                label="Heslo"
                hasError={touched.password && errors.password}
                disabled={loading}
                autoComplete="new-password"
                required
              />

              <Input
                type="password"
                name="passwordConfirmation"
                label="Heslo znovu"
                hasError={
                  touched.passwordConfirmation && errors.passwordConfirmation
                }
                disabled={loading}
                autoComplete="new-password"
                required
              />

              <Error message={error} />

              <Button type="submit" isLoading={loading} disabled={loading}>
                Registrovať sa
              </Button>
            </Form>
          )}
        </Formik>
      </Card>
    </>
  )
}

export default React.memo(SignUp)
