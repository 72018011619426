import React from 'react'
import { motion } from 'framer-motion'

import { Button, PlaceImage } from 'ui'
import { ForRoles } from 'ui/auth'
import { getImage } from 'utils/files'

import Modal from 'components/Modal/Modal'

import { MyBenefitsPayload } from 'apollo/queries'

type Props = {
  data: MyBenefitsPayload[number]
  onAction: () => void
}

const Initial = ({ data, onAction }: Props) => (
  <>
    <Modal.Wrapper>
      <motion.div layoutId="useMyBenefitImage" transition={{ duration: 0.3 }}>
        <PlaceImage
          src={data.image}
          placeholder={getImage(data.image, 'placeholder')}
          alt={data.name}
          defW={591}
          defH={833}
          w="100%"
          h="auto"
          ratio="591 / 833"
        />
      </motion.div>
    </Modal.Wrapper>

    {/* FOR TEACHER */}
    <ForRoles roles={['TEACHER']}>
      <Modal.Actions floating>
        <motion.div
          layoutId="useMyBenefitButton"
          transition={{ duration: 0.3 }}
        >
          <Button onClick={onAction}>Uplatniť</Button>
        </motion.div>
      </Modal.Actions>
    </ForRoles>
    {/* END FOR TEACHER */}
  </>
)

export default React.memo(Initial)
