import styled, {
  FlattenInterpolation,
  keyframes,
  ThemeProps,
} from 'styled-components'

import * as Popover from '@radix-ui/react-popover'
import { TTheme } from 'styles/theme'

interface ITrigger {
  css?: FlattenInterpolation<ThemeProps<TTheme>>
}

const scaleIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`

export const Trigger = styled(Popover.Trigger)<ITrigger>`
  cursor: pointer;

  ${({ css }) => css}
`

export const Content = styled(Popover.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.sizes.xs};

  padding-inline: ${({ theme }) => theme.sizes.m};
  padding-block: ${({ theme }) => theme.sizes.sm};

  background-color: ${({ theme }) => theme.colors.light};
  border-radius: ${({ theme }) => theme.borderRadii.input};

  box-shadow: ${({ theme }) => theme.shadows.card};
  z-index: ${({ theme }) => theme.zIndices.modal};

  transform-origin: var(--radix-popover-content-transform-origin);
  animation: ${scaleIn} 0.2s ease-out;
`
