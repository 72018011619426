import React from 'react'

import * as S from './styled'

type Props = {
  children?: React.ReactNode
}

const Timeline = ({ children }: Props) => <S.List>{children}</S.List>

const Item = ({ children }: Props) => <S.Item>{children}</S.Item>

const Separator = ({ children }: Props) => <S.Separator>{children}</S.Separator>

Timeline.Item = Item
Timeline.Separator = Separator

export default Timeline
