import React from 'react'

import { Box, PlaceImage, Text } from 'ui'

import * as S from './styled'

type Props = {
  title: string
  subtitle?: string
  value?: string | number
  image?: {
    src?: string
    placeholder?: string
    fallback?: string
  }
  rounded?: boolean
  active?: boolean
  secondary?: boolean
  hideImage?: boolean
  onClick?: () => void
  children?: React.ReactNode
}

const SelectItem = ({
  title,
  subtitle,
  value,
  image,
  rounded,
  active,
  secondary,
  hideImage,
  onClick,
  children,
}: Props) => (
  <Box
    as="section"
    minh={50}
    position="relative"
    flexDirection="row"
    alignItems="center"
    bgColor={active ? 'secondary500' : 'grey100'}
    radius="input"
    overflow="hidden"
    paddingLeft={secondary ? 0 : 60}
    largePhone={{ pl: secondary ? 0 : 70, minh: 70 }}
    tablet={{ pl: secondary ? 0 : 92 }}
    css={S.wrapper}
    {...{ onClick }}
  >
    {!hideImage && (
      <PlaceImage
        {...image}
        alt={`${title} image`}
        position={!secondary ? 'absolute' : undefined}
        inset="0"
        right="auto"
        ofit="cover"
        opos="center"
        w="100%"
        h="100%"
        radius={rounded ? 'rounded' : undefined}
        defW={secondary ? 50 : 92}
        defH={secondary ? 50 : 70}
        maxw={secondary ? 50 : 60}
        largePhone={{
          maxw: secondary ? 50 : 70,
        }}
        tablet={{
          maxw: secondary ? 50 : 92,
        }}
        ml={secondary ? 's' : undefined}
        mv={secondary ? 'ss' : undefined}
        ratio={secondary ? '1' : undefined}
      />
    )}

    <Box
      as="header"
      flex={1}
      gap="3xs"
      zIndex="front"
      paddingVertical="xxs"
      paddingHorizontal="ss"
      largePhone={{ pv: 'ss', ph: 's', gap: 'ss' }}
    >
      <Text
        as="h2"
        variant="heading6A"
        color={active ? 'light' : 'primary500'}
        largePhone={{ fontSize: 18, lineHeight: 22 }}
      >
        {title}
      </Text>

      {subtitle && (
        <Box flexDirection="row" gap="3xs">
          <Text
            as="span"
            variant="paragraphSB"
            color="grey800"
            largePhone={{ fontSize: 14, lineHeight: 17 }}
          >
            {subtitle}
            {value && ':'}
          </Text>

          {value && (
            <Text
              as="span"
              variant="paragraphSB"
              color={active ? 'light' : 'primary500'}
              largePhone={{ fontSize: 14, lineHeight: 17 }}
            >
              {value}
            </Text>
          )}
        </Box>
      )}
    </Box>

    {children}
  </Box>
)

type FooterProps = {
  children?: React.ReactNode
}

const Footer = ({ children }: FooterProps) => (
  <Box
    as="footer"
    gap="3xs"
    flexDirection="row"
    alignItems="center"
    justifyContent="flex-end"
    paddingVertical="xxs"
    paddingHorizontal="ss"
    largePhone={{ pv: 'ss', ph: 's', gap: 'ss' }}
  >
    {children}
  </Box>
)

SelectItem.Footer = Footer

export default SelectItem
