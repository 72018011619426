import React, { useCallback } from 'react'
import { useQuery } from '@apollo/client'

import { Box, Text, Button, Search } from 'ui'
import { SelectItem } from 'components/items'
import { getImage } from 'utils/files'

import Modal from 'components/Modal/Modal'

import {
  GET_STUDENT_BENEFITS,
  StudentBenefitsData,
  StudentBenefitsPayload,
} from 'apollo/queries'

type TPayload = Wrap<StudentBenefitsPayload, 'benefits'>
type TData = Wrap<StudentBenefitsData, 'data'>

type ActiveBenefit = StudentBenefitsPayload[number]

type Props = {
  studentId: ID
  active: ActiveBenefit | null
  setActive: (value: ActiveBenefit | null) => void
  onAction: () => void
}

const Initial = ({ studentId, active, setActive, onAction }: Props) => {
  const { data, refetch } = useQuery<TPayload, TData>(GET_STUDENT_BENEFITS, {
    variables: { data: { id: studentId } },
  })

  const handleSearch = useCallback(
    (query: string) => {
      setActive(null)
      refetch({ data: { id: studentId, query } })
    },
    [setActive, refetch, studentId]
  )

  const renderItems = useCallback(
    (item: StudentBenefitsPayload[number]) => (
      <SelectItem
        key={`benefit-${item.id}`}
        title={item.name}
        subtitle="Počet"
        value={item.count}
        image={{
          src: getImage(item.image, 'thumbnail'),
          placeholder: getImage(item.image, 'placeholder'),
          fallback: '/assets/images/duck@fallback.png',
        }}
        active={item.id === active?.id}
        onClick={() => setActive(item)}
      />
    ),
    [active, setActive]
  )

  return (
    <Modal.Wrapper indented condensed onClick={(e) => e.preventDefault()}>
      <Modal.Title>Uplatniť benefit</Modal.Title>

      <Box as="section" gap="3xs">
        {/* LABEL */}
        <Text<'label'> as="label" htmlFor="title" variant="paragraphMB">
          Názov benefitu
        </Text>
        {/* END LABEL */}

        <Search
          id="title"
          placeholder="Názov"
          callback={handleSearch}
          fullWidth
        />
      </Box>

      {/* BENEFITS LIST */}
      <Box maxHeight={395} overflow="scroll" radius="input">
        <Box mb="s" gap="s">
          {data?.benefits?.map(renderItems)}
        </Box>
      </Box>
      {/* END BENEFITS LIST */}

      <Modal.Actions>
        <Button disabled={!active} onClick={onAction}>
          Uplatniť
        </Button>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export default React.memo(Initial)
