export enum Role {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  TEACHER = 'TEACHER',
  STUDENT = 'STUDENT',
}

export type User = {
  id: ID

  email: string
  role: Role

  name: string
  surname: string
  birthYear?: number

  school: School
  avatar: Avatar

  registeredAt?: string
  deletedAt?: string
  updatedAt?: string
  createdAt: string

  invite?: Invite
  resetPassword?: ResetPassword

  classes?: ClassMember[]
  subjects?: TeacherSubject[]

  myTasks?: StudentTask[]
  tasks?: StudentTask[]

  myBenefits?: StudentBenefit[]
  benefits?: UsedBenefit[]
}

export type Avatar = {
  id: ID

  name: string
  level: number
  image: string

  users?: User[]
}

export type ResetPassword = {
  id: ID

  token: string

  user: User

  updatedAt?: string
  createdAt: string
}

export type Invite = {
  id: ID

  token: string

  user: User

  updatedAt?: string
  createdAt: string
}

export type Class = {
  id: ID

  school: School

  name: string
  description?: string
  logo?: string

  deletedAt?: string
  updatedAt?: string
  createdAt: string

  members?: ClassMember[]
  benefits?: UsedBenefit[]
}

export type School = {
  id: ID

  name: string
  hours: number

  deletedAt?: string
  updatedAt?: string
  createdAt: string

  users?: User[]
  classes?: Class[]
  subjects?: Subject[]
}

export type ClassMember = {
  id: ID

  user: User
  class: Class

  createdAt: string
}

export type Subject = {
  id: ID

  school: School

  name: string

  deletedAt?: string
  updatedAt?: string
  createdAt: string

  teachers?: TeacherSubject[]
  tasks?: StudentTask[]
  benefits?: UsedBenefit[]
}

export type TeacherSubject = {
  id: ID

  subject: Subject
  teacher: User

  createdAt: string
}

export type Task = {
  id: ID

  name: string
  code: string

  image: string
  points: number

  deletedAt?: string
  updatedAt?: string
  createdAt: string

  students?: StudentTask[]
}

export type Benefit = {
  id: ID

  name: string
  level: number
  scale: number

  image: string

  deletedAt?: string
  updatedAt?: string
  createdAt: string

  students?: StudentBenefit[]
}

export type StudentTask = {
  id: ID

  student: User
  teacher: User
  subject: Subject
  task: Task

  points: number
  coins: number

  deletedAt?: string
  updatedAt?: string
  createdAt: string
}

export type StudentBenefit = {
  id: ID

  student: User
  benefit: Benefit

  coins: number

  deletedAt?: string
  updatedAt?: string
  createdAt: string

  benefits?: UsedBenefit[]
}

export type UsedBenefit = {
  id: ID

  benefit: StudentBenefit

  teacher: User
  class: Class
  subject: Subject

  deletedAt?: string
  updatedAt?: string
  createdAt: string
}
