import { gql } from '@apollo/client'

import { User } from 'types/models'

export type RemoveMemberData = {
  id: ID
  classId: ID
}

export type RemoveMemberPayload = User

export default gql`
  mutation removeMember($data: RemoveMemberData!) {
    user: removeMember(data: $data) {
      id
    }
  }
`
