import React, { useCallback } from 'react'
import { useQuery } from '@apollo/client'

import { Head } from 'ui'
import { Content, Grid } from 'components/layout'
import { Task } from 'pages/sections'

import { GET_TASKS, TasksPayload } from 'apollo/queries'

type TPayload = Wrap<TasksPayload, 'tasks'>

const Tasks = () => {
  const { data, loading } = useQuery<TPayload>(GET_TASKS)

  const renderItems = useCallback(
    (item: TasksPayload[number]) => (
      <Task key={`task-${item.id}`} data={item} />
    ),
    []
  )

  return (
    <>
      <Head title="Galéria úloh" />

      <Content>
        {data?.tasks?.length ? (
          <Grid>{data?.tasks?.map(renderItems)}</Grid>
        ) : loading ? (
          <Content.Loading />
        ) : (
          <Content.Empty text="Žiadne úlohy sa nenašli" />
        )}
      </Content>
    </>
  )
}

export default React.memo(Tasks)
