import { css } from 'styled-components'

export const wrapper = css`
  user-select: none;
  flex-shrink: 0;

  img {
    width: 100%;
    height: auto;
  }
`
