import { gql } from '@apollo/client'

import { Class } from 'types/models'

export type RemoveClassData = {
  id: ID
  teacherId: ID
}

export type RemoveClassPayload = Class

export default gql`
  mutation removeClass($data: RemoveClassData!) {
    class: removeClass(data: $data) {
      id
    }
  }
`
