import { gql } from '@apollo/client'

import { Class } from 'types/models'

export type NewClassesData = {
  id: ID
  query?: string
}

export type NewClassesPayload = Class[]

export default gql`
  query getNewClasses($data: IdqueryData!) {
    classes: getNewClasses(data: $data) {
      id
      name
      logo
    }
  }
`
