import styled, { FlattenInterpolation, ThemeProps } from 'styled-components'
import SVG from 'react-inlinesvg'

import { TTheme } from 'styles/theme'

interface IWrapper {
  $css?: FlattenInterpolation<ThemeProps<TTheme>>
}

export const Wrapper = styled(SVG)<IWrapper>`
  ${({ $css }) => !!$css && $css}
`
