import { gql } from '@apollo/client'

import { StudentTask } from 'types/models'

export type UpsertTaskPayload = StudentTask

export default gql`
  mutation upsertTask($data: UpsertTaskData!) {
    studentTask: upsertTask(data: $data) {
      id
      points
      coins
      createdAt
      points
      coins
      createdAt
      teacher {
        id
        name
        surname
        avatar {
          id
          image
        }
      }
      student {
        id
        points
        range
        level
        coins
        totalTasks
        totalBenefits
      }
      subject {
        id
        name
      }
      task {
        id
        name
        image
      }
    }
  }
`
