import { mapObject } from 'utils/theme'

const palette = {
  white: '#FFFFFF',
  lightGrey: '#F9FAFD',
  black: '#000000',
  lightenBlue: '#6575E3',
  lightBlue: '#4E5DB8',
  blue: '#505DB6',
  darkenBlue: '#6A6DDA',
  darkBlue: '#15206C',
  blue80: ' #000B55CC',
  black30: 'rgba(0, 0, 0, 0.3)',
  azure: '#50B6B0',
  darkRed: '#F25C5C',
  darkenRed: '#D73636',
  darkGreen: '#28B775',
  grey: {
    100: '#F9F9F9',
    200: '#E0E0E0',
    300: '#C5C5C5',
    400: '#AAAAAA',
    500: '#8E8E8E',
    600: '#747474',
    700: '#575757',
    800: '#3D3D3D',
    900: '#212121',
  },
  orange: {
    100: '#FFDECE',
    200: '#FFB693',
    300: '#FF9765',
    400: '#FF8245',
    500: '#F15A29',
    600: '#DE5411',
    700: '#B5420A',
    800: '#8E3306',
    900: '#662303',
  },
  green: {
    100: '#CDFFE8',
    200: '#9BF7CC',
    300: '#67F8B4',
    400: '#2EE792',
    500: '#28B775',
    600: '#16965B',
    700: '#006838',
    800: '#085833',
    900: '#054326',
  },
  yellow: {
    100: '#FFF3C5',
    200: '#FFEB9B',
    300: '#FFE57B',
    400: '#FCDC5C',
    500: '#F3CD33',
    600: '#DDB71D',
    700: '#C1A017',
    800: '#997D0D',
    900: '#766007',
  },
} as const

const textVariants = {
  headingAvenir: `
    font-family: Avenir, sans-serif;
    font-weight: 700;
  `,
  headingTime: `
    font-family: Time, sans-serif;
    font-weight: 400;
  `,
  paragraph: `
    font-family: Avenir, sans-serif;
    font-weight: 400;
  `,
  paragraphB: `
    font-family: Avenir, sans-serif;
    font-weight: 700;
  `,
  anchor: `
    font-family: Avenir, sans-serif;
    text-decoration-line: underline;
    font-size: 14px;
    line-height: 17px;
  `,
} as const

const THEME = {
  colors: {
    light: palette.white,
    dark: palette.black,
    bg: palette.blue,
    fg: palette.darkenBlue,
    text: palette.darkBlue,
    separator: palette.lightenBlue,

    ...mapObject(palette.grey, 'grey'),
    ...mapObject(palette.orange, 'primary'),
    ...mapObject(palette.green, 'secondary'),
    ...mapObject(palette.yellow, 'tertiary'),

    overlay: palette.blue80,
    backdrop: palette.black30,
    negative: palette.darkRed,
    positive: palette.darkGreen,
    dangerous: palette.darkenRed,

    info: `linear-gradient(90deg, #3A458E 0%, ${palette.darkBlue} 100%)`,
    success: `linear-gradient(90deg, #003C20 0%, ${palette.darkBlue} 100%)`,
    danger: `linear-gradient(90deg, #4D0000 0%, ${palette.darkBlue} 100%)`,
    warning: `linear-gradient(90deg, #4F3F01 0%, ${palette.darkBlue} 100%)`,

    lightBgG: `linear-gradient(180deg, ${palette.azure} 0%, ${palette.lightBlue} 100%)`,
    darkBgG: `linear-gradient(180deg, ${palette.blue} 0%, ${palette.lightBlue} 100%)`,
    progress: `linear-gradient(180deg, ${palette.grey[400]} 0%, ${palette.grey[300]} 100%)`,
    progressLine: `linear-gradient(180deg, ${palette.orange[400]} 0%, ${palette.orange[500]} 100%)`,
    slider: `linear-gradient(90deg, ${palette.darkRed} 0%, ${palette.darkGreen} 100%)`,
    fade: `linear-gradient(270deg, ${palette.darkBlue} 0%, rgba(21, 32, 108, 0) 100%)`,
  },

  shadows: {
    card: '0px 0px 5px rgba(0, 0, 0, 0.05)',
    profile: '0px 4px 4px rgba(0, 0, 0, 0.4)',
    thumb: '0px 3px 3px rgba(0, 0, 0, 0.05)',
    toast: '0px 0px 6px 2px rgba(0, 0, 0, 0.25)',
    nav: '-5px 0px 5px 2px rgba(0, 0, 0, 0.15)',
    button: {
      default: '0px 6px 8px rgba(0, 0, 0, 0.15)',
      hover: '0px 6px 4px rgba(0, 0, 0, 0.15)',
    },
    progress: {
      wrapper: '0px 4px 4px 1px rgba(0, 0, 0, 0.4)',
      inside: '0px 0px 5px 5px rgba(250, 113, 58, 0.3)',
    },
  },

  sizes: {
    '4xs': '2px',
    '3xs': '4px',
    xxs: '8px',
    ss: '10px',
    xs: '12px',
    s: '16px',
    sm: '20px',
    m: '24px',
    l: '32px',
    xl: '40px',
    xxl: '48px',
    '3xl': '56px',
    '4xl': '64px',
    '5xl': '72px',
    '6xl': '80px',
    '7xl': '88px',
    '8xl': '96px',
  },

  borderRadii: {
    rounded: '10000px',
    input: '10px',
    toast: '15px',
    mid: '20px',
    card: '30px',
  },

  zIndices: {
    base: 0,
    front: 1,
    xl: 100,
    xxl: 200,
    nav: 300,
    modal: 999,
    toast: 9999,
  },

  breakpoints: {
    phone: 0,
    largePhone: 425,
    tablet: 768,
    desktop: 1024,
    largeDesktop: 1440,
    container: 1320,
  },

  textVariants: {
    // A headings
    heading1A: `
      ${textVariants.headingAvenir}
      font-size: 36px;
      line-height: 43px;
    `,
    heading2A: `
      ${textVariants.headingAvenir}
      font-size: 28px;
      line-height: 34px;
    `,
    heading3A: `
      ${textVariants.headingAvenir}
      font-size: 24px;
      line-height: 29px;
    `,
    heading4A: `
      ${textVariants.headingAvenir}
      font-size: 20px;
      line-height: 24px;
    `,
    heading5A: `
      ${textVariants.headingAvenir}
      font-size: 18px;
      line-height: 22px;
    `,
    heading6A: `
      ${textVariants.headingAvenir}
      font-size: 15px;
      line-height: 18px;
    `,

    // B headings
    heading1B: `
      ${textVariants.headingTime}
      font-size: 36px;
      line-height: 62px;
    `,
    heading2B: `
      ${textVariants.headingTime}
      font-size: 28px;
      line-height: 48px;
    `,
    heading3B: `
      ${textVariants.headingTime}
      font-size: 24px;
      line-height: 41px;
    `,
    heading4B: `
      ${textVariants.headingTime}
      font-size: 20px;
      line-height: 34px;
    `,
    heading5B: `
      ${textVariants.headingTime}
      font-size: 18px;
      line-height: 31px;
    `,
    heading6B: `
      ${textVariants.headingTime}
      font-size: 15px;
      line-height: 26px;
    `,

    // Paragraphs
    paragraphS: `
      ${textVariants.paragraph}
      font-size: 12px;
      line-height: 14px;
    `,
    paragraphM: `
      ${textVariants.paragraph}
      font-size: 14px;
      line-height: 17px;
    `,
    paragraphL: `
      ${textVariants.paragraph}
      font-size: 16px;
      line-height: 19px;
    `,

    // Bold paragraphs
    paragraphSB: `
      ${textVariants.paragraphB}
      font-size: 12px;
      line-height: 14px;
    `,
    paragraphMB: `
      ${textVariants.paragraphB}
      font-size: 14px;
      line-height: 17px;
    `,
    paragraphLB: `
      ${textVariants.paragraphB}
      font-size: 16px;
      line-height: 19px;
    `,

    // Anchors
    anchor: `
      ${textVariants.paragraphB}
      font-weight: 400;
    `,
    anchorB: `
      ${textVariants.paragraphB}
      font-weight: 700;
    `,
  },
} as const

export type TTheme = typeof THEME

export type TThemeColor = keyof TTheme['colors']

export type TThemeSize = keyof TTheme['sizes']

export type TThemeShadow = keyof TTheme['shadows']

export type TThemeBorderRadius = keyof TTheme['borderRadii']

export type TThemeZIndex = keyof TTheme['zIndices']

export type TThemeBreakpoint = keyof TTheme['breakpoints']

export type TThemeTextVariant = keyof TTheme['textVariants']

export default THEME
