import React from 'react'

import { Box, Image, PlaceImage, Text } from 'ui'
import { TThemeColor } from 'styles/theme'
import { getImage } from 'utils/files'

import * as S from './styled'

type CardProps = {
  title: string
  image: string
  color: TThemeColor
  sharp?: boolean
  space?: boolean
  disabled?: boolean
  onClick?: () => void
  children?: React.ReactNode
}

const Card = ({
  title,
  image,
  color,
  sharp,
  space,
  disabled,
  onClick,
  children,
}: CardProps) => (
  <Box
    as="article"
    zIndex="base"
    position="relative"
    radius={!sharp ? 'input' : undefined}
    overflow={!sharp ? 'hidden' : undefined}
    marginBottom={sharp && space ? '4xl' : undefined}
    css={S.wrapper}
    {...{ onClick, disabled }}
  >
    <PlaceImage
      src={getImage(image, 'thumbnail')}
      placeholder={getImage(image, 'placeholder')}
      alt={title}
      defW={245}
      defH={298}
      w="100%"
      h="100%"
      maxh="100%"
      ofit="cover"
      opos="center 50%"
    />

    {children}

    <Box
      as="header"
      inset="0"
      top="auto"
      zIndex="3"
      padding="xxs"
      position="absolute"
      alignItems="center"
      minHeight={69}
      height="23%"
      bgColor={color}
    >
      <Text as="h1" variant="heading5A" color="light" textAlign="center">
        {title}
      </Text>
    </Box>
  </Box>
)

type BadgeProps = {
  color: TThemeColor
  side: 'left' | 'right'
  value: number | string
}

const Badge = ({ color, side, value }: BadgeProps) => (
  <Box
    as="section"
    top="0"
    gap="3xs"
    zIndex="3"
    padding="ss"
    position="absolute"
    flexDirection="row"
    alignItems="center"
    bgColor={color}
    bottomRightRadius={side === 'left' ? 'mid' : undefined}
    bottomLeftRadius={side === 'right' ? 'mid' : undefined}
    {...{ [side]: 0 }}
  >
    <Text as="span" variant="heading6B" color="light">
      {value}
    </Text>

    <Image
      src={side === 'left' ? 'icons/beak.svg' : 'icons/coin.svg'}
      alt={side === 'left' ? 'points' : 'coins'}
      width={16}
      height={16}
    />
  </Box>
)

type CountProps = {
  text: string | number
}

const Count = ({ text }: CountProps) => (
  <Box
    as="footer"
    position="absolute"
    alignItems="center"
    left="0"
    right="0"
    top="100%"
  >
    <Box
      position="relative"
      bgColor="primary500"
      bottomLeftRadius="input"
      bottomRightRadius="mid"
      paddingHorizontal="s"
      paddingBottom="s"
      css={S.before}
    >
      <Text variant="heading2B" color="light">
        {text}x
      </Text>
    </Box>
  </Box>
)

Card.Badge = Badge
Card.Count = Count

export default Card
