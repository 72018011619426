import React, { useMemo } from 'react'
import { motion } from 'framer-motion'

import { Box, Button, Image, Text } from 'ui'

import type { Toast as TToast } from 'stores/toast'

type Props = {
  data: TToast
}

const Item = ({ data }: Props) => {
  const color = useMemo(
    () =>
      ({
        info: 'grey100',
        success: 'secondary500',
        danger: 'dangerous',
        warning: 'tertiary500',
      }[data?.type || 'info']),
    [data]
  )

  return (
    <Box
      as="section"
      position="relative"
      bg={data.type}
      flexDirection="row"
      alignItems="center"
      radius="toast"
      overflow="hidden"
      padding="sm"
      maxw={400}
      minw={200}
      gap="l"
    >
      <Image
        src={`icons/${data.type || 'info'}.svg`}
        alt={data.type}
        width={36}
        height={36}
      />

      <Box gap="xxs">
        <Text as="h3" variant="heading6A" {...{ color }}>
          {data.title}
        </Text>

        {data?.message && (
          <Text variant="paragraphSB" color="grey300">
            {data?.message}
          </Text>
        )}

        {data?.action && (
          <Button onClick={data?.action?.onClick} variant="secondary">
            {data?.action?.text}
          </Button>
        )}
      </Box>

      {data?.duration !== Infinity && (
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: '100%' }}
          transition={{ duration: data.duration! / 1000 }}
          style={{
            overflow: 'hidden',
            position: 'absolute',
            bottom: 0,
            left: 0,
          }}
        >
          <Box w="100%" h={2} radius="rounded" bgColor={color} />
        </motion.div>
      )}
    </Box>
  )
}

export default React.memo(Item)
