import { gql } from '@apollo/client'

import { Subject } from 'types/models'

export type UnassignSubjectData = {
  id: ID
  teacherId: ID
}

export type UnassignSubjectPayload = Subject

export default gql`
  mutation unassignSubject($data: UnassignSubjectData!) {
    subject: unassignSubject(data: $data) {
      id
    }
  }
`
