import { gql } from '@apollo/client'

import { Class } from 'types/models'

export type NewStudentClassesData = {
  id: ID
  query?: string
}

export type NewStudentClassesPayload = Class[]

export default gql`
  query getNewStudentClasses($data: IdqueryData!) {
    classes: getNewStudentClasses(data: $data) {
      id
      name
      logo
    }
  }
`
