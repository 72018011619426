import React, { useCallback, useState } from 'react'
import { MotionContext } from 'framer-motion'

import { Initial, Assignment } from './sections'

import { MyBenefitsPayload } from 'apollo/queries'

type Props = {
  classId?: ID
  studentId?: ID
  data: MyBenefitsPayload[number]
}

type Steps = 'initial' | 'assignment'

const BuyBenefit = ({ classId, studentId, data }: Props) => {
  const [step, setStep] = useState<Steps>('initial')

  const handleStep = useCallback((step: Steps) => () => setStep(step), [])

  return (
    <MotionContext.Provider value={{}}>
      {step === 'initial' && (
        <Initial onAction={handleStep('assignment')} {...{ data }} />
      )}

      {step === 'assignment' && classId && studentId && (
        <Assignment {...{ data, classId, studentId }} />
      )}
    </MotionContext.Provider>
  )
}

export default React.memo(BuyBenefit)
