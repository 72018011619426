import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'

import { Button } from 'ui'
import { Modal } from 'components'
import { Item } from 'components/items'
import { ForRoles } from 'ui/auth'

import { MyClassesPayload } from 'apollo/queries'
import { DeleteModal } from './modals'

type Props = {
  teacherId?: ID
  data: MyClassesPayload[number]
}

const MyClass = ({ teacherId, data }: Props) => {
  const navigate = useNavigate()

  const toDetail = useCallback(() => navigate(`/${data.id}`), [navigate, data])

  return (
    <Item
      title={data?.name}
      subtitle={data?.description}
      image={{
        src: data?.logo,
        placeholder: '/assets/logo.svg',
        fallback: '/assets/logo.svg',
      }}
      onClick={toDetail}
      secondary
    >
      <ForRoles roles={['MANAGER']}>
        {!!teacherId && (
          <Item.Footer end>
            <Modal>
              <Modal.Trigger disabled={!teacherId}>
                <Button as="div">Odobrať</Button>
              </Modal.Trigger>

              <Modal.Content extended>
                {teacherId && <DeleteModal {...{ data, teacherId }} />}
              </Modal.Content>
            </Modal>
          </Item.Footer>
        )}
      </ForRoles>
    </Item>
  )
}

export default React.memo(MyClass)
