import { gql } from '@apollo/client'

import { UsedBenefit } from 'types/models'

export type MyUsedData = {
  id?: ID
}

export type MyUsedPayload = ({
  count: number
} & UsedBenefit)[]

export default gql`
  query getMyUsed($data: OptidData!) {
    myUsed: getMyUsed(data: $data) {
      id
      createdAt
      teacher {
        id
        name
        surname
        avatar {
          id
          name
          image
        }
      }
      subject {
        id
        name
      }
      benefit {
        id
        benefit {
          id
          name
          image
        }
      }
    }
  }
`
