import React from 'react'
import { useField } from 'formik'

import { Box } from 'ui'
import { SelectItem } from 'components/items'

import { NewClassesPayload } from 'apollo/queries'

type Props = {
  data: NewClassesPayload[number]
}

const Item = ({ data }: Props) => {
  const [field] = useField({
    type: 'checkbox',
    name: 'classes',
    value: data.id,
  })

  return (
    <Box<'label'> as="label" position="relative" style={{ cursor: 'pointer' }}>
      <SelectItem
        title={data.name}
        image={{
          src: data?.logo,
          placeholder: '/assets/logo.svg',
          fallback: '/assets/logo.svg',
        }}
        active={field.checked}
        secondary
        rounded
      />

      <input type="checkbox" {...field} style={{ display: 'none' }} />
    </Box>
  )
}

export default React.memo(Item)
