import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import { Box, Button } from 'ui'
import { Modal } from 'components'
import { AddTaskModal } from 'components/modals'
import { Content, Timeline } from 'components/layout'
import { MyTask } from 'pages/sections'
import { equalDates, isToday, toDateFormat } from 'utils/date'
import { ForRoles } from 'ui/auth'

import { DEFAULT_POLL_INTERVAL } from 'constants/global'
import { GET_MY_TASKS, MyTasksData, MyTasksPayload } from 'apollo/queries'

type DateSeparatorProps = {
  curr: MyTasksPayload[number]
  prev?: MyTasksPayload[number]
}

type Params = {
  id?: ID
  studentId?: ID
}

type TPayload = Wrap<MyTasksPayload, 'myTasks'>
type TData = Wrap<MyTasksData, 'data'>

const DateSeparator = ({ prev, curr }: DateSeparatorProps) =>
  !prev || (!!prev && !equalDates(prev.createdAt, curr.createdAt)) ? (
    <Timeline.Separator>
      {isToday(curr.createdAt) ? 'Dnes' : toDateFormat(curr.createdAt)}
    </Timeline.Separator>
  ) : null

const PointsHistory = () => {
  const navigate = useNavigate()
  const { id, studentId } = useParams<Params>()

  const { data, loading } = useQuery<TPayload, TData>(GET_MY_TASKS, {
    variables: { data: { id: studentId || '' } },
    pollInterval: !studentId ? DEFAULT_POLL_INTERVAL : undefined,
    onError: () => {
      if (studentId) navigate(`/${id || ''}`, { replace: true })
    },
  })

  const renderItems = useCallback(
    (item: MyTasksPayload[number], idx: number) => (
      <React.Fragment key={`my-points-${item.id}`}>
        <DateSeparator prev={data?.myTasks?.[idx - 1]} curr={item} />
        <MyTask data={item} />
      </React.Fragment>
    ),
    [data]
  )

  return (
    <>
      <ForRoles roles={['TEACHER']}>
        <Box flexDirection="row" mb="xs" largePhone={{ mb: 'sm' }}>
          <Modal>
            <Modal.Trigger disabled={!studentId}>
              <Button as="div" disabled={!studentId}>
                Pridať úlohu
              </Button>
            </Modal.Trigger>

            <Modal.Content extended overflow>
              {studentId && <AddTaskModal {...{ studentId }} />}
            </Modal.Content>
          </Modal>
        </Box>
      </ForRoles>

      {data?.myTasks?.length ? (
        <Timeline>{data?.myTasks?.map(renderItems)}</Timeline>
      ) : loading ? (
        <Content.Loading />
      ) : (
        <Content.Empty text="Nenašla sa žiadna história bodov" />
      )}
    </>
  )
}

export default React.memo(PointsHistory)
