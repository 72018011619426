import { gql } from '@apollo/client'

import { Subject } from 'types/models'

export type NewSubjectsData = {
  id: ID
  query?: string
}

export type NewSubjectsPayload = Subject[]

export default gql`
  query getNewSubjects($data: IdqueryData!) {
    subjects: getNewSubjects(data: $data) {
      id
      name
    }
  }
`
