import { gql } from '@apollo/client'

import { Benefit } from 'types/models'

export type StudentBenefitsData = {
  id: ID
  query?: string
}

export type StudentBenefitsPayload = ({
  count: number
} & Benefit)[]

export default gql`
  query getStudentBenefits($data: IdqueryData!) {
    benefits: getStudentBenefits(data: $data) {
      id
      name
      image
      count
    }
  }
`
