import React, { useMemo } from 'react'
import { To, useLocation } from 'react-router'
import { motion } from 'framer-motion'

import { Text } from 'ui'
import isLinkActive, { NavigationKey } from 'utils/navigation'

import * as S from './styled'

type Props = {
  to: To
  icon: string
  navKey: NavigationKey
  children: React.ReactNode
}

const Link = ({ to, icon, navKey, children }: Props) => {
  const { pathname } = useLocation()

  const isActive = useMemo(
    () => isLinkActive(navKey, pathname),
    [navKey, pathname]
  )

  return (
    <S.Wrapper {...{ to, icon }}>
      <Text
        as="span"
        variant="paragraphLB"
        color={isActive ? 'light' : 'grey800'}
      >
        {children}
      </Text>

      {isActive && (
        <motion.div
          layoutId="nav"
          transition={{ duration: 0.2, ease: 'linear' }}
          style={{
            background: '#28B775',
            position: 'absolute',
            inset: 0,
            borderRadius: 999,
            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.15)',
          }}
        />
      )}
    </S.Wrapper>
  )
}

export default React.memo(Link)
