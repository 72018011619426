import React, { useCallback, useMemo } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { useMutation, useQuery } from '@apollo/client'

import { Button, Error, Select } from 'ui'
import { SelectItem } from 'components/items'
import { useErrorHandler } from 'hooks'
import useToastStore from 'stores/toast'
import { getImage } from 'utils/files'

import Modal, { useModal } from 'components/Modal/Modal'

import {
  GET_MY_BENEFITS,
  GET_MY_SUBJECTS,
  GET_MY_USED,
  GET_STUDENT_BENEFITS,
  MySubjectsData,
  MySubjectsPayload,
  MyUsedPayload,
  StudentBenefitsPayload,
} from 'apollo/queries'
import { UseBenefitPayload, USE_BENEFIT } from 'apollo/mutations'
import validationSchema, { UseBenefitData } from 'schemas/useBenefit'

type ActiveBenefit = StudentBenefitsPayload[number]

type Props = {
  studentId: ID
  classId: ID
  active: ActiveBenefit
  onAction: () => void
}

type TQPayload = Wrap<MySubjectsPayload, 'mySubjects'>
type TQData = Wrap<MySubjectsData, 'data'>

type TMPayload = Wrap<UseBenefitPayload, 'usedBenefit'>
type TMData = Wrap<UseBenefitData, 'data'>

const Assignment = ({ active, studentId, classId, onAction }: Props) => {
  const { hide } = useModal()
  const { handle, error } = useErrorHandler()
  const { show } = useToastStore()

  const { data } = useQuery<TQPayload, TQData>(GET_MY_SUBJECTS, {
    variables: { data: {} },
  })

  const [addBenefit, { loading }] = useMutation<TMPayload, TMData>(
    USE_BENEFIT,
    {
      onCompleted: ({ usedBenefit }) =>
        show({
          title: 'Benefit bol použitý',
          message: usedBenefit?.benefit?.benefit?.name,
          type: 'success',
        }),
    }
  )

  const initialValues = useMemo(
    (): UseBenefitData => ({
      studentId,
      classId,
      benefitId: active.id,
      subject: data?.mySubjects?.[0]?.id || '',
    }),
    [active, data, studentId, classId]
  )

  const onSubmit = useCallback(
    (values: UseBenefitData, formikHelpers: FormikHelpers<UseBenefitData>) => {
      addBenefit({
        variables: { data: values },
        refetchQueries: [
          {
            query: GET_STUDENT_BENEFITS,
            variables: { data: { id: studentId } },
          },
          {
            query: GET_MY_BENEFITS,
            variables: { data: { id: studentId } },
          },
        ],
        update: (cache, { data: response }) => {
          cache.updateQuery(
            {
              query: GET_MY_USED,
              variables: { data: { id: studentId } },
            },
            (data?: Wrap<MyUsedPayload, 'myUsed'>) => {
              if (data?.myUsed) {
                return { myUsed: [response!.usedBenefit, ...data.myUsed] }
              }
            }
          )
        },
      })
        .then(hide)
        .catch(handle(formikHelpers))
    },
    [addBenefit, hide, handle, studentId]
  )

  return (
    <Modal.Wrapper indented onClick={(e) => e.preventDefault()}>
      <Modal.Title goBack={onAction}>Uplatnenie benefitu</Modal.Title>

      <SelectItem
        key={`benefit-${active.id}`}
        title={active?.name}
        subtitle="Počet"
        value={active.count}
        image={{
          src: getImage(active.image, 'thumbnail'),
          placeholder: getImage(active.image, 'placeholder'),
          fallback: '/assets/images/duck@fallback.png',
        }}
      />

      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        validateOnChange
        enableReinitialize
      >
        {({ errors, touched }) => (
          <Form style={{ gap: 32 }}>
            <Select<MySubjectsPayload[number]>
              name="subject"
              label="Predmet"
              hasError={touched.subject && errors.subject}
              disabled={loading}
              defaultValue={data?.mySubjects?.[0]?.id}
              items={data?.mySubjects}
              required
            />

            <Error message={error} />

            <Modal.Actions>
              <Button type="submit" isLoading={loading} disabled={loading}>
                Uplatniť benefit
              </Button>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal.Wrapper>
  )
}

export default React.memo(Assignment)
