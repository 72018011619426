import React, { useCallback, useMemo } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { useMutation, useQuery } from '@apollo/client'

import { Box, Text, Button, Search } from 'ui'
import { useErrorHandler } from 'hooks'
import { Item } from './sections'
import useToastStore from 'stores/toast'

import Modal, { useModal } from 'components/Modal/Modal'

import {
  GET_NEW_CLASSES,
  GET_TEACHER_CLASSES,
  NewClassesData,
  NewClassesPayload,
  TeacherClassesPayload,
} from 'apollo/queries'
import { AssignClassesPayload, ASSIGN_CLASSES } from 'apollo/mutations'
import validationSchema, { AssignClassesData } from 'schemas/assignClasses'

type Props = {
  teacherId: ID
}

type TQPayload = Wrap<NewClassesPayload, 'classes'>
type TQData = Wrap<NewClassesData, 'data'>

type TMPayload = Wrap<AssignClassesPayload, 'classes'>
type TMData = Wrap<AssignClassesData, 'data'>

const AssignClass = ({ teacherId }: Props) => {
  const { hide } = useModal()
  const { handle } = useErrorHandler()
  const { show } = useToastStore()

  const { data, refetch } = useQuery<TQPayload, TQData>(GET_NEW_CLASSES, {
    variables: { data: { id: teacherId } },
  })

  const [assignClasses, { loading }] = useMutation<TMPayload, TMData>(
    ASSIGN_CLASSES,
    {
      onCompleted: () =>
        show({
          title: 'Triedy boli úspešne priradené',
          type: 'success',
        }),
    }
  )

  const initialValues = useMemo(
    (): AssignClassesData => ({
      teacherId,
      classes: [],
    }),
    [teacherId]
  )

  const onSubmit = useCallback(
    (
      values: AssignClassesData,
      formikHelpers: FormikHelpers<AssignClassesData>
    ) => {
      assignClasses({
        variables: { data: values },
        refetchQueries: [
          {
            query: GET_NEW_CLASSES,
            variables: { data: { id: teacherId } },
          },
        ],
        update: (cache, { data: response }) => {
          cache.updateQuery(
            {
              query: GET_TEACHER_CLASSES,
              variables: { data: { id: teacherId } },
            },
            (data?: Wrap<TeacherClassesPayload, 'classes'>) => {
              if (data?.classes) {
                return { classes: [...response!.classes, ...data.classes] }
              }
            }
          )
        },
      })
        .then(hide)
        .catch(handle(formikHelpers))
    },
    [assignClasses, hide, handle, teacherId]
  )

  const handleSearch = useCallback(
    (query: string) => {
      refetch({ data: { id: teacherId, query } })
    },
    [refetch, teacherId]
  )

  const renderItems = useCallback(
    (item: NewClassesPayload[number]) => (
      <Item key={`class-${item.id}`} data={item} />
    ),
    []
  )

  return (
    <Modal.Wrapper indented condensed>
      <Modal.Title>Priradiť triedu</Modal.Title>

      <Box as="section" gap="3xs">
        {/* LABEL */}
        <Text<'label'> as="label" htmlFor="title" variant="paragraphMB">
          Názov triedy
        </Text>
        {/* END LABEL */}

        <Search
          id="title"
          placeholder="Názov"
          callback={handleSearch}
          fullWidth
        />
      </Box>

      <Formik
        {...{ validationSchema, initialValues, onSubmit }}
        validateOnChange
      >
        {({ values }) => (
          <Form>
            {/* CLASSES LIST */}
            <Box maxHeight={395} overflow="scroll" radius="input">
              <Box mb="s" gap="s">
                {data?.classes?.map(renderItems)}
              </Box>
            </Box>
            {/* END CLASSES LIST */}

            <Modal.Actions inForm>
              <Button
                type="submit"
                disabled={!values.classes.length || loading}
                isLoading={loading}
              >
                Pridať
              </Button>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal.Wrapper>
  )
}

export default React.memo(AssignClass)
