import React, { ComponentProps } from 'react'

import Icon from 'ui/Icon'
import { Box } from 'ui/core'

import * as S from './styled'

type Props = {
  onClick?: () => void
  IconProps: ComponentProps<typeof Icon>
} & ComponentProps<typeof Box>

const Action = ({ onClick, IconProps, ...props }: Props) => (
  <Box<'button'>
    as="button"
    display="inline-flex"
    alignItems="center"
    justifyContent="center"
    bgColor="primary500"
    radius="rounded"
    width={28}
    height={28}
    largePhone={{ w: 34, h: 34 }}
    css={S.wrapper}
    {...{ onClick, ...props }}
  >
    <Icon {...IconProps} />
  </Box>
)

export default React.memo(Action)
