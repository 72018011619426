import React from 'react'
import { motion } from 'framer-motion'

import { PlaceImage } from 'ui'
import { Modal } from 'components'
import { Card } from 'components/items'

import { TasksPayload } from 'apollo/queries'
import { getImage } from 'utils/files'

type Props = {
  data: TasksPayload[number]
}

const Task = ({ data }: Props) => (
  <Modal>
    {/* TRIGGER */}
    <Modal.Trigger>
      <motion.div whileHover={{ scale: 1.05 }}>
        <Card title={data.name} image={data.image} color="secondary600">
          <Card.Badge value={data.points} side="left" color="secondary600" />
          <Card.Badge value={data.coins} side="right" color="secondary600" />
        </Card>
      </motion.div>
    </Modal.Trigger>
    {/* END TRIGGER */}

    {/* MODAL */}
    <Modal.Content extended>
      <Modal.Wrapper>
        <PlaceImage
          src={data.image}
          placeholder={getImage(data.image, 'placeholder')}
          alt={data.name}
          defW={591}
          defH={833}
          w="100%"
          h="auto"
          ratio="591 / 833"
        />
      </Modal.Wrapper>
    </Modal.Content>
    {/* END MODAL */}
  </Modal>
)

export default React.memo(Task)
