import { gql } from '@apollo/client'

import { User } from 'types/models'

export type StudentData = {
  id?: ID
}

export type StudentPayload = {
  fullName: string
  points: number
  coins: number
  level: number
  range: number
} & User

export default gql`
  query getStudent($data: OptidData) {
    student: getStudent(data: $data) {
      id
      email
      name
      surname
      role
      fullName
      birthYear
      points
      coins
      level
      range
      registeredAt
      avatar {
        id
        name
        image
      }
    }
  }
`
