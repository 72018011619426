import { gql } from '@apollo/client'

import { Task } from 'types/models'

export type StudentTasksData = {
  query?: string
}

export type StudentTasksPayload = ({
  coins: number
} & Task)[]

export default gql`
  query getStudentTasks($data: QueryData) {
    tasks: getStudentTasks(data: $data) {
      id
      name
      image
      points
      coins
      code
      createdAt
    }
  }
`
