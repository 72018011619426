import styled from 'styled-components'

export const List = styled.section`
  position: relative;
  padding-left: 26px;

  @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
    padding-left: 36px;
  }

  list-style: none;

  & > * + * {
    margin-top: ${({ theme }) => theme.sizes.xs};

    @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
      margin-top: ${({ theme }) => theme.sizes.sm};
    }
  }

  & > * {
    width: 100%;
  }

  &::before {
    content: '';
    width: 2px;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 5.5px;

    @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
      left: 7px;
    }

    background-color: ${({ theme }) => theme.colors.primary300};
  }

  &::after {
    content: '';
    width: 13px;
    height: 2px;

    @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
      width: 16px;
    }

    position: absolute;
    left: 0;
    bottom: 0;

    background-color: ${({ theme }) => theme.colors.primary500};
  }
`

export const Item = styled.article`
  text-align: left;
`

export const Separator = styled.div`
  color: ${({ theme }) => theme.colors.light};
  ${({ theme }) => theme.textVariants.paragraphMB};

  @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
    ${({ theme }) => theme.textVariants.paragraphLB};
  }

  &::before {
    content: '';
    width: 13px;
    height: 13px;

    @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
      width: 16px;
      height: 16px;
    }

    position: absolute;
    left: 0;

    background-color: ${({ theme }) => theme.colors.primary500};
    border-radius: ${({ theme }) => theme.borderRadii.rounded};
  }
`
