import styled from 'styled-components'

import { NavLink } from 'react-router-dom'

interface IWrapper {
  icon: string
}

export const Wrapper = styled(NavLink)<IWrapper>`
  position: relative;
  display: inline;

  padding-block: ${({ theme }) => theme.sizes.ss};
  padding-inline: ${({ theme }) => theme.sizes.sm};
  padding-bottom: calc(${({ theme }) => theme.sizes.xs} - 4.5px);
  padding-left: calc(${({ theme }) => theme.sizes.sm} + 29px);

  border-radius: ${({ theme }) => theme.borderRadii.rounded};

  line-height: 22px;

  & > span {
    position: relative;
    z-index: ${({ theme }) => theme.zIndices.xl};
    text-align: center;
    transition: color 200ms;
  }

  transition: all 200ms;
  user-select: none;

  &::before {
    content: '';
    background-image: ${({ icon }) => `url(/assets/icons/${icon}.svg)`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    position: absolute;
    left: ${({ theme }) => theme.sizes.sm};
    z-index: ${({ theme }) => theme.zIndices.xxl};

    width: 19px;
    height: 19px;
  }
`
