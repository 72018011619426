import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'

import { Accordion } from 'components/items'

import { Box, Button, Icon, Progress, Text } from 'ui'
import { Modal } from 'components'
import { AddTaskModal, UseBenefitModal } from 'components/modals'

import { ClassStudentPayload, ClassStudentsPayload } from 'apollo/queries'

type Props = {
  classId: ID
  extra?: ClassStudentPayload
  data: ClassStudentsPayload[number]
}

const Student = ({ classId, extra, data }: Props) => {
  const navigate = useNavigate()

  const toProfile = useCallback(() => navigate(data.id), [navigate, data])

  return (
    <Accordion.Item
      value={data.id}
      title={`${data.name} ${data.surname}`}
      avatar={data.avatar}
      footer={
        <>
          <Modal>
            <Modal.Trigger>
              <Button as="div">Priradiť úlohu</Button>
            </Modal.Trigger>

            <Modal.Content extended overflow>
              <AddTaskModal studentId={data.id} />
            </Modal.Content>
          </Modal>

          <Modal>
            <Modal.Trigger disabled={!data.totalBenefits}>
              <Button as="div" disabled={!data.totalBenefits}>
                Uplatniť benefit
              </Button>
            </Modal.Trigger>

            <Modal.Content extended overflow>
              <UseBenefitModal studentId={data.id} {...{ classId }} />
            </Modal.Content>
          </Modal>
        </>
      }
    >
      <Box gap="xxs" largePhone={{ gap: 'ss' }} w="100%" maxw={310} order={1}>
        <Text
          variant="heading4A"
          color="grey800"
          largePhone={{ fontSize: 24, lineHeight: 29 }}
          tablet={{ fontSize: 28, lineHeight: 34 }}
        >
          {extra?.level || 0} Level
        </Text>

        <Progress value={extra?.points || 0} total={extra?.range || 0} />

        <Text
          variant="heading6A"
          color="grey800"
          tablet={{ fontSize: 18, lineHeight: 22 }}
        >
          {extra?.points || 0} / {extra?.range || 0} bodov
        </Text>
      </Box>

      <Box gap="ss" order={4} largePhone={{ order: 2, gap: 'sm' }}>
        <Box flexDirection="row" gap="3xs">
          <Text
            as="span"
            variant="heading6A"
            color="grey800"
            largePhone={{ fontSize: 18, lineHeight: 22 }}
            tablet={{ fontSize: 20, lineHeight: 24 }}
          >
            Benefity k dispozícií:
          </Text>

          <Text
            as="span"
            variant="heading6A"
            color="primary500"
            largePhone={{ fontSize: 18, lineHeight: 22 }}
            tablet={{ fontSize: 20, lineHeight: 24 }}
          >
            {data?.totalBenefits || 0}
          </Text>
        </Box>

        <Button variant="secondary" onClick={toProfile}>
          Zobraziť profil
        </Button>
      </Box>

      <Box flexDirection="row" gap="3xs" order={3}>
        <Text
          as="span"
          variant="heading6A"
          color="grey800"
          largePhone={{ fontSize: 18, lineHeight: 22 }}
          tablet={{ fontSize: 20, lineHeight: 24 }}
        >
          Počet splnených úloh:
        </Text>

        <Text
          as="span"
          variant="heading6A"
          color="primary500"
          largePhone={{ fontSize: 18, lineHeight: 22 }}
          tablet={{ fontSize: 20, lineHeight: 24 }}
        >
          {data?.totalTasks || 0}
        </Text>
      </Box>

      <Box
        gap="3xs"
        flexDirection="row"
        alignItems="center"
        alignSelf="flex-start"
        display="inline-flex"
        order={2}
        largePhone={{ order: 4 }}
      >
        <Text
          variant="heading6A"
          color="primary500"
          largePhone={{ fontSize: 18, lineHeight: 22 }}
          tablet={{ fontSize: 20, lineHeight: 24 }}
        >
          {extra?.coins || 0}
        </Text>

        <Icon name="coin" width={16} height={16} title="coins" />
      </Box>
    </Accordion.Item>
  )
}

export default React.memo(Student)
