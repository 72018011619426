import { css } from 'styled-components'

export const shadow = css`
  box-shadow: ${({ theme }) => theme.shadows.card};
  cursor: pointer;

  img {
    flex-shrink: 0;
  }
`

export const overflow = css`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`
