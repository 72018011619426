import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import { Box, Button, Search } from 'ui'
import { Modal } from 'components'

import { Content } from 'components/layout'
import { AssignSubjectModal } from 'components/modals'
import { Subject } from 'pages/sections'

import {
  GET_TEACHER_SUBJECTS,
  MySubjectsPayload,
  TeacherSubjectsData,
  TeacherSubjectsPayload,
} from 'apollo/queries'

type Params = {
  id: ID
}

type TPayload = Wrap<TeacherSubjectsPayload, 'subjects'>
type TData = Wrap<TeacherSubjectsData, 'data'>

const Subjects = () => {
  const navigate = useNavigate()
  const { id } = useParams<Params>()

  const { data, refetch, loading } = useQuery<TPayload, TData>(
    GET_TEACHER_SUBJECTS,
    {
      variables: { data: { id: id || '' } },
      onError: () => navigate('/teachers'),
    }
  )

  const handleSearch = useCallback(
    (query: string) => refetch({ data: { id: id || '', query } }),
    [refetch, id]
  )

  const renderItems = useCallback(
    (item: MySubjectsPayload[number]) => (
      <Subject key={`subject-${item.id}`} data={item} teacherId={id} />
    ),
    [id]
  )

  return (
    <>
      <Box
        justifyContent="space-between"
        flexDirection="column-reverse"
        alignItems="flex-end"
        flex={1}
        mb="xs"
        gap="xs"
        largePhone={{ mb: 'm' }}
        tablet={{
          flexDirection: 'row',
          gap: 'm',
          alignItems: 'center',
        }}
      >
        <Search placeholder="Zadajte názov predmetu" callback={handleSearch} />

        <Modal>
          <Modal.Trigger disabled={!id}>
            <Button as="div" disabled={!id}>
              Priradiť predmet
            </Button>
          </Modal.Trigger>

          <Modal.Content extended>
            {id && <AssignSubjectModal teacherId={id} />}
          </Modal.Content>
        </Modal>
      </Box>

      {data?.subjects?.length ? (
        <Box gap="m">{data?.subjects?.map(renderItems)}</Box>
      ) : loading ? (
        <Content.Loading />
      ) : (
        <Content.Empty text="Nenašli sa žiadne predmety učiteľa" />
      )}
    </>
  )
}

export default React.memo(Subjects)
