import React, { useCallback, useMemo } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { useMutation, useQuery } from '@apollo/client'
import { motion } from 'framer-motion'

import { Box, Button, Error, PlaceImage, Select, Text } from 'ui'
import { useErrorHandler } from 'hooks'
import useToastStore from 'stores/toast'

import Modal, { useModal } from 'components/Modal/Modal'

import {
  GET_MY_BENEFITS,
  GET_MY_SUBJECTS,
  GET_MY_USED,
  GET_STUDENT_BENEFITS,
  MyBenefitsPayload,
  MySubjectsData,
  MySubjectsPayload,
  MyUsedPayload,
} from 'apollo/queries'
import { UseBenefitPayload, USE_BENEFIT } from 'apollo/mutations'
import validationSchema, { UseBenefitData } from 'schemas/useBenefit'
import { getImage } from 'utils/files'

type Props = {
  classId: ID
  studentId: ID
  data: MyBenefitsPayload[number]
}

type TQPayload = Wrap<MySubjectsPayload, 'mySubjects'>
type TQData = Wrap<MySubjectsData, 'data'>

type TMPayload = Wrap<UseBenefitPayload, 'usedBenefit'>
type TMData = Wrap<UseBenefitData, 'data'>

const Assignment = ({ data, studentId, classId }: Props) => {
  const { hide } = useModal()
  const { handle, error } = useErrorHandler()
  const { show } = useToastStore()

  const { data: subjects } = useQuery<TQPayload, TQData>(GET_MY_SUBJECTS, {
    variables: { data: {} },
  })

  const [addBenefit, { loading }] = useMutation<TMPayload, TMData>(
    USE_BENEFIT,
    {
      onCompleted: ({ usedBenefit }) =>
        show({
          title: 'Benefit bol použitý',
          message: usedBenefit?.benefit?.benefit?.name,
          type: 'success',
        }),
    }
  )

  const initialValues = useMemo(
    (): UseBenefitData => ({
      studentId,
      classId,
      benefitId: data.id,
      subject: subjects?.mySubjects?.[0]?.id || '',
    }),
    [data, subjects, studentId, classId]
  )

  const onSubmit = useCallback(
    (values: UseBenefitData, formikHelpers: FormikHelpers<UseBenefitData>) => {
      addBenefit({
        variables: { data: values },
        refetchQueries: [
          {
            query: GET_STUDENT_BENEFITS,
            variables: { data: { id: studentId } },
          },
          {
            query: GET_MY_BENEFITS,
            variables: { data: { id: studentId } },
          },
        ],
        update: (cache, { data: response }) => {
          cache.updateQuery(
            {
              query: GET_MY_USED,
              variables: { data: { id: studentId } },
            },
            (data?: Wrap<MyUsedPayload, 'myUsed'>) => {
              if (data?.myUsed) {
                return { myUsed: [response!.usedBenefit, ...data.myUsed] }
              }
            }
          )
        },
      })
        .then(hide)
        .catch(handle(formikHelpers))
    },
    [addBenefit, hide, handle, studentId]
  )

  return (
    <Modal.Wrapper indented onClick={(e) => e.preventDefault()}>
      <Modal.Title>Uplatnenie benefitu</Modal.Title>

      <Box flexDirection="row" alignItems="center" gap="sm">
        <motion.div layoutId="useMyBenefitImage" transition={{ duration: 0.3 }}>
          <PlaceImage
            src={getImage(data?.image, 'thumbnail')}
            placeholder={getImage(data?.image, 'placeholder')}
            fallback="/assets/images/duck@fallback.png"
            alt={data.name}
            defW={84}
            defH={100}
            maxw={84}
            ofit="cover"
            opos="top"
            ratio="84 / 100"
          />
        </motion.div>

        <Box
          flex={1}
          gap="sm"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text as="h3" variant="heading3A" color="text">
            {data.name}
          </Text>
        </Box>
      </Box>

      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        validateOnChange
        enableReinitialize
      >
        {({ errors, touched }) => (
          <Form>
            <Select<MySubjectsPayload[number]>
              name="subject"
              label="Predmet"
              hasError={touched.subject && errors.subject}
              disabled={loading}
              defaultValue={subjects?.mySubjects?.[0]?.id}
              items={subjects?.mySubjects}
              required
            />

            <Error message={error} />

            <Modal.Actions inForm>
              <Button type="submit" isLoading={loading} disabled={loading}>
                Uplatniť benefit
              </Button>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal.Wrapper>
  )
}

export default React.memo(Assignment)
