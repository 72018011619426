import styled from 'styled-components'

import * as Slider from '@radix-ui/react-slider'

interface IRoot {
  disabled?: boolean
}

interface IThumb {
  size: number
}

export const Root = styled(Slider.Root)<IRoot>`
  position: relative;

  display: flex;
  align-items: center;

  user-select: none;
  touch-action: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  width: 100%;
  height: 24px;
`

export const Track = styled(Slider.Track)`
  position: relative;
  flex-grow: 1;
  height: 24px;

  background: ${({ theme }) => theme.colors.slider};
  border-radius: ${({ theme }) => theme.borderRadii.rounded};
`

export const Thumb = styled(Slider.Thumb)<IThumb>`
  display: flex;

  width: ${({ size }) => Math.ceil(size * 0.33)}px;
  height: 30px;

  background-color: ${({ theme }) => theme.colors.text};
  box-shadow: ${({ theme }) => theme.shadows.thumb};
  border-radius: ${({ theme }) => theme.borderRadii.rounded};
`
