import React from 'react'

import { Box, Image, Text } from 'ui'

import * as S from './styled'

type Props = {
  Action?: React.ReactNode
  children: React.ReactNode
}

const Message = ({ Action, children }: Props) => (
  <Box
    as="aside"
    wrap="wrap"
    radius="card"
    flexDirection="row"
    alignItems="center"
    bgColor="tertiary500"
    paddingVertical="s"
    paddingHorizontal="xs"
    justifyContent="space-between"
    gap={Action ? 'ss' : undefined}
    largePhone={{ pv: 'm', ph: 's' }}
    tablet={{
      pv: 'm',
      ph: 'sm',
      wrap: 'inherit',
      gap: 'xxl',
      mb: 'sm',
      minh: 115,
    }}
  >
    <Text
      color="text"
      variant="heading6A"
      textAlign="center"
      tablet={{ textAlign: 'left', fontSize: 18, lineHeight: 22 }}
      desktop={{ textAlign: 'left', fontSize: 20, lineHeight: 24 }}
    >
      {children}
    </Text>

    <Box
      gap="xxl"
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="center"
      tablet={{ justifyContent: 'center' }}
    >
      {Action}

      <Image
        src="/images/duck-head.svg"
        alt="duck"
        draggable="false"
        width={134}
        height={122}
        css={S.image}
      />
    </Box>
  </Box>
)

export default React.memo(Message)
