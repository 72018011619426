import styled, { css } from 'styled-components'

interface IWrapper {
  fullWidth?: boolean
}

const autofillFix = css`
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.grey800};
    transition: background-color 5000s ease-in-out 0s;
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;

  transition: all 200ms;
  padding-inline: ${({ theme }) => theme.sizes.s};

  position: absolute;
  inset: 0;
  left: auto;

  path {
    fill: ${({ theme }) => theme.colors.grey500};
  }
`

export const wrapper = css<IWrapper>`
  transition: all 200ms;
  width: 100%;
  overflow: hidden;

  min-width: 180px;
  max-width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: ${({ fullWidth }) => !fullWidth && '380px'};
  }
`

export const Field = styled.input`
  ${({ theme }) => theme.textVariants.paragraphL};
  color: ${({ theme }) => theme.colors.grey800};

  background-color: ${({ theme }) => theme.colors.light};
  border: 2px solid ${({ theme }) => theme.colors.grey300};
  border-radius: ${({ theme }) => theme.borderRadii.input};

  padding-block: calc(${({ theme }) => theme.sizes.xs} - 2px);
  padding-inline: calc(${({ theme }) => theme.sizes.m} - 4px);
  padding-right: calc(${({ theme }) => theme.sizes.m} + 20px);

  transition: all 200ms;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey500};
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey500};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.grey600};
  }

  ${autofillFix}
`
