import React, { ComponentProps } from 'react'

import { Text } from 'ui/core'

type Props = {
  message?: string
} & ComponentProps<typeof Text>

const Error = ({ message, ...props }: Props) =>
  message ? (
    <Text as="small" variant="paragraphS" color="primary500" {...props}>
      {message}
    </Text>
  ) : null

export default React.memo(Error)
