import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { LayoutGroup, motion } from 'framer-motion'
import { Helmet } from 'react-helmet'

import { Box, Image, PlaceImage, Text } from 'ui'

import * as S from './styled'

type ContentProps = {
  withTabs?: boolean
  children?: React.ReactNode
}

const Content = ({ withTabs, children }: ContentProps) => (
  <Box
    as="article"
    bgColor="text"
    radius="card"
    overflow="hidden"
    padding="xs"
    largePhone={{ padding: 'sm' }}
    shadow="card"
  >
    {children}

    {withTabs && <Outlet />}
  </Box>
)

type HeaderProps = {
  wrap?: boolean
  children: React.ReactNode
}

const Header = ({ wrap, children }: HeaderProps) => (
  <Box
    as="nav"
    flexDirection="row"
    alignItems="center"
    marginBottom="xs"
    largePhone={{ mb: 'sm' }}
    css={S.scroll}
  >
    <Box
      flex={1}
      paddingBottom="xs"
      flexDirection="row"
      gap={wrap ? 'ss' : undefined}
      wrap={wrap ? 'wrap-reverse' : 'inherit'}
      justifyContent={wrap ? 'flex-end' : undefined}
      largePhone={{ gap: wrap ? 'sm' : undefined, pb: 'sm' }}
      tablet={{ wrap: 'nowrap' }}
      css={S.border}
    >
      <LayoutGroup id="contentTabs">{children}</LayoutGroup>
    </Box>
  </Box>
)

type TabProps = {
  path: string
  children: React.ReactNode
}

const Tab = ({ path, children }: TabProps) => {
  const { pathname } = useLocation()

  return (
    <S.Tab to={path} end>
      <Text as="span" variant="paragraphLB" color="light">
        {children}
      </Text>

      {path === pathname && (
        <motion.div
          layoutId="highlight"
          transition={{ duration: 0.2, ease: 'linear' }}
          style={{
            background: '#F15A29',
            position: 'absolute',
            inset: 0,
            borderRadius: 999,
            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.15)',
          }}
        />
      )}
    </S.Tab>
  )
}

type EmptyProps = {
  text?: string
  children?: React.ReactNode
}

const Empty = ({ text, children }: EmptyProps) => (
  <Box
    alignItems="center"
    justifyContent="center"
    width="100%"
    height="100%"
    pt="xxl"
    pb="3xl"
    tablet={{ pt: '4xl', pb: '5xl' }}
    gap="sm"
  >
    <Image
      src="images/duck@fallback.png"
      width={208}
      height={208}
      style={{
        opacity: 0.5,
        width: '100%',
        height: 'auto',
        maxWidth: 360,
        paddingInline: 80,
      }}
    />

    {text && (
      <Text variant="paragraphLB" color="light" textAlign="center">
        {text}
      </Text>
    )}

    {children}
  </Box>
)

type LoadingProps = {
  children?: React.ReactNode
}

const Loading = ({ children }: LoadingProps) => (
  <>
    <Helmet>
      <link rel="preload" href="/assets/gifs/duck-loading.gif" as="image" />
    </Helmet>

    <Box
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      padding="5xl"
      pt="xxl"
    >
      <PlaceImage
        src="/assets/gifs/duck-loading.gif"
        color="text"
        defW={250}
        defH={250}
        ofit="cover"
        opos="center"
      />

      <Text variant="paragraphLB" color="light">
        Načítavanie...
      </Text>

      {children}
    </Box>
  </>
)

Content.Header = Header
Content.Tab = Tab
Content.Empty = Empty
Content.Loading = Loading

export default Content
