import React, { ImgHTMLAttributes } from 'react'

import { Box } from 'ui/core'
import { TBoxProps } from 'ui/core/Box/Box'

type Props = {
  name: string
  defW?: number | string
  defH?: number | string
} & TBoxProps &
  ImgHTMLAttributes<HTMLImageElement>

const ImageIcon = ({ name, defW, defH, ...props }: Props) => (
  <Box<'img'>
    as="img"
    display="block"
    src={`/assets/icons/${name}.svg`}
    width={defW}
    height={defH}
    {...props}
  />
)

export default React.memo(ImageIcon)
