import React, {
  ComponentProps,
  ElementType,
  ForwardedRef,
  HTMLAttributes,
  Ref,
} from 'react'
import { FlattenInterpolation, ThemeProps } from 'styled-components'

import type { TTheme, TThemeBreakpoint } from 'styles/theme'

import type {
  TDisplayProps,
  TPositionProps,
  TSizeProps,
  TSpacingProps,
  TImageProps,
  TShadowProps,
} from '../types'
import {
  TBoxBackgroundProps,
  TBoxBorderProps,
  TBoxOverflowProps,
} from './props'

import * as S from './styled'

type StyleProps = TSpacingProps &
  TPositionProps &
  TDisplayProps &
  TSizeProps &
  TBoxBackgroundProps &
  TBoxBorderProps &
  TBoxOverflowProps &
  TImageProps &
  TShadowProps

type ResponsiveProps = PartialRecord<TThemeBreakpoint, StyleProps>

export type Props = {
  as?: ElementType
  css?: FlattenInterpolation<ThemeProps<TTheme>>
  children?: React.ReactNode
} & StyleProps &
  ResponsiveProps

export type TBoxProps = HTMLAttributes<HTMLDivElement> & Props

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/ban-types
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

const Box = <T extends ElementType = 'div'>(
  { children, ...props }: TBoxProps & ComponentProps<T>,
  forwardedRef?: ForwardedRef<any>
) => (
  <S.Wrapper ref={forwardedRef} {...props}>
    {children}
  </S.Wrapper>
)

export default React.forwardRef(Box)
