import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import { Action, Head } from 'ui'
import { Content } from 'components/layout'
import { ClassHeader } from 'components/headers'
import { Users } from './sections'
import { ForRoles } from 'ui/auth'

import { ClassData, ClassPayload, GET_CLASS } from 'apollo/queries'

type Params = {
  id: ID
}

type TPayload = Wrap<ClassPayload, 'class'>
type TData = Wrap<ClassData, 'data'>

const Class = () => {
  const navigate = useNavigate()
  const { id } = useParams<Params>()

  const { data, loading } = useQuery<TPayload, TData>(GET_CLASS, {
    variables: { data: { id: id! } },
    onError: () => navigate('/', { replace: true }),
  })

  const goBack = useCallback(() => navigate('/'), [navigate])

  return (
    <>
      <Head title="Trieda" />

      <Action
        onClick={goBack}
        IconProps={{ name: 'arrow', title: 'Späť' }}
        title="Späť"
      />

      <ClassHeader data={data?.class} {...{ loading }} />

      <ForRoles roles={['TEACHER']}>
        <Content withTabs>
          <Content.Header>
            <Content.Tab path={`/${id}`}>Študenti</Content.Tab>
            <Content.Tab path={`/${id}/benefits`}>Benefity triedy</Content.Tab>
          </Content.Header>
        </Content>
      </ForRoles>

      <ForRoles roles={['MANAGER']}>{!!id && <Users classId={id} />}</ForRoles>
    </>
  )
}

export default React.memo(Class)
