import { gql } from '@apollo/client'

import { User } from 'types/models'

export type ClassStudentsData = {
  id: ID
  query?: string
}

export type ClassStudentsPayload = ({
  totalTasks: number
  totalBenefits: number
} & User)[]

export default gql`
  query getClassStudents($data: IdqueryData!) {
    students: getClassStudents(data: $data) {
      id
      name
      surname
      avatar {
        id
        name
        image
      }
      totalTasks
      totalBenefits
    }
  }
`
