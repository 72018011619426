import React, { useCallback, useMemo } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'

import { Button, Error, File, Input } from 'ui'
import { Class } from 'types/models'
import { useErrorHandler } from 'hooks'
import useToastStore from 'stores/toast'

import Modal, { useModal } from 'components/Modal/Modal'

import { GET_MY_CLASSES, MyClassesPayload } from 'apollo/queries'
import { UpsertClassPayload, UPSERT_CLASS } from 'apollo/mutations'
import validationSchema, { UpsertClassData } from 'schemas/upsertClass'

type Props = {
  data?: Class
}

type TPayload = Wrap<UpsertClassPayload, 'class'>
type TData = Wrap<Omit<UpsertClassData, 'image'> & { type?: string }, 'data'>

const UpsertClass = ({ data }: Props) => {
  const { hide } = useModal()
  const { handle, error } = useErrorHandler()
  const { show } = useToastStore()

  const [upsertClass, { loading }] = useMutation<TPayload, TData>(
    UPSERT_CLASS,
    {
      onCompleted: (response) =>
        show({
          title: 'Trieda bola úspešne upravená',
          message: response?.class?.name,
          type: 'success',
        }),
    }
  )

  const initialValues = useMemo(
    (): UpsertClassData => ({
      id: data?.id,
      name: data?.name || '',
      description: data?.description || '',
      logo: data?.logo || undefined,
      image: undefined,
    }),
    [data]
  )

  const onSubmit = useCallback(
    (
      { image, ...values }: UpsertClassData,
      formikHelpers: FormikHelpers<UpsertClassData>
    ) => {
      upsertClass({
        variables: { data: { type: image?.type, ...values } },
        update: (cache, { data: response }) => {
          if (!data?.id) {
            // only on create
            cache.updateQuery(
              {
                query: GET_MY_CLASSES,
                variables: { data: {} },
              },
              (data?: Wrap<MyClassesPayload, 'myClasses'>) => {
                if (data?.myClasses) {
                  return { myClasses: [response!.class, ...data.myClasses] }
                }
              }
            )
          }
        },
      })
        .then(async ({ data }) => {
          if (image && data?.class?.upload) {
            await fetch(data?.class?.upload, {
              method: 'PUT',
              body: image,
            })
          }

          hide()
        })
        .catch(handle(formikHelpers))
    },
    [upsertClass, hide, data, handle]
  )

  return (
    <Modal.Wrapper extended indented>
      <Modal.Title>{data?.id ? 'Upraviť' : 'Vytvoriť'} triedu</Modal.Title>

      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        validateOnChange
        enableReinitialize
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <Input
              type="text"
              name="name"
              label="Názov"
              hasError={touched.name && errors.name}
              disabled={loading}
            />

            <Input
              as="textarea"
              type="text"
              name="description"
              label="Popis"
              hasError={touched.description && errors.description}
              disabled={loading}
            />

            <File
              name="image"
              label="Logo"
              hasError={touched.image && errors.image}
              disabled={loading}
              defaultValue={values?.logo}
              onDelete={() => setFieldValue('logo', undefined)}
            />

            <Error message={error} />

            <Modal.Actions inForm>
              <Button type="submit" isLoading={loading} disabled={loading}>
                {data?.id ? 'Upraviť' : 'Vytvoriť'}
              </Button>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal.Wrapper>
  )
}

export default React.memo(UpsertClass)
