import React, { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { Alert } from 'components/modals'
import { Class } from 'types/models'
import { useErrorHandler } from 'hooks'
import useToastStore from 'stores/toast'

import {
  DeleteClassData,
  DeleteClassPayload,
  DELETE_CLASS,
} from 'apollo/mutations'

type Props = {
  data: Class
}

type TPayload = Wrap<DeleteClassPayload, 'class'>
type TData = Wrap<DeleteClassData, 'data'>

const DeleteModal = ({ data }: Props) => {
  const { handle } = useErrorHandler()
  const { show } = useToastStore()

  const [deleteClass, { loading }] = useMutation<TPayload, TData>(
    DELETE_CLASS,
    {
      onCompleted: () => show({ title: 'Trieda bola odstránená' }),
    }
  )

  const handleDelete = useCallback(
    (hide: () => void) => {
      deleteClass({
        variables: { data: { id: data.id } },
        update: (cache, { data: response }) => {
          const id = cache.identify({
            id: response?.class.id,
            __typename: 'Class',
          })
          cache.evict({ id })
        },
      })
        .then(hide)
        .catch(handle())
    },
    [deleteClass, data, handle]
  )

  return (
    <Alert
      title="Prajete si vymazať triedu ?"
      button="Áno, vymazať"
      loading={loading}
      onAction={handleDelete}
    >
      Trieda &quot;{data?.name}&quot; bude trvalo odstránená. <br />
      Táto akcia je nezvratná.
    </Alert>
  )
}

export default React.memo(DeleteModal)
