import { useState, useCallback } from 'react'
import { ApolloError } from '@apollo/client'
import { FormikHelpers } from 'formik'

import useToastStore from 'stores/toast'

import { GLOBAL_ERRORS, MUTATION_ERRORS } from 'constants/errors'

type UseErrorHandler<Values> = {
  error: string | null
  handle: (
    formikHelpers?: FormikHelpers<Values>
  ) => (error: ApolloError) => void
}

const DEBUG = process.env.NODE_ENV === 'development'

export default <Values>(): UseErrorHandler<Values> => {
  const [error, setError] = useState<string | null>(null)

  const { show } = useToastStore()

  const handle = useCallback(
    (formikHelpers?: FormikHelpers<Values>) => (error: ApolloError) => {
      setError(null)

      if (DEBUG) console.error(error)

      if (error?.networkError) {
        return show({ title: GLOBAL_ERRORS['DEFAULT'].message, type: 'danger' })
      }

      // TODO: validation errors

      const firstError = error?.graphQLErrors?.[0]
      const errorKey = firstError?.path?.[0] || ''
      const errorCode = (firstError?.extensions?.code as string) || ''

      if (DEBUG) console.log(errorKey, errorCode)

      const errorGroup = MUTATION_ERRORS?.[errorKey] || GLOBAL_ERRORS
      const pickedError = errorGroup?.[errorCode] || GLOBAL_ERRORS?.[errorCode]
      const finalError = pickedError || GLOBAL_ERRORS['DEFAULT']

      // Field Error
      if (finalError?.field && !!formikHelpers) {
        return formikHelpers.setFieldError(finalError.field, finalError.message)
      }

      // Global Error
      if (!finalError?.floating || (finalError?.field && !formikHelpers)) {
        return setError(finalError.message)
      }

      // Floating Error
      if (finalError?.floating) {
        return show({ title: finalError?.message, type: 'danger' })
      }

      console.error(DEBUG ? error : errorCode)
    },
    [show]
  )

  return { error, handle }
}
