import React, { useCallback } from 'react'
import { createPortal } from 'react-dom'
import { AnimatePresence, motion } from 'framer-motion'

import { Box } from 'ui'
import { Item } from './sections'

import useToastStore from 'stores/toast'

const element = document.getElementById('root') || document.body

const Toast = () => {
  const { toasts } = useToastStore()

  const renderItems = useCallback(
    (toast: typeof toasts[number]) => (
      <motion.div
        layout
        key={`toast-${toast.id}`}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, x: '100%' }}
      >
        <Item data={toast} />
      </motion.div>
    ),
    []
  )

  return createPortal(
    <Box
      id="toasts"
      position="fixed"
      zIndex="toast"
      padding="sm"
      bottom="0"
      right="0"
      gap="sm"
    >
      <AnimatePresence>{toasts?.map(renderItems)}</AnimatePresence>
    </Box>,
    element
  )
}

export default React.memo(Toast)
