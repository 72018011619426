import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import { Action, Box, Button, Head } from 'ui'
import { Message, Modal } from 'components'

import { Content } from 'components/layout'
import { StudentHeader } from 'components/headers'
import { DeleteModal } from './modals'

import { ForRoles } from 'ui/auth'
import useMessageStore from 'stores/message'

import { GET_STUDENT, StudentData, StudentPayload } from 'apollo/queries'

type Params = {
  id?: ID
  studentId?: ID
}

type TPayload = Wrap<StudentPayload, 'student'>
type TData = Wrap<StudentData, 'data'>

const Flight = () => {
  const navigate = useNavigate()

  const { id, studentId } = useParams<Params>()
  const { message } = useMessageStore()

  const { data } = useQuery<TPayload, TData>(GET_STUDENT, {
    variables: { data: { id: studentId || '' } },
    onError: () => {
      if (studentId) navigate(`/${id || ''}`, { replace: true })
    },
  })

  const goBack = useCallback(() => navigate(`/${id || ''}`), [navigate, id])

  return (
    <>
      <Head title="Môj let" />

      {studentId && (
        <Box flexDirection="row" flex={1} justifyContent="space-between">
          <Action
            IconProps={{ name: 'arrow', title: 'Späť' }}
            onClick={goBack}
            title="Späť"
          />

          <ForRoles roles={['MANAGER']}>
            <Modal>
              <Modal.Trigger disabled={!id || !data}>
                <Button as="div" disabled={!id || !data}>
                  Vymazať študenta
                </Button>
              </Modal.Trigger>

              <Modal.Content extended hideClose>
                {id && data && <DeleteModal classId={id} data={data.student} />}
              </Modal.Content>
            </Modal>
          </ForRoles>
        </Box>
      )}

      <ForRoles roles={['STUDENT']}>
        {message && (
          <Message
            Action={
              message.onClick && (
                <Button onClick={message.onClick}>{message.button}</Button>
              )
            }
          >
            {message.text}
          </Message>
        )}
      </ForRoles>

      <StudentHeader data={data?.student} />

      <Content withTabs>
        <Content.Header>
          <ForRoles roles={['STUDENT']}>
            <Content.Tab path="/">História bodov</Content.Tab>

            <Content.Tab path="/actual-benefits">Aktuálne benefity</Content.Tab>

            <Content.Tab path="/benefits-history">
              História benefitov
            </Content.Tab>
          </ForRoles>

          <ForRoles roles={['MANAGER', 'TEACHER']}>
            <Content.Tab path={`/${id}/${studentId}`}>
              História bodov
            </Content.Tab>

            <Content.Tab path={`/${id}/${studentId}/actual-benefits`}>
              Aktuálne benefity
            </Content.Tab>

            <Content.Tab path={`/${id}/${studentId}/benefits-history`}>
              História benefitov
            </Content.Tab>
          </ForRoles>
        </Content.Header>
      </Content>
    </>
  )
}

export default React.memo(Flight)
