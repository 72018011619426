import React, { useMemo } from 'react'

import { Role } from 'types/models'
import { useAuthStore } from 'stores/auth'

type UserRole = keyof typeof Role

export type Props = {
  children: React.ReactNode
  roles?: UserRole | UserRole[]
}

const ForRoles = ({ children, roles = [] }: Props) => {
  const user = useAuthStore<'user'>((state) => state.user)

  const isAllowed = useMemo(
    () =>
      !roles.length ||
      (typeof roles === 'object'
        ? roles?.includes(user?.role as Role)
        : roles === user?.role),
    [user, roles]
  )

  return isAllowed ? <>{children}</> : null
}

export default ForRoles
