import React from 'react'
import { RouteObject } from 'react-router'

import { WithAuth, WithoutAuth } from 'ui/auth'
import { Role } from 'types/models'

// Layouts
import { Auth, Main } from 'layouts'

// Pages
import { Benefits, Ducks, Tasks, Flight, Dashboard } from 'pages'
import { Teachers, Teacher, Subjects, Classes, Class } from 'pages'
import { ChangePassword, ResetPassword, SignIn, SignUp } from 'pages/auth'

// Tabs
import {
  ActualBenefits,
  BenefitsHistory,
  PointsHistory,
} from 'pages/Flight/tabs'
import { ClassBenefits, ClassStudents } from 'pages/Class/tabs'
import { TeacherClasses, TeacherSubjects } from 'pages/Teacher/tabs'

export default [
  {
    path: '',
    element: WithAuth(Main),
    children: [
      {
        path: '',
        element: Dashboard({
          [Role.STUDENT]: Flight,
          [Role.TEACHER]: Classes,
          [Role.MANAGER]: Classes,
        }),
        children: [
          {
            index: true,
            element: Dashboard({
              [Role.STUDENT]: PointsHistory,
              [Role.TEACHER]: PointsHistory,
            }),
          },
          {
            path: 'actual-benefits',
            element: Dashboard({
              [Role.STUDENT]: ActualBenefits,
            }),
          },
          {
            path: 'benefits-history',
            element: Dashboard({
              [Role.STUDENT]: BenefitsHistory,
            }),
          },
          {
            path: '*',
            element: Dashboard({
              [Role.STUDENT]: PointsHistory,
            }),
          },
        ],
      },

      {
        path: 'tasks',
        element: Dashboard({
          [Role.STUDENT]: Tasks,
          [Role.TEACHER]: Tasks,
        }),
      },
      {
        path: 'benefits',
        element: Dashboard({
          [Role.STUDENT]: Benefits,
          [Role.TEACHER]: Benefits,
        }),
      },
      {
        path: 'ducks',
        element: Dashboard({
          [Role.STUDENT]: Ducks,
          [Role.TEACHER]: Ducks,
        }),
      },

      {
        path: 'teachers',
        element: Dashboard({
          [Role.MANAGER]: Teachers,
        }),
      },
      {
        path: 'teachers/:id',
        element: Dashboard({
          [Role.MANAGER]: Teacher,
        }),
        children: [
          { index: true, element: <TeacherClasses /> },
          { path: 'subjects', element: <TeacherSubjects /> },
          { path: '*', element: <TeacherClasses /> },
        ],
      },
      {
        path: 'subjects',
        element: Dashboard({
          [Role.MANAGER]: Subjects,
        }),
      },

      {
        path: ':id',
        element: Dashboard({
          [Role.TEACHER]: Class,
          [Role.MANAGER]: Class,
        }),
        children: [
          { index: true, element: <ClassStudents /> },
          { path: 'benefits', element: <ClassBenefits /> },
          { path: '*', element: <ClassStudents /> },
        ],
      },

      {
        path: ':id/:studentId',
        element: Dashboard({
          [Role.TEACHER]: Flight,
          [Role.MANAGER]: Flight,
        }),
        children: [
          { index: true, element: <PointsHistory /> },
          { path: 'actual-benefits', element: <ActualBenefits /> },
          { path: 'benefits-history', element: <BenefitsHistory /> },
          { path: '*', element: <PointsHistory /> },
        ],
      },
    ],
  },

  {
    path: '',
    element: WithoutAuth(Auth),
    children: [
      // Auth routes
      { path: 'signin', element: <SignIn /> },
      { path: 'signup/:token', element: <SignUp /> },
      { path: 'forgot-password', element: <ResetPassword /> },
      { path: 'forgot-password/:token', element: <ChangePassword /> },
    ],
  },
] as RouteObject[]
