import { gql } from '@apollo/client'

import { Class } from 'types/models'

export type TeacherClassesData = {
  id: ID
  query?: string
}

export type TeacherClassesPayload = Class[]

export default gql`
  query getTeacherClasses($data: IdqueryData!) {
    classes: getTeacherClasses(data: $data) {
      id
      name
      description
      logo
    }
  }
`
