import { gql } from '@apollo/client'

import { User } from 'types/models'

export type SignupPayload = {
  user: User
  accessToken: string
  refreshToken: ID
}

export default gql`
  mutation signup($data: SignupData!) {
    signup(data: $data) {
      user {
        id
        email
        role
      }
      accessToken
      refreshToken
    }
  }
`
