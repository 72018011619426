import { object, string, ref, InferType } from 'yup'

const schema = object({
  token: string(),
  password: string()
    .min(6, 'Heslo musí mať najmenej 6 znakov')
    .required('Prosím zadajte nové heslo'),
  passwordConfirmation: string()
    .required('Prosím zadajte nové heslo znovu')
    .oneOf([ref('password'), null], 'Heslá sa nezhodujú'),
}).required()

export type ChangePasswordData = InferType<typeof schema>

export default schema
