import { gql } from '@apollo/client'

import { User } from 'types/models'

export type ByTokenData = {
  token: string
}

export type ByTokenPayload = User

export default gql`
  query getByToken($data: TokenData!) {
    user: getByToken(data: $data) {
      id
      email
      name
      surname
      role
    }
  }
`
