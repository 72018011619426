import React, { useCallback, useMemo } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { useMutation, useQuery } from '@apollo/client'

import { Box, Button, Error, Icon, Select, Slider, Text } from 'ui'
import { SelectItem } from 'components/items'
import { useErrorHandler } from 'hooks'
import useToastStore from 'stores/toast'
import { getImage } from 'utils/files'

import Modal, { useModal } from 'components/Modal/Modal'

import {
  GET_MY_SUBJECTS,
  GET_MY_TASKS,
  MySubjectsData,
  MySubjectsPayload,
  MyTasksPayload,
  StudentTasksPayload,
} from 'apollo/queries'
import { UpsertTaskPayload, UPSERT_TASK } from 'apollo/mutations'
import validationSchema, { UpsertTaskData } from 'schemas/upsertTask'

type ActiveTask = StudentTasksPayload[number]

type Props = {
  active: ActiveTask
  studentId: ID
  onAction: () => void
}

type TQueryPayload = Wrap<MySubjectsPayload, 'mySubjects'>
type TQueryData = Wrap<MySubjectsData, 'data'>

type TPayload = Wrap<UpsertTaskPayload, 'studentTask'>
type TData = Wrap<UpsertTaskData, 'data'>

const Assignment = ({ active, studentId, onAction }: Props) => {
  const { hide } = useModal()
  const { handle, error } = useErrorHandler()
  const { show } = useToastStore()

  const { data } = useQuery<TQueryPayload, TQueryData>(GET_MY_SUBJECTS, {
    variables: { data: {} },
  })

  const [addTask, { loading }] = useMutation<TPayload, TData>(UPSERT_TASK, {
    onCompleted: ({ studentTask }) =>
      show({
        title: 'Úloha bola úspešne priradená',
        message: studentTask?.task?.name,
        type: 'success',
      }),
  })

  const initialValues = useMemo(
    (): UpsertTaskData => ({
      id: undefined,
      studentId,
      taskId: active.id,
      points: active.points,
      subject: data?.mySubjects?.[0]?.id || '',
    }),
    [active, data, studentId]
  )

  const onSubmit = useCallback(
    (values: UpsertTaskData, formikHelpers: FormikHelpers<UpsertTaskData>) => {
      addTask({
        variables: { data: values },
        update: (cache, { data: response }) => {
          cache.updateQuery(
            {
              query: GET_MY_TASKS,
              variables: { data: { id: studentId } },
            },
            (data?: Wrap<MyTasksPayload, 'myTasks'>) => {
              if (data?.myTasks) {
                return { myTasks: [response!.studentTask, ...data.myTasks] }
              }
            }
          )
        },
      })
        .then(hide)
        .catch(handle(formikHelpers))
    },
    [addTask, hide, handle, studentId]
  )

  return (
    <Modal.Wrapper indented onClick={(e) => e.preventDefault()}>
      <Modal.Title goBack={onAction}>Priradenie úlohy</Modal.Title>

      <SelectItem
        key={`task-${active.id}`}
        title={active?.name}
        subtitle="Počet bodov"
        value={active.points}
        image={{
          src: getImage(active.image, 'thumbnail'),
          placeholder: getImage(active.image, 'placeholder'),
          fallback: '/assets/images/duck@fallback.png',
        }}
      >
        <SelectItem.Footer>
          <Box flexDirection="row" alignItems="center" gap="3xs">
            <Text variant="heading5B" color="primary500">
              {active.coins}
            </Text>

            <Icon name="coin" width={19} height={19} title="coins" />
          </Box>
        </SelectItem.Footer>
      </SelectItem>

      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        validateOnChange
        enableReinitialize
      >
        {({ errors, touched }) => (
          <Form style={{ gap: 32 }}>
            <Slider
              name="points"
              label="Upravte počet bodov"
              defaultValue={active.points}
              min={active.points - 1}
              max={active.points + 1}
              hasError={touched.points && errors.points}
              disabled={loading}
              required
            />

            <Select<MySubjectsPayload[number]>
              name="subject"
              label="Predmet"
              hasError={touched.subject && errors.subject}
              disabled={loading}
              defaultValue={data?.mySubjects?.[0]?.id}
              items={data?.mySubjects}
              required
            />

            <Error message={error} />

            <Modal.Actions>
              <Button type="submit" isLoading={loading} disabled={loading}>
                Priradiť úlohu
              </Button>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal.Wrapper>
  )
}

export default React.memo(Assignment)
