import React, { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { Alert } from 'components/modals'
import { useErrorHandler } from 'hooks'
import { Class } from 'types/models'
import useToastStore from 'stores/toast'

import {
  RemoveClassData,
  RemoveClassPayload,
  REMOVE_CLASS,
} from 'apollo/mutations'
import {
  GET_NEW_CLASSES,
  GET_TEACHER_CLASSES,
  TeacherClassesPayload,
} from 'apollo/queries'

type Props = {
  teacherId: ID
  data: Class
}

type TPayload = Wrap<RemoveClassPayload, 'class'>
type TData = Wrap<RemoveClassData, 'data'>

const DeleteModal = ({ teacherId, data }: Props) => {
  const { handle } = useErrorHandler()
  const { show } = useToastStore()

  const [removeClass, { loading }] = useMutation<TPayload, TData>(
    REMOVE_CLASS,
    {
      onCompleted: () =>
        show({
          title: 'Trieda bola úspešne odstránená',
          type: 'success',
        }),
    }
  )

  const handleDelete = useCallback(
    (hide: () => void) => {
      removeClass({
        variables: { data: { id: data.id, teacherId } },
        refetchQueries: [
          {
            query: GET_NEW_CLASSES,
            variables: { data: { id: teacherId } },
          },
        ],
        update: (cache, { data: response }) => {
          cache.updateQuery(
            {
              query: GET_TEACHER_CLASSES,
              variables: { data: { id: teacherId } },
            },
            (data?: Wrap<TeacherClassesPayload, 'classes'>) => {
              if (data?.classes) {
                return {
                  classes: data.classes.filter(
                    (item) => item.id !== response?.class.id
                  ),
                }
              }
            }
          )
        },
      })
        .then(hide)
        .catch(handle())
    },
    [removeClass, data, handle, teacherId]
  )

  return (
    <Alert
      title="Prajete si odobrať triedu učiteľovi ?"
      button="Áno, odobrať"
      loading={loading}
      onAction={handleDelete}
    >
      Trieda {data?.name} bude odobratá.
    </Alert>
  )
}

export default React.memo(DeleteModal)
