import React, { useCallback } from 'react'
import { useQuery } from '@apollo/client'

import { Box, Button, Head, Search } from 'ui'
import { Modal } from 'components'

import { AddStudentModal } from 'components/modals'
import { Content } from 'components/layout'
import { User } from 'pages/sections'

import { GET_STUDENTS, StudentsData, StudentsPayload } from 'apollo/queries'

type Props = {
  classId: ID
}

type TPayload = Wrap<StudentsPayload, 'students'>
type TData = Wrap<StudentsData, 'data'>

const Users = ({ classId }: Props) => {
  const { data, refetch, loading } = useQuery<TPayload, TData>(GET_STUDENTS, {
    variables: { data: { id: classId } },
  })

  const handleSearch = useCallback(
    (query: string) => refetch({ data: { id: classId, query } }),
    [refetch, classId]
  )

  const renderItems = useCallback(
    (item: StudentsPayload[number]) => (
      <User key={`student-${item.id}`} data={item} {...{ classId }} />
    ),
    [classId]
  )

  return (
    <>
      <Head title="Študenti" />

      <Content>
        <Content.Header wrap>
          <Box flex={1} minHeight={43}>
            <Search placeholder="Zadajte meno" callback={handleSearch} />
          </Box>

          <Modal>
            <Modal.Trigger>
              <Button as="div">Pridať študenta</Button>
            </Modal.Trigger>

            <Modal.Content extended overflow>
              <AddStudentModal {...{ classId }} />
            </Modal.Content>
          </Modal>
        </Content.Header>

        {data?.students?.length ? (
          <Box gap="m">{data?.students?.map(renderItems)}</Box>
        ) : loading ? (
          <Content.Loading />
        ) : (
          <Content.Empty text="Nenašli sa žiadni študenti" />
        )}
      </Content>
    </>
  )
}

export default React.memo(Users)
