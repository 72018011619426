import React, { useCallback } from 'react'
import { useQuery } from '@apollo/client'

import { Box, Button, Head, Search } from 'ui'
import { Content } from 'components/layout'
import { User } from 'pages/sections'

import { Modal } from 'components'
import { AddTeacherModal } from 'components/modals'

import { GET_TEACHERS, TeachersData, TeachersPayload } from 'apollo/queries'

type TPayload = Wrap<TeachersPayload, 'teachers'>
type TData = Wrap<TeachersData, 'data'>

const Teachers = () => {
  const { data, refetch, loading } = useQuery<TPayload, TData>(GET_TEACHERS, {
    variables: { data: {} },
  })

  const handleSearch = useCallback(
    (query: string) => refetch({ data: { query } }),
    [refetch]
  )

  const renderItems = useCallback(
    (item: TeachersPayload[number]) => (
      <User key={`teacher-${item.id}`} data={item} hideFooter />
    ),
    []
  )

  return (
    <>
      <Head title="Učitelia" />

      <Content>
        <Content.Header wrap>
          <Box flex={1} minHeight={43}>
            <Search
              placeholder="Zadajte meno učiteľa"
              callback={handleSearch}
            />
          </Box>

          {/* CREATE MODAL */}
          <Modal>
            <Modal.Trigger>
              <Button as="div">Pridať učiteľa</Button>
            </Modal.Trigger>

            <Modal.Content extended>
              <AddTeacherModal />
            </Modal.Content>
          </Modal>
          {/* END CREATE MODAL */}
        </Content.Header>

        {data?.teachers?.length ? (
          <Box gap="xs" largePhone={{ gap: 'm' }}>
            {data?.teachers?.map(renderItems)}
          </Box>
        ) : loading ? (
          <Content.Loading />
        ) : (
          <Content.Empty text="Nenašli sa žiadni učitelia" />
        )}
      </Content>
    </>
  )
}

export default React.memo(Teachers)
