import { gql } from '@apollo/client'

import { User } from 'types/models'

export type ByResetTokenData = {
  token: string
}

export type ByResetTokenPayload = User

export default gql`
  query getByResetToken($data: TokenData!) {
    user: getByResetToken(data: $data) {
      id
      name
      surname
      email
    }
  }
`
