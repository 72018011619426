import { gql } from '@apollo/client'

import { Subject } from 'types/models'

export type MySubjectsData = {
  id?: ID
}

export type MySubjectsPayload = Subject[]

export default gql`
  query getMySubjects($data: OptidData) {
    mySubjects: getMySubjects(data: $data) {
      id
      name
      createdAt
    }
  }
`
