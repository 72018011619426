import styled from 'styled-components'

import { GridProps } from './Grid'

export const Wrapper = styled.section<GridProps>`
  display: grid;
  grid-column-gap: ${({ theme }) => theme.sizes['4xl']};
  grid-row-gap: ${({ theme }) => theme.sizes.sm};
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

  padding-bottom: ${({ theme }) => theme.sizes.ss};

  @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
    grid-template-columns: repeat(auto-fill, minmax(246px, 1fr));
    grid-row-gap: ${({ theme }) => theme.sizes.m};
    padding-block: ${({ theme, inline }) => !inline && theme.sizes.xs};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-inline: ${({ theme, inline }) => !inline && theme.sizes.xxl};
    grid-row-gap: ${({ theme }) => theme.sizes.l};
  }

  & > button {
    align-self: flex-start;
  }
`
