import { css } from 'styled-components'

export const wrapper = css`
  padding: ${({ theme }) => theme.sizes.xxs};

  svg {
    width: 100%;
    height: auto;
  }
`
