import jwt_decode from 'jwt-decode'

import { JWTPayload } from 'types/global'

export const getAuthorization = (accessToken?: string | null) => {
  return accessToken ? `Bearer ${accessToken}` : ''
}

export const validateAccessToken = (token: string) => {
  try {
    const payload = jwt_decode<JWTPayload>(token)
    return payload.exp * 1000 > Date.now()
  } catch {
    return false
  }
}

export const getAccessTokenData = (token: string) => {
  try {
    return jwt_decode<JWTPayload>(token)
  } catch {
    return null
  }
}

export const fetchAccessToken = async (refreshToken: ID) => {
  return await fetch(process.env.API_URL, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      query: `
          query getToken {
            token: getToken(refreshToken: "${refreshToken}") {
              user {
                id
                email
                role
              }
              accessToken
            }
          }
        `,
    }),
  })
}
