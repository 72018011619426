import { gql } from '@apollo/client'

import { StudentBenefit } from 'types/models'

export type BuyBenefitData = {
  id: ID
}

export type BuyBenefitPayload = StudentBenefit

export default gql`
  mutation buyBenefit($data: IdData!) {
    studentBenefit: buyBenefit(data: $data) {
      id
      coins
      student {
        id
      }
      benefit {
        id
        image
        name
      }
    }
  }
`
