import React from 'react'
import { Navigate } from 'react-router'

import { Role } from 'types/models'
import { useAuthStore } from 'stores/auth'

type ProtectRouteProps = {
  children?: React.ReactNode
  component?: React.ComponentType
}

const Render = ({
  children,
  component: Route = () => <>{children}</>,
}: ProtectRouteProps) => <Route />

const Redirect = () => <Navigate to="/" replace />

type Props = {
  [key in Role]?: React.ComponentType
}

const Dashboard = (components: Props) => {
  const Wrapper = () => {
    const user = useAuthStore<'user'>((state) => state.user)

    const Component = components[user?.role || '']

    return <Render component={Component || Redirect} />
  }

  return <Wrapper />
}

export default Dashboard
