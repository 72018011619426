import styled from 'styled-components'

import { makeAllStyles } from '../styled'
import type { Props } from './Text'

export const Wrapper = styled.p.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['hidden'].includes(prop) && defaultValidatorFn(prop),
})<Props>`
  ${({ theme, variant }) => theme.textVariants[variant!]}

  ${makeAllStyles}
  ${({ css }) => css}
`
