import { gql } from '@apollo/client'

export type LogoutData = {
  refreshToken: ID
}

export type LogoutPayload = boolean

export default gql`
  mutation logout($refreshToken: ID!) {
    logout(refreshToken: $refreshToken)
  }
`
