import React from 'react'

import { Box, PlaceImage, Text } from 'ui'
import { Modal } from 'components'
import { PickDuckModal } from 'components/modals'
import { getImage } from 'utils/files'

import { DucksPayload } from 'apollo/queries'

import * as S from './styled'

type Props = {
  active?: boolean
  data: DucksPayload[number]
}

const Duck = ({ active, data }: Props) => (
  <Modal>
    {/* TRIGGER */}
    <Modal.Trigger>
      <Box alignItems="center" gap="l" ph="sm" pb="sm" css={S.hover}>
        <PlaceImage
          src={data.image}
          placeholder={getImage(data.image, 'placeholder', 'png')}
          fallback="/assets/images/duck@fallback.png"
          alt={data.name}
          defW={204}
          defH={206}
          opos="center"
          ofit="contain"
          ratio="1"
          w="100%"
          h="auto"
        />

        <Text variant="heading3A" color="light" textAlign="center">
          {data?.name}
        </Text>
      </Box>
    </Modal.Trigger>
    {/* END TRIGGER */}

    {/* MODAL */}
    <Modal.Content extended>
      <PickDuckModal {...{ data, active }} />
    </Modal.Content>
    {/* END MODAL */}
  </Modal>
)

export default React.memo(Duck)
