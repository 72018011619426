import React from 'react'

import PlaceImage from 'ui/PlaceImage'
import { Box } from 'ui/core'
import { getImage } from 'utils/files'

type Props = {
  src?: string
  alt?: string
}

const Avatar = ({ src, alt }: Props) => (
  <Box
    position="relative"
    justifyContent="center"
    alignItems="center"
    bgColor="secondary100"
    radius="rounded"
    padding="3xs"
    w={30}
    h={30}
    desktop={{
      w: 59,
      h: 59,
      padding: 'ss',
    }}
    style={{ flexShrink: 0 }}
  >
    <PlaceImage
      draggable="false"
      src={src}
      placeholder={getImage(src, 'placeholder', 'png')}
      fallback="/assets/images/duck@fallback.png"
      alt={alt || 'káčer'}
      defW={40}
      defH={40}
      ofit="contain"
      opos="center"
      w="100%"
      h="auto"
      maxh="100%"
    />
  </Box>
)

export default React.memo(Avatar)
