import { css } from 'styled-components'

export const styles = css`
  border: 2px solid ${({ theme }) => theme.colors.light};
  box-shadow: ${({ theme }) => theme.shadows.progress.wrapper};

  & > div {
    box-shadow: ${({ theme }) => theme.shadows.progress.inside};
  }
`
