import React, { useCallback } from 'react'
import { useQuery } from '@apollo/client'

import { Box, Button, Head, Search } from 'ui'
import { Content } from 'components/layout'
import { Subject } from 'pages/sections'

import { Modal } from 'components'
import { AddSubjectModal } from 'components/modals'

import {
  GET_SCHOOL_SUBJECTS,
  SchoolSubjectsData,
  SchoolSubjectsPayload,
} from 'apollo/queries'

type TData = Wrap<SchoolSubjectsData, 'data'>
type TPayload = Wrap<SchoolSubjectsPayload, 'subjects'>

const Subjects = () => {
  const { data, refetch, loading } = useQuery<TPayload, TData>(
    GET_SCHOOL_SUBJECTS,
    { variables: { data: {} } }
  )

  const handleSearch = useCallback(
    (query: string) => refetch({ data: { query } }),
    [refetch]
  )

  const renderItems = useCallback(
    (item: SchoolSubjectsPayload[number]) => (
      <Subject key={`subject-${item.id}`} data={item} />
    ),
    []
  )

  return (
    <>
      <Head title="Predmety" />

      <Content>
        <Content.Header wrap>
          <Box flex={1} minHeight={43}>
            <Search
              placeholder="Zadajte názov predmetu"
              callback={handleSearch}
            />
          </Box>

          <Modal>
            <Modal.Trigger>
              <Button as="div">Pridať predmet</Button>
            </Modal.Trigger>

            <Modal.Content extended>
              <AddSubjectModal />
            </Modal.Content>
          </Modal>
        </Content.Header>

        {data?.subjects?.length ? (
          <Box gap="m">{data?.subjects?.map(renderItems)}</Box>
        ) : loading ? (
          <Content.Loading />
        ) : (
          <Content.Empty text="Nenašli sa žiadne predmety" />
        )}
      </Content>
    </>
  )
}

export default React.memo(Subjects)
