import React, { ImgHTMLAttributes, SyntheticEvent, useCallback } from 'react'
import ProgressiveImage from 'react-progressive-graceful-image'

import { Box } from 'ui/core'
import { TBoxProps } from 'ui/core/Box/Box'
import { TThemeColor } from 'styles/theme'

import * as S from './styled'

type Props = {
  placeholder?: string
  fallback?: string
  force?: boolean
  color?: TThemeColor
  defW?: number | string
  defH?: number | string
} & TBoxProps &
  ImgHTMLAttributes<HTMLImageElement>

const PlaceImage = ({
  src = 'http://',
  placeholder,
  fallback,
  force,
  color,
  alt,
  defW,
  defH,
  ...props
}: Props) => {
  const handleError = useCallback(
    (e: Event) => {
      const source = fallback || placeholder
      const img = e as unknown as SyntheticEvent<HTMLInputElement, Event>
      if (img?.currentTarget?.src) img.currentTarget.src = source || ''
    },
    [fallback, placeholder]
  )

  return (
    <ProgressiveImage src={src} placeholder={placeholder} onError={handleError}>
      {(source, loading) =>
        loading || force ? (
          <Box
            position="relative"
            overflow="hidden"
            radius="inherit"
            bgColor={color || 'transparent'}
            css={S.wrapper}
            w={defW}
            h={defH}
            {...props}
          >
            {placeholder && (
              <Box<'img'>
                as="img"
                data-placeholder
                display="block"
                bgColor={color || 'transparent'}
                width={defW}
                height={defH}
                {...props}
                src={placeholder}
                alt={`Loading ${alt}`}
              />
            )}
          </Box>
        ) : (
          <Box<'img'>
            as="img"
            display="block"
            bgColor={color || 'transparent'}
            width={defW}
            height={defH}
            {...props}
            src={source}
            alt={alt}
          />
        )
      }
    </ProgressiveImage>
  )
}

export default React.memo(PlaceImage)
