import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import { Box, Search } from 'ui'
import { Content } from 'components/layout'
import { List } from './sections'

import { LONG_POLL_INTERVAL } from 'constants/global'
import {
  ClassStudentsData,
  ClassStudentsPayload,
  GET_CLASS_STUDENTS,
} from 'apollo/queries'

type Params = {
  id: ID
}

type TPayload = Wrap<ClassStudentsPayload, 'students'>
type TData = Wrap<ClassStudentsData, 'data'>

const Students = () => {
  const navigate = useNavigate()
  const { id } = useParams<Params>()

  const { data, refetch, loading } = useQuery<TPayload, TData>(
    GET_CLASS_STUDENTS,
    {
      variables: { data: { id: id || '' } },
      pollInterval: LONG_POLL_INTERVAL,
      onError: () => navigate('/', { replace: true }),
    }
  )

  const handleSearch = useCallback(
    (query: string) => refetch({ data: { id: id!, query } }),
    [refetch, id]
  )

  return (
    <>
      <Box flex={1} mb="s" largePhone={{ mb: 'm' }}>
        <Search placeholder="Zadajte meno" callback={handleSearch} />
      </Box>

      {data?.students?.length && id ? (
        <List data={data.students} classId={id} />
      ) : loading ? (
        <Content.Loading />
      ) : (
        <Content.Empty text="Nenašli sa študenti v tejto triede" />
      )}
    </>
  )
}

export default React.memo(Students)
