import { object, string, InferType, number } from 'yup'

const schema = object({
  id: string().optional(),
  studentId: string().required(),
  taskId: string().required(),
  points: number().required().min(0).integer(),
  subject: string().required(),
}).required()

export type UpsertTaskData = InferType<typeof schema>

export default schema
