import useUpdateEffect from './useUpdateEffect'

export default <T>(dep: T, cb: () => void, delay = 1000) => {
  useUpdateEffect(() => {
    const handler = setTimeout(cb, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [dep, cb, delay])
}
