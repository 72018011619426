// Magic 🪄, don't ask

type addPrefix<T, P extends string> = {
  [K in keyof T as K extends StrNum ? `${P}${K}` : never]: T[K]
}

type Obj = Record<StrNum, StrNum>

export const mapObject = <T extends Obj, K extends string>(obj: T, name: K) =>
  Object.entries(obj).reduce((acc, curr) => {
    acc[`${name as string}${curr[0]}`] = curr[1]
    return acc
  }, {} as addPrefix<T, K>)
