import { css } from 'styled-components'

export const hover = css`
  img {
    transition: all 150ms;
  }

  &:hover img {
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
  }
`
