import React, { useCallback, useMemo } from 'react'
import { Form, Formik } from 'formik'
import { useMutation, useQuery } from '@apollo/client'

import { Box, Text, Button, Search } from 'ui'
import { useErrorHandler } from 'hooks'
import { Item } from './sections'
import useToastStore from 'stores/toast'

import Modal, { useModal } from 'components/Modal/Modal'

import {
  NewSubjectsData,
  NewSubjectsPayload,
  GET_NEW_SUBJECTS,
  GET_TEACHER_SUBJECTS,
  TeacherSubjectsPayload,
} from 'apollo/queries'
import { AssignSubjectsPayload, ASSIGN_SUBJECTS } from 'apollo/mutations'
import validationSchema, { AssignSubjectsData } from 'schemas/assignSubjects'

type Props = {
  teacherId: ID
}

type TQPayload = Wrap<NewSubjectsPayload, 'subjects'>
type TQData = Wrap<NewSubjectsData, 'data'>

type TMPayload = Wrap<AssignSubjectsPayload, 'subjects'>
type TMData = Wrap<AssignSubjectsData, 'data'>

const AssignSubject = ({ teacherId }: Props) => {
  const { hide } = useModal()
  const { handle } = useErrorHandler()
  const { show } = useToastStore()

  const { data, refetch } = useQuery<TQPayload, TQData>(GET_NEW_SUBJECTS, {
    variables: { data: { id: teacherId } },
  })

  const [assignSubjects, { loading }] = useMutation<TMPayload, TMData>(
    ASSIGN_SUBJECTS,
    {
      onCompleted: () =>
        show({
          title: 'Predmety boli úspešne priradené',
          type: 'success',
        }),
    }
  )

  const initialValues = useMemo(
    (): AssignSubjectsData => ({
      teacherId,
      subjects: [],
    }),
    [teacherId]
  )

  const onSubmit = useCallback(
    (values: AssignSubjectsData) => {
      assignSubjects({
        variables: { data: values },
        refetchQueries: [
          {
            query: GET_NEW_SUBJECTS,
            variables: { data: { id: teacherId } },
          },
        ],
        update: (cache, { data: response }) => {
          cache.updateQuery(
            {
              query: GET_TEACHER_SUBJECTS,
              variables: { data: { id: teacherId } },
            },
            (data?: Wrap<TeacherSubjectsPayload, 'subjects'>) => {
              if (data?.subjects) {
                return { subjects: [...response!.subjects, ...data.subjects] }
              }
            }
          )
        },
      })
        .then(hide)
        .catch(handle())
    },
    [assignSubjects, hide, handle, teacherId]
  )

  const handleSearch = useCallback(
    (query: string) => {
      refetch({ data: { id: teacherId, query } })
    },
    [refetch, teacherId]
  )

  const renderItems = useCallback(
    (item: NewSubjectsPayload[number]) => (
      <Item key={`subject-${item.id}`} data={item} />
    ),
    []
  )

  return (
    <Modal.Wrapper indented condensed>
      <Modal.Title>Priradiť predmet</Modal.Title>

      <Box as="section" gap="3xs">
        {/* LABEL */}
        <Text<'label'> as="label" htmlFor="title" variant="paragraphMB">
          Názov predmetu
        </Text>
        {/* END LABEL */}

        <Search
          id="title"
          placeholder="Názov"
          callback={handleSearch}
          fullWidth
        />
      </Box>

      <Formik
        {...{ validationSchema, initialValues, onSubmit }}
        validateOnChange
      >
        {({ values }) => (
          <Form>
            {/* CLASSES LIST */}
            <Box maxHeight={395} overflow="scroll" radius="input">
              <Box mb="s" gap="s">
                {data?.subjects?.map(renderItems)}
              </Box>
            </Box>
            {/* END CLASSES LIST */}

            <Modal.Actions inForm>
              <Button
                type="submit"
                disabled={!values.subjects.length || loading}
                isLoading={loading}
              >
                Pridať
              </Button>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal.Wrapper>
  )
}

export default React.memo(AssignSubject)
