import { css } from 'styled-components'

const formStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: ${({ theme }) => theme.sizes.s};

  button {
    align-self: center;
    margin-top: ${({ theme }) => theme.sizes.s};
  }
`

export const wrapper = css`
  box-shadow: ${({ theme }) => theme.shadows.card};

  form {
    ${formStyles}
  }
`
