import { gql } from '@apollo/client'

import { Subject } from 'types/models'

export type DeleteSubjectData = {
  id: ID
}

export type DeleteSubjectPayload = Subject

export default gql`
  mutation deleteSubject($data: IdData!) {
    subject: deleteSubject(data: $data) {
      id
    }
  }
`
