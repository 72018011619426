import { gql } from '@apollo/client'

import { User } from 'types/models'

export type AddStudentData = {
  id: ID
  classId: ID
}

export type AddStudentPayload = User

export default gql`
  mutation addStudent($data: AddStudentData!) {
    student: addStudent(data: $data) {
      id
      name
      surname
      avatar {
        id
        name
        image
      }
      registeredAt
      createdAt
    }
  }
`
