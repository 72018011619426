import React from 'react'

import { Avatar, Box, PlaceImage, Text } from 'ui'
import { Modal } from 'components'
import { Item } from 'components/items'
import { Timeline } from 'components/layout'
import { getImage } from 'utils/files'

import { MyUsedPayload } from 'apollo/queries'

type Props = {
  data: MyUsedPayload[number]
}

const MyTask = ({ data }: Props) => (
  <Modal>
    {/* TRIGGER */}
    <Modal.Trigger>
      <Timeline.Item>
        <Item
          title={data?.benefit?.benefit?.name}
          subtitle={data?.subject?.name}
          image={{
            src: getImage(data?.benefit?.benefit?.image, 'thumbnail'),
            placeholder: getImage(data?.benefit?.benefit?.image, 'placeholder'),
            fallback: '/assets/logo.svg',
          }}
        >
          <Item.Footer>
            <Box
              flexDirection="row"
              alignItems="center"
              gap="ss"
              tablet={{ gap: 'sm' }}
              desktop={{ mr: '20%' }}
            >
              <Avatar
                src={data?.teacher?.avatar?.image}
                alt={data?.teacher?.avatar?.name}
              />

              <Text
                variant="heading6A"
                color="grey800"
                tablet={{ fontSize: 18, lineHeight: 22 }}
                desktop={{ fontSize: 20, lineHeight: 24 }}
              >
                {data?.teacher?.name} {data?.teacher?.surname}
              </Text>
            </Box>
          </Item.Footer>
        </Item>
      </Timeline.Item>
    </Modal.Trigger>
    {/* END TRIGGER */}

    {/* MODAL */}
    <Modal.Content extended>
      <Modal.Wrapper>
        <PlaceImage
          src={data.benefit.benefit.image}
          placeholder={getImage(data.benefit.benefit.image, 'placeholder')}
          alt={data?.benefit?.benefit?.name}
          defW={591}
          defH={833}
          ratio="591 / 833"
          w="100%"
          h="auto"
        />
      </Modal.Wrapper>
    </Modal.Content>
    {/* END MODAL */}
  </Modal>
)

export default React.memo(MyTask)
