import { ReactNode } from 'react'
import { create } from 'zustand'

type Message = {
  text: ReactNode
  button?: string
  onClick?: () => void
}

const messages = {
  START: {
    text: 'Začni zbierať body plnením úloh a získaj benefity!',
    button: 'Otvoriť galériu úloh',
  } as Message,

  NEW_BENEFIT: {
    text: 'Pecka! Získal si ďalší benefit, môžeš ho nájsť v sekcii “Aktuálne benefity”',
  } as Message,
} as const

type MessageKey = keyof typeof messages

export type MessageState = {
  message: Message | null
  show: (type: MessageKey, onClick?: () => void) => void
  hide: () => void
}

const useMessageStore = create<MessageState>()((set) => ({
  message: null,

  show: (type, onClick) =>
    set(() => ({
      message: {
        ...messages[type],
        onClick,
      },
    })),

  hide: () => set(() => ({ message: null })),
}))

export default useMessageStore
