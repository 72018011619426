import React from 'react'
import { motion } from 'framer-motion'

import { Box } from '../core'

import * as S from './styled'

export type Props = {
  value: number
  total: number
}

const Progress = ({ value, total }: Props) => (
  <Box
    position="relative"
    bg="progress"
    radius="rounded"
    overflow="hidden"
    height={20}
    tablet={{ height: 33 }}
    css={S.styles}
  >
    <Box<typeof motion.div>
      as={motion.div}
      radius="rounded"
      height="100%"
      bg="progressLine"
      minw={29}
      maxw="100%"
      initial={{ width: '29px' }}
      animate={{ width: `${(Math.min(value, total) / total) * 100}%` }}
      transition={{ type: 'spring', duration: 0.5 }}
      style={{ transformOrigin: 'left' }}
    />
  </Box>
)

export default React.memo(Progress)
