import React, { useCallback } from 'react'
import { useQuery } from '@apollo/client'

import { Box, Button, Head, Search } from 'ui'
import { Modal } from 'components'
import { Content } from 'components/layout'
import { UpsertClassModal } from 'components/modals'
import { MyClass } from 'pages/sections'

import { ForRoles } from 'ui/auth'

import { LONG_POLL_INTERVAL } from 'constants/global'
import { GET_MY_CLASSES, MyClassesData, MyClassesPayload } from 'apollo/queries'

type TData = Wrap<MyClassesData, 'data'>
type TPayload = Wrap<MyClassesPayload, 'myClasses'>

const Classes = () => {
  const { data, refetch, loading } = useQuery<TPayload, TData>(GET_MY_CLASSES, {
    pollInterval: LONG_POLL_INTERVAL,
    variables: { data: {} },
  })

  const handleSearch = useCallback(
    (query: string) => refetch({ data: { query } }),
    [refetch]
  )

  const renderItems = useCallback(
    (item: MyClassesPayload[number]) => (
      <MyClass key={`my-class-${item.id}`} data={item} />
    ),
    []
  )

  return (
    <>
      <Head title="Triedy" />

      <Content>
        <Content.Header wrap>
          <Box flex={1} minHeight={43}>
            <Search
              placeholder="Zadajte názov triedy"
              callback={handleSearch}
            />
          </Box>

          <ForRoles roles={['MANAGER']}>
            <Modal>
              <Modal.Trigger>
                <Button as="div">Vytvoriť triedu</Button>
              </Modal.Trigger>

              <Modal.Content extended>
                <UpsertClassModal />
              </Modal.Content>
            </Modal>
          </ForRoles>
        </Content.Header>

        {data?.myClasses?.length ? (
          <Box gap="xs" largePhone={{ gap: 'm' }}>
            {data?.myClasses?.map(renderItems)}
          </Box>
        ) : loading ? (
          <Content.Loading />
        ) : (
          <Content.Empty text="Zatiaľ nemáš žiadne triedy" />
        )}
      </Content>
    </>
  )
}

export default React.memo(Classes)
