import styled, { css } from 'styled-components'

import type { Props } from './Button'

const state = css<Props>`
  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.button.hover};
    background-color: ${({ theme, variant }) =>
      theme.colors?.[`${variant}600`]};
  }

  &:active {
    background-color: ${({ theme, variant }) =>
      theme.colors?.[`${variant}500`]};
  }
`

const leftIcon = css<Props>`
  padding-left: calc(${({ theme }) => theme.sizes.m} + 29px);

  &::before {
    content: '';
    background-image: ${({ icon }) => `url(/assets/icons/${icon}.svg)`};
    position: absolute;
    left: ${({ theme }) => theme.sizes.m};
    width: 19px;
    height: 19px;
  }
`

const invertedButton = css<Props>`
  background-color: transparent;
  color: ${({ theme, variant }) =>
    theme.colors?.[`${variant}800`] || theme.colors.light};
`

const disabledButton = css<Props>`
  background-color: ${({ theme, variant }) => theme.colors?.[`${variant}200`]};
  cursor: not-allowed;
`

const defaultButton = css<Props>`
  background-color: ${({ theme, variant }) => theme.colors?.[`${variant}500`]};
  box-shadow: ${({ theme }) => theme.shadows.button.default};
  color: ${({ theme }) => theme.colors.light};

  ${({ disabled }) => !disabled && state}
`

export const Wrapper = styled.button<Props>`
  display: ${({ as }) => !as && 'inline'};
  ${({ inverted }) => (inverted ? invertedButton : defaultButton)}

  padding-block: calc(${({ theme }) => theme.sizes.xs} - 2px);
  padding-inline: calc(${({ theme }) => theme.sizes.m} - 4px);
  padding-bottom: calc(${({ theme }) => theme.sizes.xs} - 4.5px);

  border-radius: ${({ theme }) => theme.borderRadii.rounded};

  ${({ theme }) => theme.textVariants.paragraphMB};
  line-height: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ${({ theme }) => theme.textVariants.paragraphLB};
    line-height: 22px;
  }

  text-align: center;
  white-space: nowrap;

  transition: all 200ms;
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  ${({ icon }) => !!icon && leftIcon}
  ${({ disabled }) => !!disabled && disabledButton}
`
