import React, { useEffect } from 'react'
import { Outlet } from 'react-router'

import { Box, Container, Icon, Image } from 'ui'
import { Navigation } from 'components'

import useMessageStore from 'stores/message'

import * as S from './styled'

const Main = () => {
  const { hide } = useMessageStore()

  useEffect(hide, [hide])

  return (
    <Box position="relative" overflow="hidden" minHeight="100vh" bg="darkBgG">
      <Image
        width={1440}
        height={1000}
        src="images/bg.png"
        alt="background"
        draggable="false"
        css={S.bg}
      />

      <Icon name="bg-left" width={137} height={192} css={S.left} />
      <Icon name="bg-right" width={164} height={230} css={S.right} />

      <Icon name="feather" width={39} height={85} css={S.fg} />

      <Container zIndex="front">
        <Navigation />

        <Box as="main" gap="sm" pb="3xl">
          <Outlet />
        </Box>
      </Container>
    </Box>
  )
}

export default Main
