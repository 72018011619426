import { object, string, InferType, array } from 'yup'

const schema = object({
  teacherId: string().required(),
  classes: array().of(string()).required(),
}).required()

export type AssignClassesData = InferType<typeof schema>

export default schema
