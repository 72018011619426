import styled, { css } from 'styled-components'

interface IWrapper {
  hasError: boolean
  disabled?: boolean
}

const autofillFix = css<IWrapper>`
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.grey800};
    box-shadow: 0 0 0px 1000px
      ${({ theme, disabled }) => theme.colors?.[disabled ? 'grey200' : 'light']}
      inset;
  }
`

const errorStyle = css`
  border-color: ${({ theme }) => theme.colors.primary500};
`

const disabledStyle = css`
  background-color: ${({ theme }) => theme.colors.grey200};
  cursor: not-allowed;
`

export const field = css`
  ${({ theme }) => theme.textVariants.paragraphL};
  color: ${({ theme }) => theme.colors.grey800};

  background-color: ${({ theme }) => theme.colors.light};
  border: 2px solid ${({ theme }) => theme.colors.grey300};
  border-radius: ${({ theme }) => theme.borderRadii.input};

  padding-block: calc(${({ theme }) => theme.sizes.xs} - 2px);
  padding-inline: calc(${({ theme }) => theme.sizes.m} - 4px);

  transition: all 200ms;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey500};
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey500};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.grey600};
  }
`

export const Wrapper = styled.div<IWrapper>`
  display: flex;
  flex-direction: column;

  width: 100%;
  gap: ${({ theme }) => theme.sizes['3xs']};

  input,
  textarea {
    ${field}
    ${autofillFix}

    ${({ hasError }) => hasError && errorStyle}
    ${({ disabled }) => disabled && disabledStyle}
  }

  textarea {
    min-height: 66px;
    max-height: 120px;
    resize: none;
  }
`
