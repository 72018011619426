import { gql } from '@apollo/client'

import { User } from 'types/models'

export type StudentsData = {
  id: ID
  query?: string
}

export type StudentsPayload = User[]

export default gql`
  query getStudents($data: IdqueryData!) {
    students: getStudents(data: $data) {
      id
      name
      surname
      avatar {
        id
        name
        image
      }
      registeredAt
      createdAt
    }
  }
`
