import React, { InputHTMLAttributes } from 'react'
import { ErrorMessage, Field, FieldAttributes } from 'formik'

import Text from '../core/Text'

import * as S from './styled'

export type Props = {
  label?: string
  hasError: boolean
} & FieldAttributes<any> &
  InputHTMLAttributes<HTMLInputElement>

const Input = ({ label, name, hasError, disabled, ...props }: Props) => (
  <S.Wrapper {...{ hasError, disabled }}>
    {!!label && (
      <Text<'label'>
        as="label"
        htmlFor={name}
        variant="paragraphMB"
        color="text"
      >
        {label}
      </Text>
    )}

    <Field id={name} {...{ name, disabled, ...props }} />

    {hasError && (
      <Text as="small" variant="paragraphS" color="primary500">
        <ErrorMessage {...{ name }} />
      </Text>
    )}
  </S.Wrapper>
)

export default React.memo(Input)
