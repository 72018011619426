import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'

import { setContext } from '@apollo/client/link/context'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { onError } from '@apollo/client/link/error'

import { getRefreshToken, removeRefreshToken } from 'utils/cookies'
import {
  validateAccessToken,
  fetchAccessToken,
  getAuthorization,
} from 'utils/auth'

import store from 'stores/auth'
import toast from 'stores/toast'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  graphQLErrors?.forEach(({ extensions: { code } }) => {
    if (code === 'UNAUTHORIZED') store.setState({ accessToken: null })
  })

  if (networkError) {
    toast.getState().show({
      type: 'danger',
      title: 'Pripojenie zlyhalo',
      message: 'Prosím, skúste to znovu',
    })
  }
})

const refreshLink: TokenRefreshLink<string> = new TokenRefreshLink({
  accessTokenField: 'accessToken',

  isTokenValidOrUndefined: () => {
    // Get access and refresh token
    const accessToken = store.getState().accessToken
    const refreshToken = getRefreshToken()

    // Is refresh token present
    if (refreshToken && !accessToken) return false

    // Is token undefined
    if (!accessToken) return true

    // Is token valid
    return validateAccessToken(accessToken)
  },

  fetchAccessToken: async () => {
    const refreshToken = getRefreshToken() || ''

    const response = await fetchAccessToken(refreshToken)
    const json = await response.json()

    return json.data.token
  },

  handleResponse: (operation, accessTokenField) => (response) => {
    const accessToken = response?.[accessTokenField]

    operation.setContext((context) => ({
      headers: {
        ...context.headers,
        authorization: getAuthorization(accessToken),
      },
    }))

    return { accessToken: accessToken || null }
  },

  handleFetch: (accessToken) => {
    // Set access token from response
    store.setState({ accessToken })
  },

  handleError: () => {
    // Logout and clear cache
    removeRefreshToken()
    store.getState().clearToken()
    client.resetStore()

    toast.getState().show({
      type: 'info',
      title: 'Vaša relácia vypršala',
      message: 'Prosím, prihláste sa znovu',
    })
  },
})

const authLink = setContext((_, { headers }) => {
  const accessToken = store.getState().accessToken

  return {
    headers: {
      ...headers,
      authorization: getAuthorization(accessToken),
    },
  }
})

const httpLink = createHttpLink({
  uri: process.env.API_URL,
})

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, refreshLink, authLink, httpLink]),
  cache: new InMemoryCache(),
})

export default client
