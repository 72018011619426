import { gql } from '@apollo/client'

import { User } from 'types/models'

export type ReassignStudentData = {
  id: ID
  studentId: ID
  classId: ID
}

export type ReassignStudentPayload = User

export default gql`
  mutation reassignStudent($data: ReassignStudentData!) {
    student: reassignStudent(data: $data) {
      id
      name
      surname
      avatar {
        id
        name
        image
      }
      registeredAt
      createdAt
    }
  }
`
