import React, { useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { groupBy } from 'lodash'

import { Box, Head, Text } from 'ui'
import { Message } from 'components'
import { Content, Grid } from 'components/layout'
import { Duck } from 'pages/sections'
import { ForRoles } from 'ui/auth'

import { DucksPayload, GET_DUCKS, GET_ME, MePayload } from 'apollo/queries'

type TPayload = Wrap<DucksPayload, 'ducks'>

const Ducks = () => {
  const { data: user } = useQuery<Wrap<MePayload, 'me'>>(GET_ME)
  const { data, loading } = useQuery<TPayload>(GET_DUCKS)

  const ducksByLevels = useMemo(
    () => groupBy(data?.ducks, (duck) => duck.level),
    [data]
  )

  const renderItems = useCallback(
    (item: DucksPayload[number]) => (
      <Duck
        key={`duck-${item.id}`}
        active={user?.me?.avatar?.id === item.id}
        data={item}
      />
    ),
    [user]
  )

  const renderGroups = useCallback(
    ([level, group]: [string, DucksPayload]) => (
      <Box
        as="article"
        key={`benefits-${level}`}
        tablet={{ ph: 'xxl', pv: 'xs' }}
        pb="sm"
      >
        <Grid.Separator>Level {level}</Grid.Separator>
        <Grid inline>{group?.map(renderItems)}</Grid>
      </Box>
    ),
    [renderItems]
  )

  return (
    <>
      <Head title="Galéria káčerov" />

      <ForRoles roles={['STUDENT']}>
        <Message>
          <Text as="span" color="primary500">
            Vyber
          </Text>{' '}
          si káčera! <br />
          Pre zobrazenie ostatných káčerov vo vyšších leveloch musíš daný level
          dosiahnuť!
        </Message>
      </ForRoles>

      <Content>
        {data?.ducks?.length ? (
          Object.entries(ducksByLevels)?.map(renderGroups)
        ) : loading ? (
          <Content.Loading />
        ) : user?.me?.level === 0 ? (
          <Content.Empty text="Pre zobrazenie káčerov musíš dosiahnúť prvý level" />
        ) : (
          <Content.Empty text="Žiadni káčeri sa nenašli" />
        )}
      </Content>
    </>
  )
}

export default React.memo(Ducks)
