import React, { useCallback, useMemo } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'

import { Button, Error, Input } from 'ui'
import { useErrorHandler } from 'hooks'
import useToastStore from 'stores/toast'

import Modal, { useModal } from 'components/Modal/Modal'

import { GET_STUDENTS, StudentsPayload } from 'apollo/queries'
import { CreateStudentPayload, CREATE_STUDENT } from 'apollo/mutations'
import validationSchema, { CreateStudentData } from 'schemas/createStudent'

type Props = {
  classId: ID
  onAction: () => void
}

type TPayload = Wrap<CreateStudentPayload, 'student'>
type TData = Wrap<CreateStudentData, 'data'>

const Assignment = ({ classId, onAction }: Props) => {
  const { hide } = useModal()
  const { handle, error } = useErrorHandler()
  const { show } = useToastStore()

  const [createStudent, { loading }] = useMutation<TPayload, TData>(
    CREATE_STUDENT,
    {
      onCompleted: ({ student }) =>
        show({
          title: 'Študent bol úspešne vytvorený',
          message: `${student?.name} ${student?.surname}`,
          type: 'success',
        }),
    }
  )

  const initialValues = useMemo(
    (): CreateStudentData => ({
      classId,
      name: '',
      surname: '',
      birthYear: 1900,
      email: '',
    }),
    [classId]
  )

  const onSubmit = useCallback(
    (
      values: CreateStudentData,
      formikHelpers: FormikHelpers<CreateStudentData>
    ) => {
      createStudent({
        variables: { data: values },
        update: (cache, { data: response }) => {
          cache.updateQuery(
            {
              query: GET_STUDENTS,
              variables: { data: { id: classId } },
            },
            (data?: Wrap<StudentsPayload, 'students'>) => {
              if (data?.students) {
                return { students: [response!.student, ...data.students] }
              }
            }
          )
        },
      })
        .then(hide)
        .catch(handle(formikHelpers))
    },
    [createStudent, hide, handle, classId]
  )

  return (
    <Modal.Wrapper indented condensed>
      <Modal.Title goBack={onAction}>Pridať nového študenta</Modal.Title>

      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        validateOnChange
        enableReinitialize
      >
        {({ errors, touched }) => (
          <Form>
            <Input
              type="text"
              name="name"
              label="Meno"
              hasError={touched.name && errors.name}
              disabled={loading}
              required
            />

            <Input
              type="text"
              name="surname"
              label="Priezvisko"
              hasError={touched.surname && errors.surname}
              disabled={loading}
              required
            />

            <Input
              type="number"
              name="birthYear"
              label="Rok narodenia"
              hasError={touched.birthYear && errors.birthYear}
              disabled={loading}
              required
            />

            <Input
              type="email"
              name="email"
              label="Email"
              hasError={touched.email && errors.email}
              disabled={loading}
              required
            />

            <Error message={error} />

            <Modal.Actions inForm>
              <Button type="submit" isLoading={loading} disabled={loading}>
                Pridať
              </Button>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal.Wrapper>
  )
}

export default React.memo(Assignment)
