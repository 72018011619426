import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { Button, Text } from 'ui'
import { Modal } from 'components'
import { ReassignStudentModal } from 'components/modals'
import { UserItem } from 'components/items'
import { User as TUser } from 'types/models'

import { ForRoles } from 'ui/auth'
import { getStatus } from 'utils/user'

type Props = {
  data: TUser
  classId?: ID
  hideFooter?: boolean
}

const User = ({ data, classId, hideFooter }: Props) => {
  const navigate = useNavigate()

  const status = useMemo(() => getStatus(data), [data])

  const toProfile = useCallback(() => navigate(data.id), [navigate, data])

  return (
    <UserItem>
      <UserItem.Content
        avatar={data?.avatar}
        title={`${data.name} ${data.surname}`}
        onClick={toProfile}
        subtitle={
          <Text as="span" variant="paragraphMB" color={status.color}>
            {status.text}
          </Text>
        }
      >
        {/* FOR MANAGER */}
        {!hideFooter && (
          <ForRoles roles={['MANAGER']}>
            <UserItem.Footer>
              <Modal>
                <Modal.Trigger disabled={!classId}>
                  <Button as="div" variant="secondary" disabled={!classId}>
                    Preradiť študenta
                  </Button>
                </Modal.Trigger>

                <Modal.Content extended>
                  {classId && (
                    <ReassignStudentModal
                      studentId={data?.id}
                      {...{ classId }}
                    />
                  )}
                </Modal.Content>
              </Modal>
            </UserItem.Footer>
          </ForRoles>
        )}
        {/* END FOR MANAGER */}
      </UserItem.Content>
    </UserItem>
  )
}

export default React.memo(User)
