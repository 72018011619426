import React from 'react'
import { motion } from 'framer-motion'

import { Box, Text } from 'ui'

import * as S from './styled'

type Props = {
  title: string
  children?: React.ReactNode
}

const Card = ({ title, children }: Props) => (
  <motion.article
    initial={{ y: '20px', opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    exit={{ y: '-20px', opacity: 0 }}
  >
    <Box
      bgColor="light"
      radius="card"
      paddingVertical="m"
      paddingHorizontal="s"
      largePhone={{ pv: 'l', ph: 'm' }}
      css={S.wrapper}
    >
      <Text
        as="h1"
        color="text"
        variant="heading2A"
        textAlign="center"
        marginBottom="m"
        largePhone={{ mb: 'l' }}
        tablet={{ fontSize: 36, lineHeight: 43 }}
      >
        {title}
      </Text>

      {children}
    </Box>
  </motion.article>
)

export default React.memo(Card)
