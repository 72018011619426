import { gql } from '@apollo/client'

import { User } from 'types/models'

export type TeachersData = {
  query?: string
}

export type TeachersPayload = User[]

export default gql`
  query getTeachers($data: QueryData!) {
    teachers: getTeachers(data: $data) {
      id
      name
      surname
      avatar {
        id
        name
        image
      }
      registeredAt
      createdAt
    }
  }
`
