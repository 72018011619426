import Cookies from 'js-cookie'

import { REFRESH_TOKEN, REFRESH_TOKEN_EXPIRATION } from 'constants/global'

export const setRefreshToken = (token: string) =>
  Cookies.set(REFRESH_TOKEN, token, { expires: REFRESH_TOKEN_EXPIRATION })

export const getRefreshToken = () => Cookies.get(REFRESH_TOKEN)

export const removeRefreshToken = () => Cookies.remove(REFRESH_TOKEN)
