import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Tab = styled(NavLink)`
  position: relative;
  display: inline;

  padding-block: calc(${({ theme }) => theme.sizes.xs} - 2px);
  padding-inline: ${({ theme }) => theme.sizes.xs};
  padding-bottom: calc(${({ theme }) => theme.sizes.xs} - 4.5px);

  border-radius: ${({ theme }) => theme.borderRadii.rounded};

  @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
    padding-inline: ${({ theme }) => theme.sizes.sm};
  }

  & > span {
    position: relative;
    z-index: ${({ theme }) => theme.zIndices.xl};
    text-align: center;
    white-space: nowrap;

    ${({ theme }) => theme.textVariants.paragraphMB};
    line-height: 20px;

    @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
      ${({ theme }) => theme.textVariants.paragraphLB};
      line-height: 22px;
    }
  }

  transition: all 200ms;
  user-select: none;
  cursor: pointer;
`

export const scroll = css`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.separator};

  &::before,
  &::after {
    width: ${({ theme }) => theme.sizes.xs};
    z-index: 999;

    background: ${({ theme }) => theme.colors.fade};
    position: absolute;
    bottom: 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
      width: ${({ theme }) => theme.sizes.sm};
    }
  }

  &::before {
    content: '';
    transform: rotate(180deg);

    right: 100%;
    top: -${({ theme }) => theme.sizes.sm};
  }

  &::after {
    content: '';

    left: 100%;
    top: -${({ theme }) => theme.sizes.sm};
  }
`

export const border = css`
  padding-inline: ${({ theme }) => theme.sizes.sm};
  margin-inline: -${({ theme }) => theme.sizes.sm};

  -ms-overflow-style: none;
  scrollbar-width: none;

  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`
