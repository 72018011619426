import { gql } from '@apollo/client'

import { User } from 'types/models'

export type ClassStudentData = {
  id: ID
}

export type ClassStudentPayload = {
  points: number
  level: number
  range: number
  coins: number
} & User

export default gql`
  query getClassStudent($data: IdData!) {
    student: getClassStudent(data: $data) {
      id
      points
      level
      range
      coins
    }
  }
`
