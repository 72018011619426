import React, { ComponentProps } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Box } from 'ui/core'
import Image from '../Image'

import * as S from './styled'

export type Props = {
  small?: boolean
} & ComponentProps<typeof Image>

const Logo = ({ small, ...props }: Props) => (
  <>
    <Helmet>
      <link rel="preload" href="/assets/logo.svg" as="image" />
    </Helmet>

    <Box<typeof Link>
      as={Link}
      to="/"
      css={S.wrapper}
      maxw={small ? 48 : 69}
      maxh={small ? 48 : 69}
      tablet={{
        maxw: small ? 59 : 80,
        maxh: small ? 59 : 80,
      }}
    >
      <Image
        src="logo.svg"
        alt="Úlet logo"
        width={small ? 59 : 80}
        height={small ? 59 : 80}
        draggable="false"
        {...props}
      />
    </Box>
  </>
)

export default React.memo(Logo)
