import { gql } from '@apollo/client'

import { Subject } from 'types/models'

export type AddSubjectPayload = Subject

export default gql`
  mutation addSubject($data: AddSubjectData!) {
    subject: addSubject(data: $data) {
      id
      name
      createdAt
    }
  }
`
