import React, { useCallback, useState } from 'react'
import { MotionContext } from 'framer-motion'

import { Initial, Main, Success } from './sections'

import { BenefitsPayload } from 'apollo/queries'

type Props = {
  balance: number
  data: BenefitsPayload[number]
}

type Steps = 'initial' | 'main' | 'success'

const BuyBenefit = ({ balance, data }: Props) => {
  const [step, setStep] = useState<Steps>('initial')

  const handleStep = useCallback((step: Steps) => () => setStep(step), [])

  return (
    <MotionContext.Provider value={{}}>
      {step === 'initial' && (
        <Initial onAction={handleStep('main')} {...{ data, balance }} />
      )}

      {step === 'main' && (
        <Main onAction={handleStep('success')} {...{ data, balance }} />
      )}

      {step === 'success' && <Success />}
    </MotionContext.Provider>
  )
}

export default React.memo(BuyBenefit)
