import styled, { css, keyframes } from 'styled-components'

import * as Accordion from '@radix-ui/react-accordion'

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`

export const wrapper = css`
  border-top: 1px solid ${({ theme }) => theme.colors.grey200};
`

export const Root = styled(Accordion.Root)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizes.xs};

  @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
    gap: ${({ theme }) => theme.sizes.m};
  }
`

export const Item = styled(Accordion.Item)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.grey100};
  border-radius: ${({ theme }) => theme.borderRadii.card};
  box-shadow: ${({ theme }) => theme.shadows.card};
  overflow: hidden;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  flex-direction: column;

  padding: ${({ theme }) => theme.sizes.ss};
  gap: ${({ theme }) => theme.sizes['3xs']};

  @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
    gap: ${({ theme }) => theme.sizes.xs};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: ${({ theme }) => theme.sizes.sm};
    gap: ${({ theme }) => theme.sizes.m};
    flex-direction: row;
  }
`

export const Trigger = styled(Accordion.Trigger)`
  display: flex;
  align-items: center;
  width: 100%;

  gap: ${({ theme }) => theme.sizes.s};

  @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
    gap: ${({ theme }) => theme.sizes.m};
  }

  svg {
    transform: rotate(180deg);
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);

    width: 8px;
    height: 16px;

    @media (min-width: ${({ theme }) => theme.breakpoints.largePhone}px) {
      width: 12px;
      height: 20px;
    }

    #path {
      fill: ${({ theme }) => theme.colors.primary500};
    }
  }

  &[data-state='open'] {
    svg {
      transform: rotate(270deg);
    }
  }
`

export const Content = styled(Accordion.Content)`
  overflow: hidden;

  width: 100%;

  animation-fill-mode: forwards;

  &[data-state='open'] {
    animation: ${slideDown} 200ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='closed'] {
    animation: ${slideUp} 200ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`
