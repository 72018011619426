import { gql } from '@apollo/client'

import { User } from 'types/models'

export type PickDuckData = {
  id: ID
}

export type PickDuckPayload = User

export default gql`
  mutation pickDuck($data: IdData!) {
    user: pickDuck(data: $data) {
      id
      avatar {
        id
        name
        image
      }
    }
  }
`
