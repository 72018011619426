import React, { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { Box, Button, PlaceImage } from 'ui'
import { useErrorHandler } from 'hooks'
import useToastStore from 'stores/toast'
import { getImage } from 'utils/files'

import Modal, { useModal } from 'components/Modal/Modal'

import { DucksPayload } from 'apollo/queries'
import { PickDuckData, PickDuckPayload, PICK_DUCK } from 'apollo/mutations'

type Props = {
  active?: boolean
  data: DucksPayload[number]
}

type TPayload = Wrap<PickDuckPayload, 'user'>
type TData = Wrap<PickDuckData, 'data'>

const PickDuck = ({ active, data }: Props) => {
  const { hide } = useModal()
  const { handle } = useErrorHandler()
  const { show } = useToastStore()

  const [pickDuck, { loading }] = useMutation<TPayload, TData>(PICK_DUCK, {
    onCompleted: ({ user }) =>
      show({
        title: 'Káčer bol úspešne zmenený',
        message: user?.avatar?.name,
        type: 'success',
      }),
  })

  const handlePick = useCallback(() => {
    pickDuck({
      variables: { data: { id: data.id } },
    })
      .then(hide)
      .catch(handle())
  }, [data, hide, pickDuck, handle])

  return (
    <Modal.Wrapper indented>
      <Modal.Title>Výber káčera</Modal.Title>

      <Box alignItems="center" pb="m">
        <PlaceImage
          src={data?.image}
          placeholder={getImage(data?.image, 'placeholder', 'png')}
          fallback="/assets/images/duck@fallback.png"
          alt={data?.name}
          defW={318}
          defH={320}
          w="100%"
          h="auto"
          maxw={320}
          opos="center"
          ofit="contain"
          ratio="1"
        />
      </Box>

      <Modal.Actions floating>
        <Button
          onClick={handlePick}
          isLoading={loading}
          disabled={loading || active}
        >
          {active ? 'Aktuálny káčer' : 'Vybrať káčera'}
        </Button>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export default React.memo(PickDuck)
