import React, { ChangeEvent, useCallback, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import Icon from 'ui/Icon'
import { Box } from 'ui/core'
import { useDebounce } from 'hooks'

import * as S from './styled'

type Props = {
  fullWidth?: boolean
  callback: (value: string) => void
} & React.InputHTMLAttributes<HTMLInputElement>

const MotionButton = motion(S.Button)

const Search = ({ callback, fullWidth, ...props }: Props) => {
  const [value, setValue] = useState<string>('')

  useDebounce(value, () => callback(value), 400)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value),
    [setValue]
  )

  const handleReset = useCallback(() => setValue(''), [setValue])

  return (
    <Box position="relative" css={S.wrapper} {...{ fullWidth }}>
      <S.Field value={value} onChange={handleChange} {...props} />

      <AnimatePresence initial={false}>
        {value && (
          <MotionButton
            onClick={handleReset}
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'spring', damping: 5, stiffness: 50 }}
          >
            <Icon name="cross" width={12} height={12} />
          </MotionButton>
        )}
      </AnimatePresence>
    </Box>
  )
}

export default React.memo(Search)
