import { useState, useCallback } from 'react'

export default <T>(): [T | null, (value: T | null, keep?: boolean) => void] => {
  const [selected, setSelected] = useState<T | null>(null)

  const select = useCallback(
    (value: T | null, keep?: boolean) =>
      setSelected((prev) => (prev === value && !keep ? null : value)),
    [setSelected]
  )

  return [selected, select]
}
