import React, { useEffect, useMemo, useRef } from 'react'
import { animate } from 'framer-motion'

import { Box, Icon, PlaceImage, Progress, Skeleton, Text } from 'ui'
import { ForRoles } from 'ui/auth'
import { getStatus } from 'utils/user'
import { getImage } from 'utils/files'

import { StudentPayload } from 'apollo/queries'

import * as S from './styled'

type Props = {
  data?: StudentPayload
}

const StudentHeader = ({ data }: Props) => {
  const pointsRef = useRef<HTMLSpanElement>(null)

  const status = useMemo(() => getStatus(data), [data])

  useEffect(() => {
    const controls = animate(0, data?.points || 0, {
      duration: 0.5,
      onUpdate(value) {
        if (pointsRef.current) {
          pointsRef.current.textContent = value.toFixed(0)
        }
      },
    })

    return () => controls.stop()
  }, [data])

  return (
    <Box
      as="header"
      flexDirection="row"
      bgColor="text"
      radius="card"
      padding="xs"
      gap="ss"
      largePhone={{ padding: 'sm' }}
      tablet={{ gap: 'xxl' }}
      desktop={{ mb: 'ss', gap: '8xl', padding: 'l' }}
    >
      <PlaceImage
        draggable="false"
        src={data?.avatar?.image}
        placeholder={getImage(data?.avatar?.image, 'placeholder', 'png')}
        fallback="/assets/images/duck@fallback.png"
        alt={data?.avatar?.name}
        alignSelf="center"
        ofit="contain"
        opos="center"
        defW={247}
        defH={248}
        w="auto"
        h="auto"
        maxw={80}
        tablet={{
          mt: -32,
          ml: -10,
          mb: -42,
          w: '100%',
          maxw: 274,
          maxh: 248,
        }}
        css={S.filter}
      />

      <Box flex={1}>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
          desktop={{ mb: -18 }}
        >
          <Text
            as="h1"
            w="100%"
            maxw="25ch"
            color="light"
            variant="heading1B"
            css={S.shadow}
            fontSize={24}
            lineHeight={48}
            largePhone={{ fontSize: 32, lineHeight: 58 }}
            tablet={{ fontSize: 38, lineHeight: 78 }}
            desktop={{ fontSize: 48, lineHeight: 83 }}
          >
            {data?.fullName || <Skeleton />}
          </Text>

          <ForRoles roles={['TEACHER', 'MANAGER']}>
            <Box flexDirection="row" alignItems="center" gap="3xs" pv="3xs">
              <Text
                variant="heading6A"
                color="light"
                css={S.shadow}
                largePhone={{ fontSize: 18, lineHeight: 22 }}
                tablet={{ fontSize: 20, lineHeight: 24 }}
              >
                {data?.coins || 0}
              </Text>

              <Icon name="coin" width={19} height={19} title="peniaze" />
            </Box>
          </ForRoles>
        </Box>

        <Box gap="xxs" largePhone={{ gap: 'ss' }}>
          <Box
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            gap="sm"
          >
            {/* FOR MANAGER */}
            <ForRoles roles={['MANAGER']}>
              <Box flex={1}>
                <Text as="span" variant="paragraphMB" color={status.color}>
                  {status?.text || <Skeleton />}
                </Text>
              </Box>
            </ForRoles>
            {/* END FOR MANAGER */}

            <Text
              variant="heading5A"
              color="light"
              textAlign="right"
              css={S.shadow}
              largePhone={{ fontSize: 20, lineHeight: 24 }}
              tablet={{ fontSize: 24, lineHeight: 29 }}
              desktop={{ fontSize: 28, lineHeight: 34 }}
            >
              {data?.level || 0} Level
            </Text>
          </Box>

          <Progress value={data?.points || 0} total={data?.range || 0} />

          <Text
            variant="heading6A"
            color="light"
            textAlign="right"
            css={S.shadow}
            tablet={{ fontSize: 18, lineHeight: 22 }}
          >
            <span ref={pointsRef} /> / {data?.range || 0} bodov
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(StudentHeader)
