import React, { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { motion } from 'framer-motion'

import { Box, Button, Icon, PlaceImage, Text } from 'ui'
import { useErrorHandler } from 'hooks'
import useMessageStore from 'stores/message'
import useToastStore from 'stores/toast'
import { getImage } from 'utils/files'

import Modal from 'components/Modal/Modal'

import { BenefitsPayload, GET_ME, GET_MY_BENEFITS } from 'apollo/queries'
import {
  BuyBenefitData,
  BuyBenefitPayload,
  BUY_BENEFIT,
} from 'apollo/mutations'

import * as S from './styled'

type Props = {
  balance: number
  data: BenefitsPayload[number]
  onAction: () => void
}

type TPayload = Wrap<BuyBenefitPayload, 'studentBenefit'>
type TData = Wrap<BuyBenefitData, 'data'>

const Main = ({ balance, data, onAction }: Props) => {
  const { show } = useMessageStore()
  const { handle } = useErrorHandler()
  const { show: showToast } = useToastStore()

  const [buyBenefit, { loading }] = useMutation<TPayload, TData>(BUY_BENEFIT, {
    onCompleted: ({ studentBenefit }) => {
      onAction()
      showToast({
        title: 'Benefit bol úspešne zakúpený',
        message: studentBenefit?.benefit?.name,
        type: 'success',
      })
    },
  })

  const handleSubmit = useCallback(() => {
    buyBenefit({
      variables: { data: { id: data.id } },
      refetchQueries: [
        {
          query: GET_ME,
          variables: { data: { id: '' } },
        },
        {
          query: GET_MY_BENEFITS,
          variables: { data: { id: '' } },
        },
      ],
    })
      .then(() => show('NEW_BENEFIT'))
      .catch(handle())
  }, [buyBenefit, data, show, handle])

  return (
    <Modal.Wrapper indented>
      <Modal.Title>Želáš si kúpiť tento benefit?</Modal.Title>

      <Box as="section" gap="xs" largePhone={{ gap: 'sm' }}>
        <Box
          gap="xs"
          flexDirection="row"
          alignItems="center"
          paddingBottom="xs"
          largePhone={{ gap: 'sm', pb: 'sm' }}
          css={S.border}
        >
          <motion.div layoutId="buyBenefitImage" transition={{ duration: 0.3 }}>
            <PlaceImage
              src={getImage(data.image, 'thumbnail')}
              placeholder={getImage(data.image, 'placeholder')}
              alt={data.name}
              defW={84}
              defH={100}
              maxw={84}
              ofit="cover"
              opos="top"
              ratio="84 / 100"
            />
          </motion.div>

          <Box
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            gap="sm"
            flex={1}
          >
            <Text
              as="h3"
              variant="heading5A"
              color="text"
              largePhone={{ fontSize: 20, lineHeight: 24 }}
              tablet={{ fontSize: 24, lineHeight: 29 }}
            >
              {data.name}
            </Text>

            <Box flexDirection="row" alignItems="center" gap="3xs">
              <Text
                variant="heading6B"
                color="primary500"
                largePhone={{ fontSize: 18, lineHeight: 31 }}
                tablet={{ fontSize: 20, lineHeight: 34 }}
              >
                {data.price}
              </Text>

              <Icon name="coin" width={19} height={19} title="price" />
            </Box>
          </Box>
        </Box>

        <Box
          flex={1}
          gap="sm"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          paddingBottom="xxs"
          largePhone={{ pv: 'xxs' }}
        >
          <Text
            color="text"
            variant="paragraphMB"
            largePhone={{ fontSize: 16, lineHeight: 19 }}
          >
            Po tomto nákupe ti ostane:
          </Text>

          <Box flexDirection="row" alignItems="center" gap="3xs">
            <Text
              variant="heading6B"
              color="primary500"
              largePhone={{ fontSize: 18, lineHeight: 31 }}
              tablet={{ fontSize: 20, lineHeight: 34 }}
            >
              {balance}
            </Text>

            <Icon name="coin" width={19} height={19} title="price" />
          </Box>
        </Box>
      </Box>

      <Modal.Actions close>
        <motion.div layoutId="buyBenefitButton" transition={{ duration: 0.3 }}>
          <Button
            onClick={handleSubmit}
            isLoading={loading}
            disabled={loading || balance < 0}
          >
            Kúpiť benefit
          </Button>
        </motion.div>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export default React.memo(Main)
