import React, { ComponentProps, useMemo } from 'react'
import Primitive from 'react-loading-skeleton'
import { useTheme } from 'styled-components'
import { lighten } from 'polished'

import { Box } from 'ui/core'
import { TThemeColor } from 'styles/theme'

type Props = {
  color?: TThemeColor | Omit<string, TThemeColor>
} & Omit<ComponentProps<typeof Box>, 'color'> &
  ComponentProps<typeof Primitive>

const Skeleton = ({ color = 'text', ...props }: Props) => {
  const theme = useTheme()

  const themeColor = useMemo(
    () => theme.colors?.[color as TThemeColor] || color,
    [theme, color]
  )

  return (
    <Box<typeof Primitive>
      as={Primitive}
      baseColor={lighten(0.1, themeColor)}
      highlightColor={lighten(0.2, themeColor)}
      display="inline-flex"
      {...props}
    />
  )
}

export default React.memo(Skeleton)
