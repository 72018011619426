import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Formik, FormikHelpers } from 'formik'
import { FetchResult, useMutation, useQuery } from '@apollo/client'

import { Box, Button, Error, Head, Icon, Input, Text } from 'ui'
import { Card } from 'components/layout'
import { useAuth, useErrorHandler } from 'hooks'

import validationSchema, { ChangePasswordData } from 'schemas/changePassword'
import { ChangePasswordPayload, CHANGE_PASSWORD } from 'apollo/mutations'
import {
  ByResetTokenData,
  ByResetTokenPayload,
  GET_BY_RESET_TOKEN,
} from 'apollo/queries'

type Params = {
  token: string
}

type TQPayload = Wrap<ByResetTokenPayload, 'user'>
type TQData = Wrap<ByResetTokenData, 'data'>

type TMPayload = Wrap<ChangePasswordPayload, 'changePassword'>
type TMData = Wrap<ChangePasswordData, 'data'>

const ChangePassword = () => {
  const navigate = useNavigate()
  const { token } = useParams<Params>()

  const { onLogin } = useAuth()
  const { handle, error } = useErrorHandler()

  const [signin, setSignin] = useState<
    Partial<Pick<ChangePasswordPayload, 'accessToken' | 'refreshToken'>>
  >({ accessToken: undefined, refreshToken: undefined })

  useQuery<TQPayload, TQData>(GET_BY_RESET_TOKEN, {
    variables: { data: { token: token || '' } },
    onError: () => navigate('/reset-password', { replace: true }),
  })

  const initialValues = useMemo(
    (): ChangePasswordData => ({
      token,
      password: '',
      passwordConfirmation: '',
    }),
    [token]
  )

  const [changePassword, { loading }] = useMutation<TMPayload, TMData>(
    CHANGE_PASSWORD
  )

  const onSubmit = useCallback(
    (
      values: ChangePasswordData,
      formikHelpers: FormikHelpers<ChangePasswordData>
    ) => {
      changePassword({ variables: { data: values } })
        .then(({ data: user }: FetchResult<TMPayload>) =>
          setSignin({
            accessToken: user?.changePassword?.accessToken,
            refreshToken: user?.changePassword?.refreshToken,
          })
        )
        .catch(handle(formikHelpers))
    },
    [changePassword, handle, setSignin]
  )

  return (
    <>
      <Head title="Zmena hesla" />

      <Card title="Zmena hesla">
        {signin !== undefined ? (
          <Box gap="l" alignItems="center">
            <Icon name="tick" width={55} height={47} />

            <Text textAlign="center" variant="heading4A" color="secondary500">
              Zmena hesla prebehla úspešne!
            </Text>

            <Button
              onClick={() => onLogin(signin.refreshToken, signin.accessToken)}
            >
              Prihlásiť sa
            </Button>
          </Box>
        ) : (
          <Formik
            {...{ initialValues, validationSchema, onSubmit }}
            validateOnChange
            enableReinitialize
          >
            {({ errors, touched }) => (
              <Form>
                <Input
                  type="password"
                  name="password"
                  label="Heslo"
                  hasError={touched.password && errors.password}
                  disabled={loading}
                  autoComplete="new-password"
                  required
                />

                <Input
                  type="password"
                  name="passwordConfirmation"
                  label="Heslo znovu"
                  hasError={
                    touched.passwordConfirmation && errors.passwordConfirmation
                  }
                  disabled={loading}
                  autoComplete="new-password"
                  required
                />

                <Error message={error} />

                <Button type="submit" disabled={loading} isLoading={loading}>
                  Zmeniť
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Card>
    </>
  )
}

export default React.memo(ChangePassword)
