import { object, string, InferType } from 'yup'

const schema = object({
  studentId: string().required(),
  benefitId: string().required(),
  classId: string().required(),
  subject: string().required(),
}).required()

export type UseBenefitData = InferType<typeof schema>

export default schema
