import { InferType, object, string } from 'yup'

const schema = object({
  email: string()
    .required('Prosím vyplňte emailovú adresu')
    .email('Nesprávny formát emailovej adresy'),
}).required()

export type ResetPasswordData = InferType<typeof schema>

export default schema
