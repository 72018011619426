import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import { Action, Box, Head } from 'ui'

import { Content } from 'components/layout'
import { TeacherHeader } from 'components/headers'

import { GET_TEACHER, StudentData, StudentPayload } from 'apollo/queries'

type Params = {
  id: ID
}

type TPayload = Wrap<StudentPayload, 'teacher'>
type TData = Wrap<StudentData, 'data'>

const Teacher = () => {
  const navigate = useNavigate()

  const { id } = useParams<Params>()

  const { data } = useQuery<TPayload, TData>(GET_TEACHER, {
    variables: { data: { id } },
    onError: () => navigate(-1),
  })

  const goBack = useCallback(() => navigate('/teachers'), [navigate])

  return (
    <>
      <Head title="Učiteľ" />

      <Box flexDirection="row" flex={1} justifyContent="space-between">
        <Action
          IconProps={{ name: 'arrow', title: 'Späť' }}
          onClick={goBack}
          title="Späť"
        />
      </Box>

      <TeacherHeader data={data?.teacher} />

      <Content withTabs>
        <Content.Header>
          <Content.Tab path={`/teachers/${id}`}>Triedy</Content.Tab>
          <Content.Tab path={`/teachers/${id}/subjects`}>Predmety</Content.Tab>
        </Content.Header>
      </Content>
    </>
  )
}

export default React.memo(Teacher)
