import React, { useCallback } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'

import { Button, Error, Input } from 'ui'
import { useErrorHandler } from 'hooks'
import useToastStore from 'stores/toast'

import Modal, { useModal } from 'components/Modal/Modal'

import { GET_TEACHERS, TeachersPayload } from 'apollo/queries'
import { AddTeacherPayload, ADD_TEACHER } from 'apollo/mutations'
import validationSchema, { AddTeacherData } from 'schemas/addTeacher'

type TPayload = Wrap<AddTeacherPayload, 'teacher'>
type TData = Wrap<AddTeacherData, 'data'>

const initialValues: AddTeacherData = {
  name: '',
  surname: '',
  email: '',
}

const AddTeacher = () => {
  const { hide } = useModal()
  const { handle, error } = useErrorHandler()
  const { show } = useToastStore()

  const [addTeacher, { loading }] = useMutation<TPayload, TData>(ADD_TEACHER, {
    onCompleted: ({ teacher }) =>
      show({
        title: 'Učiteľ bol úspešne vytvorený',
        message: `${teacher?.name} ${teacher?.surname}`,
        type: 'success',
      }),
  })

  const onSubmit = useCallback(
    (values: AddTeacherData, formikHelpers: FormikHelpers<AddTeacherData>) => {
      addTeacher({
        variables: { data: values },
        update: (cache, { data: response }) => {
          cache.updateQuery(
            {
              query: GET_TEACHERS,
              variables: { data: {} },
            },
            (data?: Wrap<TeachersPayload, 'teachers'>) => {
              if (data?.teachers) {
                return { teachers: [response!.teacher, ...data.teachers] }
              }
            }
          )
        },
      })
        .then(hide)
        .catch(handle(formikHelpers))
    },
    [addTeacher, hide, handle]
  )

  return (
    <Modal.Wrapper indented condensed>
      <Modal.Title>Pridať učiteľa</Modal.Title>

      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        validateOnChange
      >
        {({ errors, touched }) => (
          <Form>
            <Input
              type="text"
              name="name"
              label="Meno"
              hasError={touched.name && errors.name}
              disabled={loading}
            />

            <Input
              type="text"
              name="surname"
              label="Priezvisko"
              hasError={touched.surname && errors.surname}
              disabled={loading}
            />

            <Input
              type="email"
              name="email"
              label="Email"
              hasError={touched.email && errors.email}
              disabled={loading}
            />

            <Error message={error} />

            <Modal.Actions inForm>
              <Button type="submit" isLoading={loading} disabled={loading}>
                Pridať
              </Button>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal.Wrapper>
  )
}

export default React.memo(AddTeacher)
