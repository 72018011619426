import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { useMutation, useQuery } from '@apollo/client'

import { Avatar, Box, Button, ImageIcon, Text } from 'ui'
import { useAuth } from 'hooks'
import { ForRoles } from 'ui/auth'
import { getRefreshToken } from 'utils/cookies'
import useMessageStore from 'stores/message'

import Modal from 'components/Modal'
import Popover from 'components/Popover'
import ProfileModal from 'components/modals/Profile'

import { GET_ME, MePayload } from 'apollo/queries'
import { LOGOUT, LogoutData, LogoutPayload } from 'apollo/mutations'

import * as S from './styled'

type TPayload = Wrap<MePayload, 'me'>

const Profile = () => {
  const navigate = useNavigate()
  const { onLogout } = useAuth()

  const { show } = useMessageStore()

  const { data } = useQuery<TPayload>(GET_ME, {
    onCompleted: (data) => {
      if (data.me?.points === 0 && data?.me?.level === 0) {
        show('START', () => navigate('/tasks'))
      }
    },
  })

  const [logout, { loading }] = useMutation<LogoutPayload, LogoutData>(LOGOUT)

  const handleLogout = useCallback(() => {
    const refreshToken = getRefreshToken() || ''
    logout({ variables: { refreshToken } }).then(onLogout)
  }, [logout, onLogout])

  return (
    <Popover>
      <Popover.Trigger css={S.trigger}>
        <Box flexDirection="row" alignItems="center" gap="ss">
          {/* FOR STUDENT */}
          <ForRoles roles="STUDENT">
            <Box row alignItems="center" gap="3xs">
              <Text variant="heading4B" color="light">
                {data?.me?.coins || 0}
              </Text>

              <ImageIcon name="coin" defW={19} defH={19} alt="peniaze" />
            </Box>
          </ForRoles>
          {/* END FOR STUDENT */}

          <Avatar src={data?.me?.avatar?.image} alt={data?.me?.avatar?.name} />
        </Box>
      </Popover.Trigger>

      <Popover.Content>
        <Modal>
          <Modal.Trigger disabled={!data?.me}>
            <Button as="div" variant="grey" disabled={!data?.me} inverted>
              Profil
            </Button>
          </Modal.Trigger>

          <Modal.Content extended max={420}>
            <ProfileModal data={data?.me} />
          </Modal.Content>
        </Modal>

        <Button disabled={loading} isLoading={loading} onClick={handleLogout}>
          Odhlásiť sa
        </Button>
      </Popover.Content>
    </Popover>
  )
}

export default React.memo(Profile)
