import { gql } from '@apollo/client'

import { Benefit } from 'types/models'

export type MyBenefitsData = {
  id?: ID
}

export type MyBenefitsPayload = ({
  count: number
} & Benefit)[]

export default gql`
  query getMyBenefits($data: OptidData) {
    myBenefits: getMyBenefits(data: $data) {
      id
      name
      image
      count
    }
  }
`
