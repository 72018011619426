import React from 'react'

import { Text } from 'ui'

import * as S from './styled'

export type GridProps = {
  inline?: boolean
  children: React.ReactNode
}

const Grid = ({ children, ...props }: GridProps) => (
  <S.Wrapper {...props}>{children}</S.Wrapper>
)

type SeparatorProps = {
  children: React.ReactNode
}

const Separator = ({ children }: SeparatorProps) => (
  <Text
    color="light"
    variant="heading3B"
    paddingBottom="xs"
    largePhone={{ fontSize: 28, lineHeight: 48, pb: 'm' }}
    tablet={{ fontSize: 36, lineHeight: 62, pb: 'l' }}
    uppercase
  >
    {children}
  </Text>
)

Grid.Separator = Separator

export default Grid
