import { gql } from '@apollo/client'

import { ResetPassword } from 'types/models'

export type ResetPasswordPayload = ResetPassword

export default gql`
  mutation resetPassword($data: ResetPasswordData!) {
    resetPassword(data: $data) {
      user {
        email
      }
    }
  }
`
