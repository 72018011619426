import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'

import { Button, Image, Text } from 'ui'

import Modal from 'components/Modal/Modal'

const Success = () => {
  const navigate = useNavigate()

  const toActualBenefits = useCallback(
    () => navigate('/actual-benefits'),
    [navigate]
  )

  return (
    <Modal.Wrapper indented alignItems="center">
      <Image
        src="/images/duck@fallback.png"
        draggable="false"
        width={175}
        height={184}
      />

      <Text
        color="text"
        textAlign="center"
        variant="paragraphMB"
        largePhone={{ fontSize: 16, lineHeight: 19 }}
      >
        Kúpil si si benefit, výborne!
        <br />
        Galériu aktuálnych benefitov môžeš nájsť vo svojom lete.
      </Text>

      <Modal.Actions>
        <Button onClick={toActualBenefits}>Môj let</Button>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export default React.memo(Success)
