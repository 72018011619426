import { gql } from '@apollo/client'

import { UsedBenefit } from 'types/models'

export type UseBenefitPayload = UsedBenefit

export default gql`
  mutation useBenefit($data: UseBenefitData!) {
    usedBenefit: useBenefit(data: $data) {
      id
      createdAt
      benefit {
        id
        benefit {
          id
          name
          image
        }
        student {
          id
          totalTasks
          totalBenefits
        }
      }
      teacher {
        id
        name
        surname
        avatar {
          id
          name
          image
        }
      }
      class {
        id
      }
      subject {
        id
        name
      }
    }
  }
`
