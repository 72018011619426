import { object, string, InferType } from 'yup'

const schema = object({
  name: string().required('Prosím vyplňte meno'),
  surname: string().required('Prosím vyplňte priezvisko'),
  email: string()
    .required('Prosím vyplňte emailovú adresu')
    .email('Nesprávny formát emailovej adresy'),
}).required()

export type AddTeacherData = InferType<typeof schema>

export default schema
