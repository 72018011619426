import React from 'react'
import { useQuery } from '@apollo/client'

import Loading from 'ui/Loading'

import { useAuthStore } from 'stores/auth'
import { GET_ME, MePayload } from 'apollo/queries'

type Props = {
  children?: React.ReactNode
  yes?: React.ComponentType
  no?: React.ComponentType
}

type TPayload = Wrap<MePayload, 'me'>

const ProtectRoute = ({
  children,
  yes: Yes = () => <>{children}</>,
  no: No = () => null,
}: Props) => {
  const setUser = useAuthStore<'setUser'>((state) => state.setUser)
  const user = useAuthStore<'user'>((state) => state.user)

  const { data, error, loading } = useQuery<TPayload>(GET_ME, {
    onCompleted: (data) => setUser(data?.me),
    onError: () => setUser(undefined),
  })

  if (loading) return <Loading />

  return !!data && !error && user ? <Yes /> : <No />
}

export default ProtectRoute
