import React from 'react'

import * as S from './styled'

type Props = {
  onResult: (value: string) => void
}

const QrScanner = ({ onResult }: Props) => (
  <S.View
    onResult={(result) => {
      if (result) onResult(result.getText())
    }}
    constraints={{ facingMode: 'front', aspectRatio: 1 }}
  />
)

export default React.memo(QrScanner)
