import { object, string, InferType } from 'yup'

const schema = object({
  email: string()
    .required('Prosím vyplňte emailovú adresu')
    .email('Nesprávny formát emailovej adresy'),
  password: string()
    .min(6, 'Heslo musí mať najmenej 6 znakov')
    .required('Prosím vyplňte heslo'),
}).required()

export type SigninData = InferType<typeof schema>

export default schema
