import React from 'react'
import { createRoot } from 'react-dom/client'

import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import App from './App'
import client from 'apollo'

import theme from 'styles/theme'
import GlobalStyles from 'styles/global'

import { Toast } from 'components'

const container = document.getElementById('root')
const root = createRoot(container!)

import 'react-loading-skeleton/dist/skeleton.css'

root.render(
  <ApolloProvider {...{ client }}>
    <ThemeProvider {...{ theme }}>
      <BrowserRouter>
        <App />
        <Toast />
      </BrowserRouter>

      <GlobalStyles />
    </ThemeProvider>
  </ApolloProvider>
)
