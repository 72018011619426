import React from 'react'

import { Modal } from 'components'
import { Card } from 'components/items'
import { UseMyBenefitModal } from 'components/modals'

import { MyBenefitsPayload } from 'apollo/queries'

type Props = {
  classId?: ID
  studentId?: ID
  data: MyBenefitsPayload[number]
}

const MyBenefit = ({ data, classId, studentId }: Props) => (
  <Modal>
    {/* TRIGGER */}
    <Modal.Trigger>
      <Card
        sharp
        title={data.name}
        image={data.image}
        color="primary600"
        space={data?.count > 1}
      >
        {data?.count > 1 && <Card.Count text={data.count} />}
      </Card>
    </Modal.Trigger>
    {/* END TRIGGER */}

    {/* MODAL */}
    <Modal.Content extended>
      <UseMyBenefitModal {...{ data, classId, studentId }} />
    </Modal.Content>
    {/* END MODAL */}
  </Modal>
)

export default React.memo(MyBenefit)
