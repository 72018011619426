import React, { useCallback } from 'react'
import { useQuery } from '@apollo/client'

import { Box, Text, Button, Search, Icon, QrScanner } from 'ui'
import { SelectItem } from 'components/items'
import { useToggle } from 'hooks'
import { getImage } from 'utils/files'
import useToastStore from 'stores/toast'

import Modal from 'components/Modal/Modal'

import {
  GET_STUDENT_TASKS,
  StudentTasksData,
  StudentTasksPayload,
} from 'apollo/queries'

type TPayload = Wrap<StudentTasksPayload, 'tasks'>
type TData = Wrap<StudentTasksData, 'data'>

type ActiveTask = StudentTasksPayload[number]

type Props = {
  active: ActiveTask | null
  setActive: (value: ActiveTask | null, keep?: boolean) => void
  onAction: () => void
}

const Initial = ({ active, setActive, onAction }: Props) => {
  const { show } = useToastStore()
  const { visible: scanner, toggle, hide } = useToggle()

  const { data, refetch } = useQuery<TPayload, TData>(GET_STUDENT_TASKS, {
    variables: { data: {} },
  })

  const handleSearch = useCallback(
    (query: string) => {
      setActive(null)
      refetch({ data: { query } })
    },
    [setActive, refetch]
  )

  const handleScanResult = useCallback(
    (value: string) => {
      refetch({ data: {} })
        .then(({ data }) => {
          const found = data?.tasks?.find((task) => task.code == value)
          if (!found) throw Error('NOT_FOUND')
          return found || null
        })
        .then((found) => setActive(found, true))
        .then(onAction)
        .catch(() => {
          hide()
          show({
            title: 'Úloha sa nenašla',
            type: 'warning',
          })
        })
    },
    [setActive, onAction, refetch, hide, show]
  )

  const renderItems = useCallback(
    (item: StudentTasksPayload[number]) => (
      <SelectItem
        key={`task-${item.id}`}
        title={item.name}
        subtitle="Počet bodov"
        value={item.points}
        image={{
          src: getImage(item.image, 'empty'),
          placeholder: getImage(item.image, 'placeholder'),
          fallback: '/assets/images/duck@fallback.png',
        }}
        active={item.id === active?.id}
        onClick={() => setActive(item)}
      >
        <SelectItem.Footer>
          <Box flexDirection="row" alignItems="center" gap="3xs">
            <Text
              variant="heading6B"
              color={item.id === active?.id ? 'light' : 'primary500'}
              largePhone={{ fontSize: 18, lineHeight: 31 }}
            >
              {item.coins}
            </Text>

            <Icon name="coin" width={19} height={19} title="coins" />
          </Box>
        </SelectItem.Footer>
      </SelectItem>
    ),
    [active, setActive]
  )

  return (
    <Modal.Wrapper indented condensed onClick={(e) => e.preventDefault()}>
      <Modal.Title>Pridať úlohu</Modal.Title>

      <Box as="section" gap="3xs">
        <Box flexDirection="row" justifyContent="space-between">
          {/* LABEL */}
          <Text<'label'> as="label" htmlFor="title" variant="paragraphMB">
            {scanner ? 'Naskenujte QR kód' : 'Názov úlohy'}
          </Text>
          {/* END LABEL */}

          {/* SCAN */}
          <Text<'button'>
            as="button"
            onClick={toggle}
            variant="anchorB"
            color="primary500"
            underline
          >
            {scanner ? 'Vyhľadať' : 'Naskenovať QR kód'}
          </Text>
          {/* END SCAN */}
        </Box>

        {!scanner && (
          <Search
            id="title"
            placeholder="Názov"
            callback={handleSearch}
            fullWidth
          />
        )}
      </Box>

      {scanner ? (
        <QrScanner onResult={handleScanResult} />
      ) : (
        <>
          {/* TASKS LIST */}
          <Box maxHeight={395} overflow="scroll" radius="input">
            <Box mb="s" gap="s">
              {data?.tasks?.map(renderItems)}
            </Box>
          </Box>
          {/* END TASKS LIST */}

          <Modal.Actions>
            <Button disabled={!active} onClick={onAction}>
              Pridať
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal.Wrapper>
  )
}

export default React.memo(Initial)
