import React from 'react'
import { Props as SVGProps } from 'react-inlinesvg'
import { FlattenInterpolation, ThemeProps } from 'styled-components'

import { TTheme } from 'styles/theme'

import * as S from './styled'

type Props = {
  name: string
  css?: FlattenInterpolation<ThemeProps<TTheme>>
} & Omit<SVGProps, 'src'>

const Icon = ({ name, css, ...props }: Props) => (
  <S.Wrapper src={`/assets/icons/${name}.svg`} $css={css} {...props} />
)

export default React.memo(Icon)
