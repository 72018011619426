import { thisYear } from 'utils/date'
import { InferType, number, object, string } from 'yup'

const schema = object({
  classId: string().required(),
  name: string().required('Prosím vyplňte meno'),
  surname: string().required('Prosím vyplňte priezvisko'),
  birthYear: number()
    .min(1900, 'Rok narodenia musí byť väčší ako 1900')
    .max(thisYear(), 'Rok narodenia musí byť menší ako aktuálny rok')
    .required('Prosím vyplňte rok narodenia'),
  email: string()
    .required('Prosím vyplňte emailovú adresu')
    .email('Nesprávny formát emailovej adresy'),
}).required()

export type CreateStudentData = InferType<typeof schema>

export default schema
