import { DateTime } from 'luxon'

const FORMAT = 'dd.MM.yyyy'

export const thisYear = () => DateTime.now().year

export const toDateFormat = (strDate: string) =>
  DateTime.fromISO(strDate).toFormat(FORMAT)

export const equalDates = (first: string, second: string) =>
  toDateFormat(first) === toDateFormat(second)

export const isToday = (strDate: string) =>
  toDateFormat(strDate) === DateTime.now().toFormat(FORMAT)
