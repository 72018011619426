import React, {
  ComponentProps,
  ElementType,
  ForwardedRef,
  HTMLAttributes,
} from 'react'
import { FlattenInterpolation, ThemeProps } from 'styled-components'

import type { TTheme, TThemeBreakpoint } from 'styles/theme'
import type { TDisplayProps, TSizeProps, TSpacingProps } from '../types'
import type {
  TTextDecorationProps,
  TTextTranformProps,
  TTextVisualProps,
} from './props'

import * as S from './styled'

type StyleProps = TSpacingProps &
  TSizeProps &
  TTextVisualProps &
  TTextTranformProps &
  TTextDecorationProps &
  TDisplayProps

type ResponsiveProps = PartialRecord<TThemeBreakpoint, StyleProps>

export type Props = {
  as?: ElementType
  css?: FlattenInterpolation<ThemeProps<TTheme>>
  children?: React.ReactNode
} & StyleProps &
  ResponsiveProps

export type TTextProps = HTMLAttributes<HTMLParagraphElement> & Props

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/ban-types
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

const Text = <T extends ElementType = 'p'>(
  { variant = 'paragraph', children, ...props }: TTextProps & ComponentProps<T>,
  forwardedRef?: ForwardedRef<any>
) => (
  <S.Wrapper ref={forwardedRef} {...{ variant, ...props }}>
    {children}
  </S.Wrapper>
)

export default React.forwardRef(Text)
