import React, { ComponentProps, createContext, Ref, useContext } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import * as Dialog from '@radix-ui/react-dialog'

import { Box, Button, Icon } from 'ui'
import { useToggle } from 'hooks'

import * as S from './styled'

type ContextProps = {
  visible: boolean
  toggle: () => void
  hide: () => void
}

const defaultProps = {
  visible: false,
  toggle: undefined!,
  hide: undefined!,
} as ContextProps

const ModalContext = createContext<ContextProps>(defaultProps)

type Props = {
  children: React.ReactNode
}

const prevent =
  (fn?: () => void) =>
  <T extends HTMLElement>(e: React.MouseEvent<T>) => {
    e.stopPropagation()
    fn?.()
  }

const Modal = ({ children }: Props) => {
  const { visible, toggle, hide } = useToggle(defaultProps.visible)

  return (
    <ModalContext.Provider value={{ visible, toggle, hide }}>
      <Dialog.Root>{children}</Dialog.Root>
    </ModalContext.Provider>
  )
}

type TriggerProps = {
  disabled?: boolean
  children: React.ReactNode
}

const Trigger = ({ disabled, children }: TriggerProps) => {
  const { toggle } = useContext(ModalContext)

  return (
    <S.Trigger onClick={!disabled ? prevent(toggle) : undefined}>
      {children}
    </S.Trigger>
  )
}

type ContentProps = {
  extended?: boolean
  max?: number
  hideClose?: boolean
  overflow?: boolean
  children: React.ReactNode
}

const MotionContent = motion(S.Content)
const MotionOverlay = motion(S.Overlay)

const Content = React.forwardRef(function DialogPortal(
  { children, extended, max, hideClose, overflow, ...props }: ContentProps,
  forwardedRef: Ref<HTMLDivElement>
) {
  const { hide, visible } = useContext(ModalContext)

  return (
    <AnimatePresence>
      {!visible ? null : (
        <Dialog.Portal forceMount>
          <MotionOverlay
            forceMount
            onClick={prevent(hide)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <MotionContent
              ref={forwardedRef}
              onClick={prevent()}
              initial={{ opacity: 0, y: '2%' }}
              animate={{ opacity: 1, y: '0%' }}
              exit={{ opacity: 0, y: '-2%' }}
              $extended={extended}
              $max={max}
              transition={{
                type: 'spring',
                stiffness: 300,
                damping: 30,
              }}
            >
              <S.Wrapper {...props} $overflow={overflow} onClick={prevent()}>
                {children}
              </S.Wrapper>

              {!hideClose && (
                <S.Close aria-label="close" onClick={prevent(hide)}>
                  <Icon name="cross" width={22} height={22} title="close" />
                </S.Close>
              )}
            </MotionContent>
          </MotionOverlay>
        </Dialog.Portal>
      )}
    </AnimatePresence>
  )
})

type WrapperProps = {
  indented?: boolean
  condensed?: boolean
  children: React.ReactNode
} & ComponentProps<typeof Box>

const Wrapper = ({ condensed, indented, children, ...props }: WrapperProps) => (
  <Box
    radius="card"
    overflow="hidden"
    gap={condensed ? 'xs' : 's'}
    ph={indented ? 'xs' : undefined}
    pv={indented ? 'm' : undefined}
    largePhone={{
      gap: condensed ? 'sm' : 'l',
      ph: indented ? 'sm' : undefined,
      pv: indented ? 'l' : undefined,
    }}
    {...props}
  >
    {children}
  </Box>
)

type TitleProps = {
  left?: boolean
  goBack?: () => void
  children: React.ReactNode
}

const Title = ({ left, goBack, children }: TitleProps) => (
  <Box as="header" flexDirection="row" justifyContent="center">
    {goBack && (
      <Box<'button'> as="button" onClick={goBack} ph="ss" ml={-10} mr={-10}>
        <Icon name="arrow" width={12} height={20} title="Späť" css={S.icon} />
      </Box>
    )}

    <S.Title $left={left}>{children}</S.Title>
  </Box>
)

type ActionsProps = {
  right?: boolean
  close?: boolean
  floating?: boolean
  inForm?: boolean
  children: React.ReactNode
}

const Actions = ({
  close,
  floating,
  inForm,
  right,
  children,
}: ActionsProps) => {
  const { hide } = useContext(ModalContext)

  return (
    <Box
      as="footer"
      gap="sm"
      flexDirection="row"
      alignItems="center"
      justifyContent={right ? 'flex-end' : 'center'}
      mt={inForm ? 's' : undefined}
      css={S.position}
      onClick={prevent()}
      {...{ floating }}
    >
      {close && (
        <Dialog.Close aria-label="close" onClick={prevent(hide)}>
          <Button as="div" variant="grey">
            Zrušiť
          </Button>
        </Dialog.Close>
      )}

      {children}
    </Box>
  )
}

export const useModal = () => useContext(ModalContext)

Modal.Trigger = Trigger
Modal.Content = Content
Modal.Wrapper = Wrapper
Modal.Title = Title
Modal.Actions = Actions

export default Modal
