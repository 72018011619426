import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import { Button } from 'ui'
import { Content, Grid } from 'components/layout'
import { MyBenefit } from 'pages/sections'
import { ForRoles } from 'ui/auth'

import {
  GET_MY_BENEFITS,
  MyBenefitsData,
  MyBenefitsPayload,
} from 'apollo/queries'

type Params = {
  id?: ID
  studentId?: ID
}

type TPayload = Wrap<MyBenefitsPayload, 'myBenefits'>
type TData = Wrap<MyBenefitsData, 'data'>

const ActualBenefits = () => {
  const navigate = useNavigate()
  const { id, studentId } = useParams<Params>()

  const { data, loading } = useQuery<TPayload, TData>(GET_MY_BENEFITS, {
    variables: { data: { id: studentId || '' } },
    onError: () => {
      if (studentId) navigate(`/${id || ''}`, { replace: true })
    },
  })

  const renderItems = useCallback(
    (item: MyBenefitsPayload[number]) => (
      <MyBenefit
        key={`my-benefit-${item.id}`}
        data={item}
        classId={id}
        {...{ studentId }}
      />
    ),
    [id, studentId]
  )

  if (!data?.myBenefits?.length) {
    return loading ? (
      <Content.Loading />
    ) : (
      <Content.Empty text="Nenašli sa žiadne benefity">
        <ForRoles roles={['STUDENT']}>
          <Button onClick={() => navigate('/benefits')}>
            Galéria benefitov
          </Button>
        </ForRoles>
      </Content.Empty>
    )
  }

  return <Grid>{data?.myBenefits?.map(renderItems)}</Grid>
}

export default React.memo(ActualBenefits)
