import { css } from 'styled-components'

const common = css`
  position: fixed;
  user-select: none;
  z-index: ${({ theme }) => theme.zIndices.base};

  #back {
    fill: ${({ theme }) => theme.colors.fg};
  }
`

export const left = css`
  ${common}

  transform: rotate(45deg);

  left: -25px;
  top: 64%;
`

export const right = css`
  ${common}

  transform: rotate(-30deg);

  right: -45px;
  top: 10%;
`

export const fg = css`
  ${common}

  transform: rotate(31deg);

  left: 18%;
  top: 8%;
`

export const bg = css`
  ${common}

  inset: 0;
  top: auto;

  width: 100vw;
  height: auto;
  max-height: 850px;
`
