import { gql } from '@apollo/client'

import { Avatar } from 'types/models'

export type DucksPayload = Avatar[]

export default gql`
  query getDucks {
    ducks: getDucks {
      id
      name
      level
      image
    }
  }
`
