import React from 'react'
import { Outlet } from 'react-router'

import { Box, Container, Icon, Logo } from 'ui'

import * as S from './styled'

const Auth = () => (
  <Box position="relative" overflow="hidden" minHeight="100vh" bg="lightBgG">
    <Icon name="bg-left" width={137} height={192} css={S.left} />
    <Icon name="bg-right" width={164} height={230} css={S.right} />

    <Container
      zIndex="front"
      alignItems="center"
      pv="3xl"
      gap="3xl"
      tablet={{ gap: '6xl', pv: '6xl' }}
    >
      <Logo />

      <Box as="main" gap="l" width="100%" maxw={590}>
        <Outlet />
      </Box>
    </Container>
  </Box>
)

export default React.memo(Auth)
