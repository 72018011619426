import styled from 'styled-components'

import { QrReader } from 'react-qr-reader'

export const View = styled(QrReader)`
  position: relative;
  overflow: hidden;

  border-radius: ${({ theme }) => theme.borderRadii.input};
  background-color: ${({ theme }) => theme.colors.grey100};

  &::before {
    content: 'Spúšťanie kamery, prosím počkajte...';
    text-align: center;

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    padding: ${({ theme }) => theme.sizes.ss};
  }
`

export const Finder = styled.div`
  z-index: 1;

  position: absolute;
  inset: 0;
`
