import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import { Box, Button, Search } from 'ui'
import { Modal } from 'components'

import { AssignClassModal } from 'components/modals'
import { Content } from 'components/layout'
import { MyClass } from 'pages/sections'

import {
  GET_TEACHER_CLASSES,
  TeacherClassesData,
  TeacherClassesPayload,
} from 'apollo/queries'

type Params = {
  id: ID
}

type TPayload = Wrap<TeacherClassesPayload, 'classes'>
type TData = Wrap<TeacherClassesData, 'data'>

const Classes = () => {
  const navigate = useNavigate()
  const { id } = useParams<Params>()

  const { data, refetch, loading } = useQuery<TPayload, TData>(
    GET_TEACHER_CLASSES,
    {
      variables: { data: { id: id || '' } },
      onError: () => navigate('/teachers'),
    }
  )

  const handleSearch = useCallback(
    (query: string) => refetch({ data: { id: id || '', query } }),
    [refetch, id]
  )

  const renderItems = useCallback(
    (item: TeacherClassesPayload[number]) => (
      <MyClass key={`class-${item.id}`} data={item} teacherId={id} />
    ),
    [id]
  )

  return (
    <>
      <Box
        justifyContent="space-between"
        flexDirection="column-reverse"
        alignItems="flex-end"
        flex={1}
        mb="xs"
        gap="xs"
        largePhone={{ mb: 'm' }}
        tablet={{
          flexDirection: 'row',
          gap: 'm',
          alignItems: 'center',
        }}
      >
        <Search placeholder="Zadajte názov triedy" callback={handleSearch} />

        <Modal>
          <Modal.Trigger disabled={!id}>
            <Button as="div" disabled={!id}>
              Priradiť triedu
            </Button>
          </Modal.Trigger>

          <Modal.Content extended>
            {id && <AssignClassModal teacherId={id} />}
          </Modal.Content>
        </Modal>
      </Box>

      {data?.classes?.length ? (
        <Box gap="xs" largePhone={{ gap: 'm' }}>
          {data?.classes?.map(renderItems)}
        </Box>
      ) : loading ? (
        <Content.Loading />
      ) : (
        <Content.Empty text="Nenašli sa žiadne triedy učiteľa" />
      )}
    </>
  )
}

export default React.memo(Classes)
