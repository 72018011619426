import React, { InputHTMLAttributes, useCallback } from 'react'
import { ErrorMessage, FieldAttributes, useField } from 'formik'
import useMeasure from 'react-use-measure'

import { Box, Text } from 'ui/core'

import * as S from './styled'

type Props = {
  min: number
  defaultValue: number
  max: number
  label?: string
  hasError: boolean
} & FieldAttributes<any> &
  InputHTMLAttributes<HTMLInputElement>

const Slider = ({
  label,
  hasError,
  defaultValue,
  disabled,
  ...props
}: Props) => {
  const [ref, bounds] = useMeasure()

  const [{ name, onBlur }, { value }, { setValue }] = useField(props)

  const handleChange = useCallback(
    (values: number[]) => setValue(values?.[0]),
    [setValue]
  )

  return (
    <Box w="100%" gap="s">
      {!!label && (
        <Text<'label'>
          as="label"
          htmlFor={name}
          variant="paragraphLB"
          color="grey800"
        >
          {label}
        </Text>
      )}

      <S.Root
        id={name}
        aria-label={name}
        value={[value]}
        defaultValue={[defaultValue]}
        onValueChange={handleChange}
        step={1}
        {...{ name, disabled, onBlur, ...props }}
      >
        <S.Track ref={ref} />

        <S.Thumb size={bounds.width} />
      </S.Root>

      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        gap="xs"
      >
        <Box gap="3xs">
          <Text as="span" variant="paragraphLB" color="negative">
            {props.min}
          </Text>

          <Text as="span" variant="paragraphS" color="negative">
            Nesplnil očakávania
          </Text>
        </Box>

        <Text as="span" variant="paragraphLB" color="text">
          {defaultValue}
        </Text>

        <Box gap="3xs" alignItems="flex-end">
          <Text as="span" variant="paragraphLB" color="positive">
            {props.max}
          </Text>

          <Text as="span" variant="paragraphS" color="positive">
            Splnil nad očakávania
          </Text>
        </Box>
      </Box>

      {hasError && (
        <Text as="small" variant="paragraphS" color="primary500">
          <ErrorMessage {...{ name }} />
        </Text>
      )}
    </Box>
  )
}

export default React.memo(Slider)
