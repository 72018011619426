import React, { useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { groupBy } from 'lodash'

import { Box, Head } from 'ui'
import { Message } from 'components'
import { Content, Grid } from 'components/layout'
import { Benefit } from 'pages/sections'

import { ForRoles } from 'ui/auth'
import { Role } from 'types/models'

import { BenefitsPayload, GET_BENEFITS } from 'apollo/queries'
import { MePayload, GET_ME } from 'apollo/queries'

type TPayload = Wrap<BenefitsPayload, 'benefits'>

const Benefits = () => {
  const { data: user } = useQuery<Wrap<MePayload, 'me'>>(GET_ME)
  const { data, loading } = useQuery<TPayload>(GET_BENEFITS)

  const benefitsByLevels = useMemo(
    () => groupBy(data?.benefits, (benefit) => benefit.level),
    [data]
  )

  const renderItems = useCallback(
    (item: BenefitsPayload[number]) => (
      <Benefit
        key={`benefit-${item.id}`}
        balance={(user?.me?.coins || 0) - item.price}
        disableOverlay={user?.me?.role !== Role.STUDENT}
        data={item}
      />
    ),
    [user]
  )

  const renderGroups = useCallback(
    ([level, group]: [string, BenefitsPayload]) => (
      <Box
        as="article"
        key={`benefits-${level}`}
        tablet={{ ph: 'xxl', pv: 'xs' }}
        pb="sm"
      >
        <Grid.Separator>Level {level}</Grid.Separator>
        <Grid inline>{group?.map(renderItems)}</Grid>
      </Box>
    ),
    [renderItems]
  )

  return (
    <>
      <Head title="Galéria benefitov" />

      <ForRoles roles={['STUDENT']}>
        <Message>
          Pre zobrazenie ostatných benefitov vo vyšších leveloch musíš daný
          level dosiahnuť!
        </Message>
      </ForRoles>

      <Content>
        {data?.benefits?.length ? (
          Object.entries(benefitsByLevels)?.map(renderGroups)
        ) : loading ? (
          <Content.Loading />
        ) : user?.me?.level === 0 ? (
          <Content.Empty text="Pre zobrazenie benefitov musíš dosiahnúť prvý level" />
        ) : (
          <Content.Empty text="Žiadne benefity sa nenašli" />
        )}
      </Content>
    </>
  )
}

export default React.memo(Benefits)
