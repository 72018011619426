import { gql } from '@apollo/client'

import { Class } from 'types/models'

export type AssignClassesPayload = Class[]

export default gql`
  mutation assignClasses($data: AssignClassesData!) {
    classes: assignClasses(data: $data) {
      id
      name
      description
      logo
    }
  }
`
