import React from 'react'
import { motion } from 'framer-motion'

import { Box, Button, Icon, PlaceImage } from 'ui'
import { ForRoles } from 'ui/auth'
import { getImage } from 'utils/files'

import Modal from 'components/Modal/Modal'

import { BenefitsPayload } from 'apollo/queries'

type Props = {
  balance: number
  data: BenefitsPayload[number]
  onAction: () => void
}

const Balance = ({ balance }: Pick<Props, 'balance'>) => (
  <Box flexDirection="row" alignItems="center" gap="3xs">
    Chýba {Math.abs(balance)}
    <Icon name="coin" width={19} height={19} title="price" />
  </Box>
)

const Initial = ({ balance, data, onAction }: Props) => (
  <>
    <Modal.Wrapper>
      <motion.div layoutId="buyBenefitImage" transition={{ duration: 0.3 }}>
        <PlaceImage
          src={data.image}
          placeholder={getImage(data.image, 'placeholder')}
          alt={data.name}
          defW={591}
          defH={833}
          w="100%"
          h="auto"
          ratio="591 / 833"
        />
      </motion.div>
    </Modal.Wrapper>

    {/* FOR STUDENT */}
    <ForRoles roles="STUDENT">
      <Modal.Actions floating>
        <motion.div layoutId="buyBenefitButton" transition={{ duration: 0.3 }}>
          <Button onClick={onAction} disabled={balance < 0}>
            {balance < 0 ? <Balance {...{ balance }} /> : 'Kúpiť benefit'}
          </Button>
        </motion.div>
      </Modal.Actions>
    </ForRoles>
    {/* END FOR STUDENT */}
  </>
)

export default React.memo(Initial)
