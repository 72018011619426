import { create } from 'zustand'
import { v4 as uuid } from 'uuid'

export type ToastType = 'info' | 'success' | 'danger' | 'warning'

export type Toast = {
  id?: string
  title: string
  message?: string
  type?: ToastType
  action?: {
    text: string
    onClick: () => void
  }
  duration?: number
}

export type ToastState = {
  toasts: Toast[]
  show: (toast: Toast) => void
  hide: (id: string) => void
  clear: () => void
}

const useToastStore = create<ToastState>()((set, get) => ({
  toasts: [],

  show: (options: Toast) => {
    const { toasts } = get()

    const toast: Toast = {
      id: uuid(),
      duration: 4000,
      type: 'info',
      ...options,
    }

    toasts.push(toast)
    set({ toasts })

    if (toast.duration !== Infinity) {
      setTimeout(() => {
        const current = get().toasts
        set({ toasts: current.filter((t) => t.id !== toast.id) })
      }, toast.duration)
    }
  },

  hide: (id: string) => {
    const { toasts } = get()

    set({ toasts: toasts.filter((t) => t.id !== id) })
  },

  clear: () => set({ toasts: [] }),
}))

export default useToastStore
