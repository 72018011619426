import { gql } from '@apollo/client'

import { Class } from 'types/models'

export type UpsertClassPayload = {
  upload: string
} & Class

export default gql`
  mutation upsertClass($data: UpsertClassData!) {
    class: upsertClass(data: $data) {
      id
      name
      description
      logo
      upload
    }
  }
`
