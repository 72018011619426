import React, { useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'

import { Accordion } from 'components/items'
import { Student } from 'pages/sections'
import { useErrorHandler } from 'hooks'

import {
  ClassStudentData,
  ClassStudentPayload,
  ClassStudentsPayload,
  GET_CLASS_STUDENT,
} from 'apollo/queries'

type Props = {
  classId: ID
  data: ClassStudentsPayload
}

type TPayload = Wrap<ClassStudentPayload, 'student'>
type TData = Wrap<ClassStudentData, 'data'>

const List = ({ classId, data }: Props) => {
  const { handle } = useErrorHandler()

  const [loadStudent, { data: extra }] = useLazyQuery<TPayload, TData>(
    GET_CLASS_STUDENT
  )

  const handleOpen = useCallback(
    (id: ID) => {
      if (id) loadStudent({ variables: { data: { id } } }).catch(handle())
    },
    [loadStudent, handle]
  )

  const renderItems = useCallback(
    (item: ClassStudentsPayload[number]) => (
      <Student
        key={`student-${item.id}`}
        data={item}
        extra={extra?.student}
        classId={classId}
      />
    ),
    [extra, classId]
  )

  return <Accordion onChange={handleOpen}>{data?.map(renderItems)}</Accordion>
}

export default React.memo(List)
