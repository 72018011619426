import styled, { css } from 'styled-components'

import * as Select from '@radix-ui/react-select'

interface ITrigger {
  $hasError: boolean
  disabled?: boolean
}

const errorStyle = css`
  border-color: ${({ theme }) => theme.colors.primary500};
`

const disabledStyle = css`
  background-color: ${({ theme }) => theme.colors.grey200};
  cursor: not-allowed;
`

export const Trigger = styled(Select.Trigger)<ITrigger>`
  display: inline-flex;
  cursor: pointer;

  align-items: center;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.colors.light};
  border-radius: ${({ theme }) => theme.borderRadii.input};
  border: 2px solid ${({ theme }) => theme.colors.grey300};

  padding-inline: ${({ theme }) => theme.sizes.sm};
  padding-block: ${({ theme }) => theme.sizes.ss};

  ${({ theme }) => theme.textVariants.paragraphL};
  color: ${({ theme }) => theme.colors.grey800};
  text-align: left;

  transition: all 200ms;

  &[data-placeholder] {
    color: ${({ theme }) => theme.colors.grey500};
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey500};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.grey600};
  }

  ${({ $hasError }) => $hasError && errorStyle}
  ${({ disabled }) => disabled && disabledStyle}
`

export const Content = styled(Select.Content)`
  position: absolute;
  overflow: hidden;

  width: 102%;

  background-color: ${({ theme }) => theme.colors.light};
  border-radius: ${({ theme }) => theme.borderRadii.input};
  border: 2px solid ${({ theme }) => theme.colors.grey300};
`

export const Viewport = styled(Select.Viewport)`
  padding: ${({ theme }) => theme.sizes.ss};
`

export const Item = styled(Select.Item)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  user-select: none;
  cursor: pointer;

  ${({ theme }) => theme.textVariants.paragraphL};
  color: ${({ theme }) => theme.colors.grey800};

  border-radius: ${({ theme }) => theme.borderRadii.input};
  padding-inline: ${({ theme }) => theme.sizes.sm};
  padding-block: ${({ theme }) => theme.sizes.ss};

  transition: all 200ms;

  svg path {
    transition: all 200ms;
  }

  &[data-disabled] {
    color: ${({ theme }) => theme.colors.grey400};
    pointer-events: none;
    cursor: not-allowed;
  }

  &[data-highlighted] {
    outline: none;
    background-color: ${({ theme }) => theme.colors.primary500};
    color: ${({ theme }) => theme.colors.light};

    svg path {
      fill: ${({ theme }) => theme.colors.light};
    }
  }
`

export const ArrowDown = styled(Select.Icon)`
  display: inline-flex;
  align-items: center;
  justify-items: center;

  svg {
    transform: rotate(-90deg);

    #path {
      fill: ${({ theme }) => theme.colors.grey800};
    }
  }
`

export const Indicator = styled(Select.ItemIndicator)`
  display: inline-flex;
  align-items: center;
  justify-items: center;

  svg path {
    fill: ${({ theme }) => theme.colors.primary500};
  }
`

export const ScrollUp = styled(Select.ScrollUpButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 25px;
  background-color: ${({ theme }) => theme.colors.light};

  cursor: default;

  svg {
    transform: rotate(90deg);

    #path {
      fill: ${({ theme }) => theme.colors.primary500};
    }
  }
`

export const ScrollDown = styled(Select.ScrollDownButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 25px;
  background-color: ${({ theme }) => theme.colors.light};

  cursor: default;

  svg {
    transform: rotate(-90deg);

    #path {
      fill: ${({ theme }) => theme.colors.primary500};
    }
  }
`
