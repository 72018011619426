import { css } from 'styled-components'

interface IWrapper {
  onClick?: () => void
}

export const wrapper = css<IWrapper>`
  box-shadow: ${({ theme }) => theme.shadows.card};
  cursor: ${({ onClick }) => !!onClick && 'pointer'};
`
