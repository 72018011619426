import React, { useMemo } from 'react'

import { Box, PlaceImage, Skeleton, Text } from 'ui'
import { getStatus } from 'utils/user'
import { getImage } from 'utils/files'

import { TeacherPayload } from 'apollo/queries'

import * as S from './styled'

type Props = {
  data?: TeacherPayload
}

const TeacherHeader = ({ data }: Props) => {
  const status = useMemo(() => getStatus(data), [data])

  return (
    <Box
      as="header"
      flexDirection="row"
      alignItems="center"
      bgColor="text"
      radius="card"
      padding="xs"
      gap="s"
      largePhone={{ padding: 'sm' }}
      tablet={{ gap: 'xxl' }}
      desktop={{ mb: 'ss', gap: '8xl', padding: 'l' }}
    >
      <PlaceImage
        draggable="false"
        src={data?.avatar?.image}
        alt={data?.avatar?.name}
        placeholder={getImage(data?.avatar?.image, 'placeholder', 'png')}
        fallback="/assets/images/duck@fallback.png"
        ofit="contain"
        opos="center"
        alignSelf="center"
        defW={247}
        defH={248}
        w="auto"
        h="auto"
        maxw={80}
        tablet={{
          mt: -32,
          ml: -10,
          mb: -42,
          w: '100%',
          maxw: 274,
          maxh: 248,
        }}
        css={S.filter}
      />

      <Box flex={1}>
        <Text
          as="h1"
          variant="heading1B"
          color="light"
          fontSize={24}
          lineHeight={48}
          largePhone={{ fontSize: 32, lineHeight: 58 }}
          tablet={{ fontSize: 38, lineHeight: 78 }}
          desktop={{ fontSize: 48, lineHeight: 83 }}
          css={S.shadow}
        >
          {data?.fullName || <Skeleton />}
        </Text>

        <Box flex={1}>
          <Text
            as="span"
            variant="paragraphSB"
            color={status.color}
            largePhone={{ fontSize: 14, lineHeight: 17 }}
          >
            {status?.text || <Skeleton />}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(TeacherHeader)
