import React, { useCallback } from 'react'
import { To } from 'react-router'

import { Box, Logo } from 'ui'
import { Link, Mobile, Profile } from './sections'

import { ForRoles } from 'ui/auth'
import { Role } from 'types/models'
import { NavigationKey } from 'utils/navigation'
import { LayoutGroup } from 'framer-motion'

type Link = {
  to: To
  text: string
  icon: string
  roles: Role[]
  navKey: NavigationKey
}

const LINKS: Link[] = [
  {
    to: '/',
    text: 'Môj let',
    icon: 'account',
    roles: [Role.STUDENT],
    navKey: 'flight',
  },
  {
    to: '/',
    text: 'Moje triedy',
    icon: 'classes',
    roles: [Role.TEACHER],
    navKey: 'myclasses',
  },
  {
    to: '/',
    text: 'Triedy a študenti',
    icon: 'classes',
    roles: [Role.MANAGER],
    navKey: 'classes',
  },
  {
    to: '/teachers',
    text: 'Učitelia',
    icon: 'teachers',
    roles: [Role.MANAGER],
    navKey: 'teachers',
  },
  {
    to: '/subjects',
    text: 'Predmety',
    icon: 'subjects',
    roles: [Role.MANAGER],
    navKey: 'subjects',
  },
  {
    to: '/tasks',
    text: 'Galéria úloh',
    icon: 'tasks',
    roles: [Role.STUDENT, Role.TEACHER],
    navKey: 'tasks',
  },
  {
    to: '/benefits',
    text: 'Galéria benefitov',
    icon: 'benefits',
    roles: [Role.STUDENT, Role.TEACHER],
    navKey: 'benefits',
  },
  {
    to: '/ducks',
    text: 'Galéria káčerov',
    icon: 'duck',
    roles: [Role.STUDENT, Role.TEACHER],
    navKey: 'ducks',
  },
]

const Navigation = () => {
  const renderLinks = useCallback(
    ({ text, roles, ...other }: typeof LINKS[number]) => (
      <ForRoles key={text} roles={roles}>
        <Link {...other}>{text}</Link>
      </ForRoles>
    ),
    []
  )

  return (
    <Box
      gap="l"
      justifyContent="space-between"
      position="relative"
      flexDirection="row"
      alignItems="center"
      paddingVertical="sm"
      desktop={{ mb: 'l' }}
    >
      <Logo small />

      <Box
        as="nav"
        padding="ss"
        display="none"
        flexDirection="row"
        bgColor="grey200"
        radius="rounded"
        desktop={{ display: 'flex' }}
      >
        <LayoutGroup id="navigation">{LINKS.map(renderLinks)}</LayoutGroup>
      </Box>

      <Mobile>
        <LayoutGroup id="mobile">{LINKS.map(renderLinks)}</LayoutGroup>
      </Mobile>

      <Profile />
    </Box>
  )
}

export default React.memo(Navigation)
