import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { useMutation } from '@apollo/client'

import { Alert } from 'components/modals'
import { User } from 'types/models'
import { useErrorHandler } from 'hooks'
import useToastStore from 'stores/toast'

import { GET_NEW_STUDENTS, GET_STUDENTS, StudentsPayload } from 'apollo/queries'
import {
  RemoveMemberData,
  RemoveMemberPayload,
  REMOVE_MEMBER,
} from 'apollo/mutations'

type Props = {
  classId: ID
  data: User
}

type TPayload = Wrap<RemoveMemberPayload, 'user'>
type TData = Wrap<RemoveMemberData, 'data'>

const DeleteModal = ({ classId, data }: Props) => {
  const navigate = useNavigate()
  const { handle } = useErrorHandler()
  const { show } = useToastStore()

  const goBack = useCallback(
    () => navigate(`/${classId}`, { replace: true }),
    [navigate, classId]
  )

  const [removeMember, { loading }] = useMutation<TPayload, TData>(
    REMOVE_MEMBER,
    {
      onCompleted: () => {
        goBack()
        show({
          title: 'Študent bol odstránený z triedy',
          type: 'success',
        })
      },
    }
  )

  const handleDelete = useCallback(
    (hide: () => void) => {
      removeMember({
        variables: { data: { id: data.id, classId } },
        refetchQueries: [
          { query: GET_NEW_STUDENTS, variables: { data: { id: classId } } },
        ],
        update: (cache, { data: response }) => {
          cache.updateQuery(
            {
              query: GET_STUDENTS,
              variables: { data: { id: classId } },
            },
            (data?: Wrap<StudentsPayload, 'students'>) => {
              if (data?.students) {
                return {
                  students: data.students.filter(
                    (item) => item.id !== response?.user.id
                  ),
                }
              }
            }
          )
        },
      })
        .then(hide)
        .catch(handle())
    },
    [removeMember, data, classId, handle]
  )

  return (
    <Alert
      title="Prajete si odstrániť študenta z triedy ?"
      button="Áno, odstrániť"
      loading={loading}
      onAction={handleDelete}
    >
      Študent &quot;{data?.name} {data?.surname}&quot; bude odstránený z triedy.
    </Alert>
  )
}

export default React.memo(DeleteModal)
