import React from 'react'

import { Box, PlaceImage, Text } from 'ui'

import * as S from './styled'

type ItemProps = {
  title: string
  subtitle?: string
  image: {
    src?: string
    placeholder?: string
    fallback?: string
  }
  indent?: boolean
  secondary?: boolean
  onClick?: () => void
  children?: React.ReactNode
}

const Item = ({
  title,
  subtitle,
  image,
  onClick,
  indent,
  secondary,
  children,
}: ItemProps) => (
  <Box
    as="section"
    position="relative"
    flexDirection="row"
    bgColor="grey100"
    radius="toast"
    overflow="hidden"
    paddingLeft={secondary ? 0 : 80}
    largePhone={{ pl: secondary ? 0 : 100, radius: 'card' }}
    tablet={{ pl: secondary ? 0 : 150 }}
    desktop={{ pl: secondary ? 0 : 200 }}
    css={S.shadow}
    {...{ onClick }}
  >
    <PlaceImage
      position={!secondary ? 'absolute' : undefined}
      inset="0"
      right="auto"
      {...image}
      alt={`${title} image`}
      defW={secondary ? 60 : 200}
      defH={secondary ? 60 : 115}
      radius={secondary ? 'rounded' : undefined}
      m={secondary ? 'ss' : undefined}
      mr={secondary ? '3xs' : undefined}
      maxw={secondary ? 30 : 80}
      largePhone={{
        m: secondary ? 's' : undefined,
        mr: secondary ? 'xxs' : undefined,
        maxw: secondary ? 40 : 100,
      }}
      tablet={{
        m: secondary ? 'sm' : undefined,
        mr: secondary ? 'xs' : undefined,
        maxw: secondary ? 50 : 150,
      }}
      desktop={{
        maxw: secondary ? 60 : 200,
        mr: secondary ? 'sm' : undefined,
      }}
      ofit="cover"
      opos="center"
      w="100%"
      h="100%"
      ratio={secondary ? '1' : undefined}
    />

    <Box flex={1} flexDirection="column" tablet={{ flexDirection: 'row' }}>
      <Box
        as="header"
        flex={1}
        gap="3xs"
        padding="ss"
        justifyContent={secondary ? 'center' : undefined}
        paddingRight={indent ? 'xxl' : undefined}
        largePhone={{ gap: 'ss' }}
        tablet={{ padding: 'sm' }}
        desktop={{ flex: 1.5 }}
      >
        <Text
          as="h2"
          underline={secondary}
          color="primary500"
          variant={secondary ? 'heading6A' : 'heading5A'}
          desktop={{
            fontSize: secondary ? 20 : 28,
            lineHeight: secondary ? 24 : 34,
          }}
        >
          {title}
        </Text>

        {subtitle && (
          <Text
            as="span"
            variant="paragraphS"
            color="grey800"
            maxw={550}
            css={S.overflow}
            desktop={{
              fontSize: 16,
              lineHeight: 19,
            }}
          >
            {subtitle}
          </Text>
        )}
      </Box>

      {children}
    </Box>
  </Box>
)

type ContentProps = {
  children?: React.ReactNode
}

const Content = ({ children }: ContentProps) => (
  <Box
    gap="ss"
    alignContent="center"
    justifyContent="center"
    desktop={{ flex: 1 }}
    tablet={{ padding: 'sm' }}
  >
    {children}
  </Box>
)

type FooterProps = {
  end?: boolean
  children?: React.ReactNode
}

const Footer = ({ end, children }: FooterProps) => (
  <Box
    as="footer"
    flex={1}
    gap="ss"
    flexDirection="row"
    alignItems="center"
    padding="ss"
    paddingTop="0"
    justifyContent={end ? 'flex-end' : undefined}
    tablet={{ pv: 'sm', justifyContent: 'flex-end' }}
  >
    {children}
  </Box>
)

Item.Content = Content
Item.Footer = Footer

export default Item
