import { gql } from '@apollo/client'

import { Subject } from 'types/models'

export type TeacherSubjectsData = {
  id: ID
  query?: string
}

export type TeacherSubjectsPayload = Subject[]

export default gql`
  query getTeacherSubjects($data: IdqueryData!) {
    subjects: getTeacherSubjects(data: $data) {
      id
      name
    }
  }
`
