import React, { useCallback, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Form, Formik, FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'

import { Box, Button, Error, Head, Icon, Input, Text } from 'ui'
import { Card } from 'components/layout'
import { useErrorHandler } from 'hooks'
import useToastStore from 'stores/toast'

import validationSchema, { ResetPasswordData } from 'schemas/resetPassword'
import { ResetPasswordPayload, RESET_PASSWORD } from 'apollo/mutations'

type TPayload = Wrap<ResetPasswordPayload, 'resetPassword'>
type TData = Wrap<ResetPasswordData, 'data'>

const initialValues: ResetPasswordData = {
  email: '',
}

type Steps = 'initial' | 'success'

const ResetPassword = () => {
  const { handle, error } = useErrorHandler()
  const { show } = useToastStore()

  const [step, setStep] = useState<Steps>('initial')

  const [resetPassword, { loading }] = useMutation<TPayload, TData>(
    RESET_PASSWORD,
    {
      onCompleted: ({ resetPassword }) =>
        show({
          title: 'Požiadavka na zmenu hesla odoslaná',
          message: resetPassword?.user?.email,
        }),
    }
  )

  const onSubmit = useCallback(
    (
      values: ResetPasswordData,
      formikHelpers: FormikHelpers<ResetPasswordData>
    ) => {
      resetPassword({ variables: { data: values } })
        .then(() => setStep('success'))
        .catch(handle(formikHelpers))
    },
    [resetPassword, handle]
  )

  return (
    <>
      <Head title="Zmena hesla" />

      <Card title="Zmena hesla">
        {step === 'success' ? (
          <Box gap="l" alignItems="center">
            <Icon name="tick" width={55} height={47} />

            <Text
              textAlign="center"
              variant="heading4A"
              color="secondary500"
              maxw="25ch"
            >
              Na Tvoj email bol poslaný link pre zmenu hesla.
            </Text>
          </Box>
        ) : (
          <Formik
            {...{ initialValues, validationSchema, onSubmit }}
            validateOnChange
          >
            {({ errors, touched }) => (
              <Form>
                <Input
                  type="email"
                  name="email"
                  label="Email"
                  hasError={touched.email && errors.email}
                  disabled={loading}
                  autoComplete="email"
                  required
                />

                <Error message={error} />

                <Button type="submit" disabled={loading} isLoading={loading}>
                  Zmeniť
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Card>

      {step === 'initial' && (
        <Text<typeof NavLink>
          as={NavLink}
          to="/signin"
          textAlign="center"
          variant="anchorB"
          color="grey100"
          underline
        >
          Prihlásiť sa
        </Text>
      )}
    </>
  )
}

export default React.memo(ResetPassword)
