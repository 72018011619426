import { gql } from '@apollo/client'

import { User } from 'types/models'

export type TeacherData = {
  id: ID
}

export type TeacherPayload = {
  fullName: string
} & User

export default gql`
  query getTeacher($data: IdData!) {
    teacher: getTeacher(data: $data) {
      id
      email
      name
      surname
      role
      fullName
      registeredAt
      avatar {
        id
        name
        image
      }
    }
  }
`
