import { useStore } from 'zustand'
import { createStore } from 'zustand/vanilla'

import { MePayload } from 'apollo/queries'

export type AuthState = {
  accessToken: string | null
  user?: MePayload
  setToken: (token: string) => string
  getToken: () => string | null
  clearToken: () => void
  setUser: (data?: MePayload) => void
}

const store = createStore<AuthState>()((set, get) => ({
  accessToken: null,
  user: undefined,

  setUser: (data?: MePayload) => {
    set(() => ({ user: data }))
  },

  setToken: (token) => {
    set(() => ({ accessToken: token }))
    return get().accessToken || token
  },

  getToken: () => get().accessToken,

  clearToken: () => set(() => ({ accessToken: null, user: undefined })),
}))

type Selector<T extends keyof AuthState> = (state: AuthState) => AuthState[T]

export const useAuthStore = <T extends keyof AuthState>(
  selector: Selector<T>
) => useStore(store, selector)

export default store
