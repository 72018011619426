import React from 'react'

import { UserItem } from 'components/items'

import { Button } from 'ui'
import { Modal } from 'components'
import { DeleteModal, UnassignModal } from './sections'

import { SchoolSubjectsPayload } from 'apollo/queries'
import { ForRoles } from 'ui/auth'

type Props = {
  teacherId?: ID
  data: SchoolSubjectsPayload[number]
}

const Subject = ({ teacherId, data }: Props) => (
  <UserItem>
    <UserItem.Content secondary title={data?.name}>
      <UserItem.Footer>
        <ForRoles roles={['MANAGER']}>
          {teacherId ? (
            <Modal>
              <Modal.Trigger>
                <Button as="div">Odobrať</Button>
              </Modal.Trigger>

              <Modal.Content extended hideClose>
                <UnassignModal {...{ data, teacherId }} />
              </Modal.Content>
            </Modal>
          ) : (
            <Modal>
              <Modal.Trigger>
                <Button as="div">Vymazať</Button>
              </Modal.Trigger>

              <Modal.Content extended hideClose>
                <DeleteModal {...{ data }} />
              </Modal.Content>
            </Modal>
          )}
        </ForRoles>
      </UserItem.Footer>
    </UserItem.Content>
  </UserItem>
)

export default React.memo(Subject)
