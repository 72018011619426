import React from 'react'
import { Helmet } from 'react-helmet'

import { TITLE } from 'constants/global'

type Props = {
  title?: string
  children?: React.ReactNode
}

const Head = ({ title, children }: Props) => (
  <Helmet>
    <title>
      {title && `${title} |`} {TITLE}
    </title>

    {children}
  </Helmet>
)

export default React.memo(Head)
