import { gql } from '@apollo/client'

import { Task } from 'types/models'

export type TasksPayload = ({
  coins: number
} & Task)[]

export default gql`
  query getTasks {
    tasks: getTasks {
      id
      name
      image
      points
      coins
      createdAt
    }
  }
`
