import React, { useCallback } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { useMutation } from '@apollo/client'

import { Button, Error, Input } from 'ui'
import { useErrorHandler } from 'hooks'
import useToastStore from 'stores/toast'

import Modal, { useModal } from 'components/Modal/Modal'

import { GET_SCHOOL_SUBJECTS, SchoolSubjectsPayload } from 'apollo/queries'
import { AddSubjectPayload, ADD_SUBJECT } from 'apollo/mutations'
import validationSchema, { AddSubjectData } from 'schemas/addSubject'

type TPayload = Wrap<AddSubjectPayload, 'subject'>
type TData = Wrap<AddSubjectData, 'data'>

const initialValues: AddSubjectData = {
  name: '',
}

const AddSubject = () => {
  const { hide } = useModal()
  const { handle, error } = useErrorHandler()
  const { show } = useToastStore()

  const [addSubject, { loading }] = useMutation<TPayload, TData>(ADD_SUBJECT, {
    onCompleted: ({ subject }) =>
      show({
        title: 'Predmet bol úspešne vytvorený',
        message: subject?.name,
        type: 'success',
      }),
  })

  const onSubmit = useCallback(
    (values: AddSubjectData, formikHelpers: FormikHelpers<AddSubjectData>) => {
      addSubject({
        variables: { data: values },
        update: (cache, { data: response }) => {
          cache.updateQuery(
            {
              query: GET_SCHOOL_SUBJECTS,
              variables: { data: {} },
            },
            (data?: Wrap<SchoolSubjectsPayload, 'subjects'>) => {
              if (data?.subjects) {
                return { subjects: [response!.subject, ...data.subjects] }
              }
            }
          )
        },
      })
        .then(hide)
        .catch(handle(formikHelpers))
    },
    [addSubject, hide, handle]
  )

  return (
    <Modal.Wrapper indented condensed>
      <Modal.Title>Pridať predmet</Modal.Title>

      <Formik
        {...{ initialValues, validationSchema, onSubmit }}
        validateOnChange
      >
        {({ errors, touched }) => (
          <Form>
            <Input
              type="text"
              name="name"
              label="Názov"
              hasError={touched.name && errors.name}
              disabled={loading}
            />

            <Error message={error} />

            <Modal.Actions inForm>
              <Button type="submit" isLoading={loading} disabled={loading}>
                Pridať
              </Button>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal.Wrapper>
  )
}

export default React.memo(AddSubject)
