import styled, { css } from 'styled-components'

interface IField {
  hasError: boolean
  disabled?: boolean
}

export const overflow = css`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const errorStyle = css`
  border-color: ${({ theme }) => theme.colors.primary500};
`

const disabledStyle = css`
  background-color: ${({ theme }) => theme.colors.grey200};
  cursor: not-allowed;
`

export const Field = styled.div<IField>`
  position: relative;

  display: flex;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.light};
  border: 2px dashed ${({ theme }) => theme.colors.grey300};
  border-radius: ${({ theme }) => theme.borderRadii.input};

  transition: all 200ms;

  input {
    cursor: pointer;
    position: absolute;
    inset: 0;
    opacity: 0;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.grey500};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.grey600};
  }

  ${({ hasError }) => hasError && errorStyle}
  ${({ disabled }) => disabled && disabledStyle}
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  width: 100%;
  gap: ${({ theme }) => theme.sizes['3xs']};
`
