import { gql } from '@apollo/client'

import { User } from 'types/models'

export type MePayload = {
  fullName: string
  points: number
  coins: number
  level: number
  range: number
} & User

export default gql`
  query getMe {
    me: getMe {
      id
      email
      name
      surname
      role
      fullName
      birthYear
      points
      coins
      level
      range
      avatar {
        id
        name
        image
      }
    }
  }
`
