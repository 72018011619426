import { gql } from '@apollo/client'

import { Benefit } from 'types/models'

export type BenefitsPayload = ({
  price: number
} & Benefit)[]

export default gql`
  query getBenefits {
    benefits: getBenefits {
      id
      image
      level
      name
      scale
      price
      createdAt
    }
  }
`
