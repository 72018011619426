import { gql } from '@apollo/client'

import { User } from 'types/models'

export type CreateStudentPayload = User

export default gql`
  mutation createStudent($data: CreateStudentData!) {
    student: createStudent(data: $data) {
      id
      name
      surname
      avatar {
        id
        name
        image
      }
      registeredAt
      createdAt
    }
  }
`
