type Errors = {
  [key in string]: {
    message: string
    floating?: boolean
    field?: string
  }
}

export const GLOBAL_ERRORS: Errors = {
  UNAUTHORIZED: {
    message: 'Na vykonanie tejto akcie je potrebná autentifikácia',
    floating: true,
  },
  RECORD_NOT_FOUND: {
    message: 'Hľadaný záznam sa nenašiel',
    floating: true,
  },
  CANNOT_CREATE: {
    message: 'Záznam sa nepodarilo vytvoriť',
    floating: true,
  },
  CANNOT_UPDATE: {
    message: 'Záznam sa nepodarilo upraviť',
    floating: true,
  },
  CANNOT_DELETE: {
    message: 'Záznam sa nepodarilo odstrániť',
    floating: true,
  },
  CANNOT_INVITE: {
    message: 'Pozvánku sa nepodarilo odoslať',
    floating: true,
  },
  UNIQUE_VIOLATION: {
    message: 'Tento záznam už existuje',
    floating: true,
  },
  INSUFFICIENT_BALANCE: {
    message: 'Nedostatočný počet peňazí',
    floating: true,
  },
  INSUFFICIENT_LEVEL: {
    message: 'Nedostatočný level',
    floating: true,
  },
  INVALID_ROLE: {
    message: 'Nedostatočné oprávnenie',
    floating: true,
  },
  INVALID_TOKEN: {
    message: 'Chybné autentifikačné údaje',
    floating: true,
  },
  NOT_ALLOWED: {
    message: 'Na vykonanie tejto akcie nemáte dostatočné oprávnenie',
    floating: true,
  },
  CANNOT_SEND_EMAIL: {
    message: 'Email sa nepodarilo odoslať',
    floating: true,
  },
  DEFAULT: {
    message: 'Niečo sa pokazilo, prosím skúste to znovu',
    floating: true,
  },
}

export const MUTATION_ERRORS: Record<string, Errors> = {
  signin: {
    USER_NOT_CONFIRMED: {
      message: 'Emailová adresa používateľa nie je potvrdená',
    },
    INVALID_CREDENTIALS: {
      message: 'Prihlasovacie údaje nie sú správne',
      field: 'email',
    },
  },
  student: {
    UNIQUE_VIOLATION: {
      message: 'Používateľ s touto emailovou adresou už existuje',
      field: 'email',
    },
  },
}
