import React, { InputHTMLAttributes, useCallback } from 'react'
import { ErrorMessage, FieldAttributes, useField } from 'formik'
import * as Primitive from '@radix-ui/react-select'

import { Box, Text } from 'ui/core'
import Icon from 'ui/Icon'

import * as S from './styled'

type Props<Item> = {
  items?: Item[]
  defaultValue: Item
  label?: string
  hasError: boolean
} & FieldAttributes<any> &
  InputHTMLAttributes<HTMLSelectElement>

const Select = <Item extends { id: ID; name: string }>({
  items,
  defaultValue,
  label,
  hasError,
  disabled,
  ...props
}: Props<Item>) => {
  const [{ name, onBlur }, { value }, { setValue }] = useField(props)

  const renderOptions = useCallback(
    (item: Item) => (
      <S.Item key={item.id} value={item.id} textValue={item.name}>
        <Primitive.ItemText>{item.name}</Primitive.ItemText>

        <S.Indicator>
          <Icon name="tick" width={13} height={12} title="Zvolené" />
        </S.Indicator>
      </S.Item>
    ),
    []
  )

  return (
    <Box w="100%" gap="3xs">
      {!!label && (
        <Text<'label'>
          as="label"
          htmlFor={name}
          variant="paragraphMB"
          color="grey800"
        >
          {label}
        </Text>
      )}

      <Primitive.Root
        defaultValue={defaultValue?.id}
        onValueChange={setValue}
        disabled={disabled || !items?.length}
        {...{ value, name, ...props }}
      >
        <S.Trigger
          id={name}
          aria-label={name}
          disabled={disabled || !items?.length}
          $hasError={hasError}
          {...{ onBlur }}
        >
          <Primitive.Value />

          <S.ArrowDown>
            <Icon name="arrow" width={7} height={12} title="Otvoriť" />
          </S.ArrowDown>
        </S.Trigger>

        <Primitive.Portal style={{ zIndex: 999 }}>
          <S.Content>
            <S.ScrollUp>
              <Icon name="arrow" width={7} height={12} title="Hore" />
            </S.ScrollUp>

            <S.Viewport>{items?.map(renderOptions)}</S.Viewport>

            <S.ScrollDown>
              <Icon name="arrow" width={7} height={12} title="Dolú" />
            </S.ScrollDown>
          </S.Content>
        </Primitive.Portal>
      </Primitive.Root>

      {hasError && (
        <Text as="small" variant="paragraphS" color="primary500">
          <ErrorMessage {...{ name }} />
        </Text>
      )}
    </Box>
  )
}

export default Select
