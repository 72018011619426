import React, { useCallback } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { Box, Text, Button, Search } from 'ui'
import { SelectItem } from 'components/items'
import { useErrorHandler } from 'hooks'
import useToastStore from 'stores/toast'

import Modal, { useModal } from 'components/Modal/Modal'

import {
  GET_NEW_STUDENTS,
  GET_STUDENTS,
  NewStudentsData,
  NewStudentsPayload,
  StudentsPayload,
} from 'apollo/queries'
import {
  AddStudentData,
  AddStudentPayload,
  ADD_STUDENT,
} from 'apollo/mutations'

type Props = {
  classId: ID
  active: ActiveStudent | null
  setActive: (value: ActiveStudent | null) => void
  onAction: () => void
}

type TQPayload = Wrap<NewStudentsPayload, 'students'>
type TQData = Wrap<NewStudentsData, 'data'>

type TMPayload = Wrap<AddStudentPayload, 'student'>
type TMData = Wrap<AddStudentData, 'data'>

type ActiveStudent = NewStudentsPayload[number]

const Initial = ({ classId, active, setActive, onAction }: Props) => {
  const { hide } = useModal()
  const { handle } = useErrorHandler()
  const { show } = useToastStore()

  const { data, refetch } = useQuery<TQPayload, TQData>(GET_NEW_STUDENTS, {
    variables: { data: { id: classId } },
  })

  const [addStudent, { loading }] = useMutation<TMPayload, TMData>(
    ADD_STUDENT,
    {
      onCompleted: ({ student }) =>
        show({
          title: 'Študent bol úspešne pridaný do triedy',
          message: `${student?.name} ${student?.surname}`,
          type: 'success',
        }),
    }
  )

  const onSubmit = useCallback(() => {
    addStudent({
      variables: { data: { classId, id: active?.id || '' } },
      refetchQueries: [
        { query: GET_NEW_STUDENTS, variables: { data: { id: classId } } },
      ],
      update: (cache, { data: response }) => {
        cache.updateQuery(
          {
            query: GET_STUDENTS,
            variables: { data: { id: classId } },
          },
          (data?: Wrap<StudentsPayload, 'students'>) => {
            if (data?.students) {
              return { students: [response!.student, ...data.students] }
            }
          }
        )
      },
    })
      .then(hide)
      .catch(handle())
  }, [addStudent, hide, active, classId, handle])

  const handleSearch = useCallback(
    (query: string) => {
      setActive(null)
      refetch({ data: { id: classId, query } })
    },
    [setActive, refetch, classId]
  )

  const renderItems = useCallback(
    (item: NewStudentsPayload[number]) => (
      <SelectItem
        key={`student-${item.id}`}
        title={`${item.name} ${item.surname}`}
        subtitle={item.email}
        image={{
          src: item?.avatar?.image,
          placeholder: '/assets/images/duck@fallback.png',
          fallback: '/assets/images/duck@fallback.png',
        }}
        active={item.id === active?.id}
        onClick={() => setActive(item)}
        secondary
      />
    ),
    [active, setActive]
  )

  return (
    <Modal.Wrapper indented condensed>
      <Modal.Title>Pridať študenta</Modal.Title>

      <Box as="section" gap="3xs">
        <Box flexDirection="row" justifyContent="space-between">
          {/* LABEL */}
          <Text<'label'> as="label" htmlFor="title" variant="paragraphMB">
            Meno
          </Text>
          {/* END LABEL */}

          {/* SCAN */}
          <Text<'button'>
            as="button"
            onClick={onAction}
            variant="anchorB"
            color="primary500"
            underline
            disabled={loading}
          >
            Pridať nového študenta
          </Text>
          {/* END SCAN */}
        </Box>

        <Search
          id="title"
          placeholder="Názov"
          callback={handleSearch}
          fullWidth
        />
      </Box>

      {/* STUDENTS LIST */}
      <Box maxHeight={395} overflow="scroll" radius="input">
        <Box mb="s" gap="s">
          {data?.students?.map(renderItems)}
        </Box>
      </Box>
      {/* END STUDENTS LIST */}

      <Modal.Actions>
        <Button
          disabled={!active || loading}
          isLoading={loading}
          onClick={onSubmit}
        >
          Pridať
        </Button>
      </Modal.Actions>
    </Modal.Wrapper>
  )
}

export default React.memo(Initial)
