import React from 'react'

import { Avatar, Box, ImageIcon, PlaceImage, Text } from 'ui'
import { Modal } from 'components'
import { Item } from 'components/items'
import { Timeline } from 'components/layout'
import { getImage } from 'utils/files'

import { MyTasksPayload } from 'apollo/queries'

type Props = {
  data: MyTasksPayload[number]
}

const MyTask = ({ data }: Props) => (
  <Modal>
    {/* TRIGGER */}
    <Modal.Trigger>
      <Timeline.Item>
        <Item
          indent
          title={data?.task?.name}
          subtitle={data?.subject?.name}
          image={{
            src: getImage(data?.task?.image, 'empty'),
            placeholder: getImage(data?.task?.image, 'placeholder'),
            fallback: '/assets/logo.svg',
          }}
        >
          <Item.Content>
            <Box
              gap="3xs"
              alignSelf="flex-start"
              position="absolute"
              top="ss"
              right="ss"
              tablet={{
                gap: 'ss',
                position: 'relative',
                top: 'auto',
                right: 'auto',
              }}
            >
              <Box
                gap="3xs"
                flexDirection="row"
                alignItems="center"
                alignSelf="flex-end"
                desktop={{ alignSelf: 'flex-start' }}
              >
                <Text
                  as="span"
                  variant="heading5A"
                  color="grey800"
                  display="none"
                  desktop={{ display: 'inline' }}
                >
                  Počet bodov:
                </Text>

                <Text
                  as="span"
                  variant="heading6A"
                  color="primary500"
                  largePhone={{ fontSize: 18, lineHeight: 22 }}
                >
                  {data?.points}
                </Text>

                <Box desktop={{ display: 'none' }}>
                  <ImageIcon name="beak" defW={16} defH={16} alt="body" />
                </Box>
              </Box>

              <Box
                flexDirection="row"
                alignItems="center"
                gap="3xs"
                alignSelf="flex-end"
                desktop={{ alignSelf: 'flex-start' }}
              >
                <Text
                  variant="heading6A"
                  color="primary500"
                  largePhone={{ fontSize: 18, lineHeight: 22 }}
                >
                  {data?.coins}
                </Text>

                <ImageIcon name="coin" defW={16} defH={16} alt="peniaze" />
              </Box>
            </Box>
          </Item.Content>

          {/* TEACHER */}
          <Item.Footer>
            <Box
              flexDirection="row"
              alignItems="center"
              gap="ss"
              tablet={{ gap: 'sm', mr: '5%' }}
              desktop={{ mr: '20%' }}
            >
              <Avatar
                src={data?.teacher?.avatar?.image}
                alt={data?.teacher?.avatar?.name}
              />

              <Text
                variant="heading6A"
                color="grey800"
                tablet={{ fontSize: 18, lineHeight: 22 }}
                desktop={{ fontSize: 20, lineHeight: 24 }}
              >
                {data?.teacher?.name} {data?.teacher?.surname}
              </Text>
            </Box>
          </Item.Footer>
          {/* END TEACHER */}
        </Item>
      </Timeline.Item>
    </Modal.Trigger>
    {/* END TRIGGER */}

    {/* MODAL */}
    <Modal.Content extended>
      <Modal.Wrapper>
        <PlaceImage
          src={data.task.image}
          placeholder={getImage(data.task.image, 'placeholder')}
          alt={data?.task?.name}
          defW={591}
          defH={833}
          w="100%"
          h="auto"
          ratio="591 / 833"
        />
      </Modal.Wrapper>
    </Modal.Content>
    {/* END MODAL */}
  </Modal>
)

export default React.memo(MyTask)
