const UUID_REGEXP =
  /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g

const NAVIGATION = {
  flight: ['/', '/actual-benefits', '/benefits-history'],
  tasks: ['/tasks'],
  benefits: ['/benefits'],
  ducks: ['/ducks'],
  myclasses: [
    '/',
    '/:id',
    '/:id/benefits',
    '/:id/:id',
    '/:id/:id/actual-benefits',
    '/:id/:id/benefits-history',
  ],
  classes: [
    '/',
    '/:id',
    '/:id/:id',
    '/:id/:id/actual-benefits',
    '/:id/:id/benefits-history',
  ],
  teachers: ['/teachers', '/teachers/:id', '/teachers/:id/subjects'],
  subjects: ['/subjects'],
} as const

export type NavigationKey = keyof typeof NAVIGATION

const isActive = (key: NavigationKey, path: string) => {
  const paths = NAVIGATION[key]
  const joinedPaths = paths.join(',')

  const normalizedPath = path.replace(UUID_REGEXP, ':id')
  const pathRegExp = new RegExp(`(,|^){1}(${normalizedPath})(,|$){1}`)

  return pathRegExp.test(joinedPaths)
}

export default isActive
