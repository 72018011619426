import { gql } from '@apollo/client'

import { StudentTask } from 'types/models'

export type MyTasksData = {
  id?: ID
}

export type MyTasksPayload = StudentTask[]

export default gql`
  query getMyTasks($data: OptidData) {
    myTasks: getMyTasks(data: $data) {
      id
      points
      coins
      createdAt
      teacher {
        id
        name
        surname
        avatar {
          id
          image
          name
        }
      }
      subject {
        id
        name
      }
      task {
        id
        name
        image
      }
    }
  }
`
