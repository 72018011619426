import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import { Button } from 'ui'
import { Content, Timeline } from 'components/layout'
import { MyUsed } from 'pages/sections'
import { equalDates, isToday, toDateFormat } from 'utils/date'
import { ForRoles } from 'ui/auth'

import { DEFAULT_POLL_INTERVAL } from 'constants/global'
import { GET_MY_USED, MyUsedData, MyUsedPayload } from 'apollo/queries'

type DateSeparatorProps = {
  curr: MyUsedPayload[number]
  prev?: MyUsedPayload[number]
}

type Params = {
  id?: ID
  studentId?: ID
}

type TPayload = Wrap<MyUsedPayload, 'myUsed'>
type TData = Wrap<MyUsedData, 'data'>

const DateSeparator = ({ prev, curr }: DateSeparatorProps) =>
  !prev || (!!prev && !equalDates(prev.createdAt, curr.createdAt)) ? (
    <Timeline.Separator>
      {isToday(curr.createdAt) ? 'Dnes' : toDateFormat(curr.createdAt)}
    </Timeline.Separator>
  ) : null

const BenefitsHistory = () => {
  const navigate = useNavigate()
  const { id, studentId } = useParams<Params>()

  const { data, loading } = useQuery<TPayload, TData>(GET_MY_USED, {
    variables: { data: { id: studentId || '' } },
    pollInterval: !studentId ? DEFAULT_POLL_INTERVAL : undefined,
    onError: () => {
      if (studentId) navigate(`/${id || ''}`, { replace: true })
    },
  })

  const renderItems = useCallback(
    (item: MyUsedPayload[number], idx: number) => (
      <React.Fragment key={`my-used-${item.id}`}>
        <DateSeparator prev={data?.myUsed?.[idx - 1]} curr={item} />
        <MyUsed data={item} />
      </React.Fragment>
    ),
    [data]
  )

  if (!data?.myUsed?.length) {
    return loading ? (
      <Content.Loading />
    ) : (
      <Content.Empty text="Nenašli sa žiadne použité benefity">
        <ForRoles roles={['STUDENT']}>
          <Button onClick={() => navigate('/actual-benefits')}>
            Aktuálne benefity
          </Button>
        </ForRoles>
      </Content.Empty>
    )
  }

  return <Timeline>{data?.myUsed?.map(renderItems)}</Timeline>
}

export default React.memo(BenefitsHistory)
