import { css } from 'styled-components'

interface IWrapper {
  disabled?: boolean
}

export const overlay = css`
  &::before {
    content: '';
    z-index: ${({ theme }) => theme.zIndices.modal};

    position: absolute;
    inset: 0;

    background-color: ${({ theme }) => theme.colors.dark};
    opacity: 0.8;
  }
`

export const wrapper = css<IWrapper>`
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05);

  &:hover {
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.2);
  }

  cursor: pointer;

  aspect-ratio: 246 / 288;

  ${({ disabled }) => disabled && overlay}
`

export const before = css`
  align-self: center;

  &::before {
    content: '';
    height: 18px;

    background-color: ${({ theme }) => theme.colors.primary700};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadii.input};

    position: absolute;
    bottom: 0;
    left: -10px;
    right: 18px;
    z-index: 3;
  }

  &::after {
    content: '';
    height: 18px;
    width: 25px;

    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 3;

    background-color: ${({ theme }) => theme.colors.primary500};
    border-bottom-left-radius: 37px;
    border-bottom-right-radius: 100px;
  }
`
