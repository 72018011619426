import { gql } from '@apollo/client'

import { Subject } from 'types/models'

export type AssignSubjectsPayload = Subject[]

export default gql`
  mutation assignSubjects($data: AssignSubjectsData!) {
    subjects: assignSubjects(data: $data) {
      id
      name
    }
  }
`
