import React, { useCallback } from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import { Content, Grid } from 'components/layout'
import { Benefit } from 'pages/sections'

import { LONG_POLL_INTERVAL } from 'constants/global'
import {
  ClassBenefitsData,
  ClassBenefitsPayload,
  GET_CLASS_BENEFITS,
} from 'apollo/queries'

type Params = {
  id: ID
}

type TPayload = Wrap<ClassBenefitsPayload, 'benefits'>
type TData = Wrap<ClassBenefitsData, 'data'>

const Benefits = () => {
  const { id } = useParams<Params>()

  const { data, loading } = useQuery<TPayload, TData>(GET_CLASS_BENEFITS, {
    variables: { data: { id: id! } },
    pollInterval: LONG_POLL_INTERVAL,
  })

  const renderItems = useCallback(
    (item: ClassBenefitsPayload[number]) => (
      <Benefit key={`benefit-${item.id}`} data={item} disableOverlay />
    ),
    []
  )

  if (!data?.benefits?.length) {
    return loading ? (
      <Content.Loading />
    ) : (
      <Content.Empty text="Táto trieda nemá žiadne benefity" />
    )
  }

  return <Grid>{data?.benefits?.map(renderItems)}</Grid>
}

export default React.memo(Benefits)
