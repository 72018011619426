import React from 'react'

import { Box, Text } from 'ui/core'

const Loading = () => (
  <Box position="fixed" inset="0" justifyContent="center" alignItems="center">
    <Text variant="heading3A" color="light">
      Načítavanie...
    </Text>
  </Box>
)

export default React.memo(Loading)
