import { gql } from '@apollo/client'

import { User } from 'types/models'

export type ChangePasswordPayload = {
  user: User
  accessToken: string
  refreshToken: ID
}

export default gql`
  mutation changePassword($data: ChangePasswordData!) {
    changePassword(data: $data) {
      user {
        id
        email
        role
      }
      accessToken
      refreshToken
    }
  }
`
