import React, { useMemo } from 'react'
import { Form, Formik } from 'formik'

import { Input, PlaceImage } from 'ui'
import { ForRoles } from 'ui/auth'
import { getImage } from 'utils/files'

import Modal from 'components/Modal/Modal'

import { MePayload } from 'apollo/queries'

type Props = {
  data?: MePayload
}

const Profile = ({ data }: Props) => {
  const initialValues = useMemo(
    () => ({
      name: data?.name || '',
      surname: data?.surname || '',
      birthYear: data?.birthYear || '',
      email: data?.email || '',
    }),
    [data]
  )

  return (
    <Modal.Wrapper indented>
      <Modal.Title>Profil</Modal.Title>

      <PlaceImage
        src={data?.avatar?.image}
        placeholder={getImage(data?.avatar?.image, 'placeholder', 'png')}
        fallback="/assets/images/duck@fallback.png"
        defW={147}
        defH={148}
        maxw={147}
        w="100%"
        h="auto"
        alignSelf="center"
      />

      <Formik {...{ initialValues }} onSubmit={undefined!} enableReinitialize>
        <Form>
          <Input type="text" name="name" label="Meno" disabled />

          <Input type="text" name="surname" label="Priezvisko" disabled />

          {/* FOR STUDENT */}
          <ForRoles roles="STUDENT">
            <Input
              type="text"
              name="birthYear"
              label="Rok narodenia"
              disabled
            />
          </ForRoles>
          {/* END FOR STUDENT */}

          <Input type="email" name="email" label="Email" disabled />
        </Form>
      </Formik>
    </Modal.Wrapper>
  )
}

export default React.memo(Profile)
