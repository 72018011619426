import React, { useCallback, useRef } from 'react'
import { ErrorMessage, FieldAttributes, useField } from 'formik'

import { Box, Text } from 'ui/core'
import Icon from 'ui/Icon'

import * as S from './styled'

export type Props = {
  label: string
  hasError: boolean
  defaultValue?: string
  onDelete?: () => void
} & FieldAttributes<any> &
  React.InputHTMLAttributes<HTMLInputElement>

const File = ({
  label,
  name,
  hasError,
  disabled,
  defaultValue,
  onDelete,
  ...props
}: Props) => {
  const ref = useRef<HTMLInputElement | null>(null)

  const [{ onBlur }, { value }, { setValue }] = useField({ name, ...props })

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e?.target?.files?.[0])
    },
    [setValue]
  )

  const handleEdit = useCallback(() => ref?.current?.click(), [ref])

  const handleDelete = useCallback(() => {
    if (ref.current) ref.current.value = ''
    setValue(null)
    if (!value) onDelete?.()
  }, [value, setValue, onDelete])

  return (
    <S.Wrapper {...{ hasError, disabled }}>
      {!!label && (
        <Text<'label'>
          as="label"
          htmlFor={name}
          variant="paragraphMB"
          color="text"
        >
          {label}
        </Text>
      )}

      <S.Field {...{ disabled, hasError }}>
        <input
          id={name}
          type="file"
          onChange={handleChange}
          accept="image/png, image/jpeg, image/jpg"
          {...{ ref, name, onBlur, disabled }}
        />

        {defaultValue || value ? (
          <Box
            flex={1}
            gap="xs"
            zIndex="1"
            padding="ss"
            flexDirection="row"
            alignItems="center"
            largePhone={{ padding: 'sm' }}
          >
            <Box<'img'>
              as="img"
              src={value ? URL.createObjectURL(value) : defaultValue}
              width={47}
              height={47}
              alt="preview"
              ofit="cover"
              opos="center"
              radius="rounded"
              style={{ flexShrink: 0 }}
            />

            <Box gap="3xs">
              <Text variant="heading6A" color="primary600" css={S.overflow}>
                {value ? value?.name : 'Aktuálne logo'}
              </Text>

              <Box flexDirection="row" gap="ss">
                <Text<'button'>
                  as="button"
                  type="button"
                  variant="anchor"
                  color="grey800"
                  underline
                  onClick={handleEdit}
                >
                  Upraviť
                </Text>

                <Text<'button'>
                  as="button"
                  type="button"
                  variant="anchor"
                  color="grey800"
                  underline
                  onClick={handleDelete}
                >
                  Zmazať
                </Text>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            flex={1}
            gap="s"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            padding="ss"
            largePhone={{ padding: 'sm' }}
          >
            <Icon name="file" width={47} height={47} title="prázdny súbor" />

            <Text
              variant="heading6A"
              color="grey300"
              largePhone={{ fontSize: 18, lineHeight: 22 }}
            >
              Nahrať súbor
            </Text>
          </Box>
        )}
      </S.Field>

      {hasError && (
        <Text as="small" variant="paragraphS" color="primary500">
          <ErrorMessage {...{ name }} />
        </Text>
      )}
    </S.Wrapper>
  )
}

export default React.memo(File)
