import React, { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { Alert } from 'components/modals'
import { useErrorHandler } from 'hooks'
import { Subject } from 'types/models'
import useToastStore from 'stores/toast'

import {
  DeleteSubjectPayload,
  DeleteSubjectData,
  DELETE_SUBJECT,
} from 'apollo/mutations'

type Props = {
  data: Subject
}

type TPayload = Wrap<DeleteSubjectPayload, 'subject'>
type TData = Wrap<DeleteSubjectData, 'data'>

const DeleteModal = ({ data }: Props) => {
  const { handle } = useErrorHandler()
  const { show } = useToastStore()

  const [deleteSubject, { loading }] = useMutation<TPayload, TData>(
    DELETE_SUBJECT,
    {
      onCompleted: () =>
        show({
          title: 'Predmet bol úspešne odstránený',
          type: 'success',
        }),
    }
  )

  const handleDelete = useCallback(
    (hide: () => void) => {
      deleteSubject({
        variables: { data: { id: data.id } },
        update: (cache, { data: response }) => {
          const id = cache.identify({
            id: response?.subject.id,
            __typename: 'Subject',
          })
          cache.evict({ id })
        },
      })
        .then(hide)
        .catch(handle())
    },
    [deleteSubject, data, handle]
  )

  return (
    <Alert
      title="Prajete si vymazať predmet ?"
      button="Áno, vymazať"
      loading={loading}
      onAction={handleDelete}
    >
      Predmet &quot;{data?.name}&quot; bude trvalo odstránený. <br />
      Táto akcia je nezvratná.
    </Alert>
  )
}

export default React.memo(DeleteModal)
