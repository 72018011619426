import { gql } from '@apollo/client'

import { Benefit } from 'types/models'

export type ClassBenefitsData = {
  id: ID
}

export type ClassBenefitsPayload = ({
  price: number
} & Benefit)[]

export default gql`
  query getClassBenefits($data: IdData!) {
    benefits: getClassBenefits(data: $data) {
      id
      image
      level
      name
      scale
      price
      createdAt
    }
  }
`
