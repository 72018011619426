import { gql } from '@apollo/client'

import { Class } from 'types/models'

export type DeleteClassData = {
  id: ID
}

export type DeleteClassPayload = Class

export default gql`
  mutation deleteClass($data: IdData!) {
    class: deleteClass(data: $data) {
      id
    }
  }
`
