import React from 'react'
import { Navigate, useLocation } from 'react-router'

import ProtectRoute from './ProtectRoute'

const WithoutAuth = (Component: React.ComponentType, fallback = '/') => {
  const Wrapper = () => {
    const location = useLocation()

    const toLocation = (location?.state as any)?.from || fallback

    return (
      <ProtectRoute
        no={Component}
        yes={() => (
          <Navigate to={toLocation} state={{ from: location }} replace />
        )}
      />
    )
  }

  return <Wrapper />
}

export default WithoutAuth
