import React, { useCallback, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import useMeasure from 'react-use-measure'

import { Assignment, Initial } from './sections'
import { useSelect } from 'hooks'

import { StudentTasksPayload } from 'apollo/queries'

type Props = {
  studentId: ID
}

type Steps = 'initial' | 'assignment'
type ActiveTask = StudentTasksPayload[number]

const AddTask = ({ studentId }: Props) => {
  const [initialRef, { height: initialHeight }] = useMeasure()
  const [assignmentRef, { height: assignmentHeight }] = useMeasure()

  const [step, setStep] = useState<Steps>('initial')
  const [active, setActive] = useSelect<ActiveTask>()

  const handleStep = useCallback((value: Steps) => () => setStep(value), [])

  return (
    <AnimatePresence initial={false}>
      {step === 'initial' && (
        <motion.div
          key="initial"
          initial={{
            x: '-100%',
            opacity: 0,
            height: assignmentHeight || 'auto',
          }}
          animate={{
            x: 0,
            opacity: 1,
            zIndex: 1,
            height: initialHeight || 'auto',
          }}
          exit={{
            x: '-100%',
            position: 'absolute',
            width: '100%',
            opacity: 0,
            zIndex: 0,
          }}
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            duration: 0.2,
          }}
        >
          <div ref={initialRef}>
            <Initial
              onAction={handleStep('assignment')}
              {...{ active, setActive }}
            />
          </div>
        </motion.div>
      )}

      {step === 'assignment' && active && (
        <motion.div
          key="assignment"
          initial={{
            x: '100%',
            opacity: 0,
            height: initialHeight || 'auto',
          }}
          animate={{
            x: 0,
            opacity: 1,
            zIndex: 1,
            height: assignmentHeight || 'auto',
          }}
          exit={{
            x: '100%',
            position: 'absolute',
            width: '100%',
            opacity: 0,
            zIndex: 0,
          }}
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            duration: 0.2,
          }}
        >
          <div ref={assignmentRef}>
            <Assignment
              onAction={handleStep('initial')}
              {...{ active, studentId }}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default React.memo(AddTask)
