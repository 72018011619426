export const TITLE = 'Úlet'

export const PREFIX = '@ULET'

export const REFRESH_TOKEN = `${PREFIX}/REFRESH_TOKEN`
export const REFRESH_TOKEN_EXPIRATION = 356 // 1 year

export const SECOND = 1_000 // ms
export const MINUTE = 60 * SECOND // 1 minute

export const DEFAULT_POLL_INTERVAL = MINUTE
export const LONG_POLL_INTERVAL = 2 * MINUTE

export const BYTE = 1_024 // 1B
