import React from 'react'
import { motion } from 'framer-motion'

import { Modal } from 'components'
import { Card } from 'components/items'
import { BuyBenefitModal } from 'components/modals'

import { BenefitsPayload } from 'apollo/queries'

type Props = {
  balance?: number
  disableOverlay?: boolean
  data: BenefitsPayload[number]
}

const Benefit = ({ balance = 0, disableOverlay, data }: Props) => (
  <Modal>
    <Modal.Trigger>
      <motion.div whileHover={{ scale: 1.05 }}>
        <Card
          title={data.name}
          image={data.image}
          color="primary600"
          disabled={!disableOverlay ? balance < 0 : false}
        >
          <Card.Badge value={data.price} side="right" color="primary600" />
        </Card>
      </motion.div>
    </Modal.Trigger>

    <Modal.Content extended>
      <BuyBenefitModal {...{ data, balance }} />
    </Modal.Content>
  </Modal>
)

export default React.memo(Benefit)
